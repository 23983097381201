import React, { useState } from "react";
import styles from "../../styles/Profile.module.css";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { errorContainer, formAttr } from "../common/CommonFun";
import { toast } from "react-toastify";
import { PostTokenApi } from "../../api/api-service";
import { API_Path, companyName } from "../../const";
import Loading from "../loader/Loading";

export default function SafetyPhrase(props) {
    const [loading, setLoading] = useState();
    const [emailSafetyShow, setEmailSafetyShow] = useState(false);
    const [loginSAfetySHow, setLoginSafetyShow] = useState(false);
    const handleEmailSafetyShow = () => setEmailSafetyShow(!emailSafetyShow);
    const handleLoginSafetyShow = () => setLoginSafetyShow(!loginSAfetySHow);

    const setEmailSubmitData = (formData, resetForm) => {
        setLoading(true);
        const emailSafety = new Promise((resolve) => {
            resolve(PostTokenApi(API_Path.userEmailSafertCode, { ...formData }));
        });
        emailSafety.then((res) => {
            if (res) {
                if (res.data.Isvalid === true) {
                    setLoading(false);
                    resetForm();
                    toast.success(res.data.Successes[0]);
                    props.getProfileApi();
                } else {
                    setLoading(false);
                    toast.error(res.data.Isvalid[0]);
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    };

    const setLoginSubmitData = (formData, resetForm) => {
        setLoading(true);
        const loginSafetyData = new Promise((resolve) => {
            resolve(PostTokenApi(API_Path.userLoginSafertCode, { ...formData }));
        });
        loginSafetyData.then((res) => {
            if (res) {
                if (res.data.Isvalid === true) {
                    setLoading(false);
                    resetForm();
                    toast.success(res.data.Successes[0]);
                    props.getProfileApi();
                } else {
                    setLoading(false);
                    toast.error(res.data.Isvalid[0]);
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    };

    return (
        <React.Fragment>
            {loading && <Loading />}
            <div className={`${styles.personal_info_body}`}>
                <div className="safety-item">
                    <div className={styles.title}>
                        <h6 className="mb-3">E-mail Safety Phrase</h6>
                    </div>
                    <p>Displayed in the e-mail from {companyName.toLowerCase()} to prevent the user from visiting a phishing website.</p>
                    {!emailSafetyShow ? (
                        <div className="form-group">
                            <div className={`${styles.personal_info_btn} p-0`}>
                                <button type="submit" className={`${styles.button} primary_button`} onClick={handleEmailSafetyShow}>
                                    Set
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.safety_phrase}>
                            <p>
                                Set this e-mail security phrase to prevent phishing attempts. This code shall be displayed whenever you login to {companyName.toLowerCase()} or receive an e-mail from
                                us.
                            </p>
                            <div>
                                {props?.EmailSafetyCode && (
                                    <div className={styles.safety_phrase_text}>
                                        <span>Current E-mail Safety Code :</span> <b>{props.EmailSafetyCode}</b>
                                    </div>
                                )}
                                <Formik
                                    initialValues={{ EmailSafetyCode: "" }}
                                    validationSchema={Yup.object().shape({
                                        EmailSafetyCode: Yup.string().required("New e-mail safety is required."),
                                    })}
                                    onSubmit={(values, { resetForm }) => {
                                        setEmailSubmitData(values, resetForm);
                                    }}
                                >
                                    {(runform) => {
                                        const { touched, errors } = runform;
                                        return (
                                            <Form onSubmit={runform.handleSubmit}>
                                                <div className={styles.personalinfocontent}>
                                                    <label>
                                                        New Email Safety
                                                        <span className={styles.asterisk}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter code"
                                                        name="EmailSafetyCode"
                                                        maxLength={20}
                                                        className={`${styles.form_input} ${errors.EmailSafetyCode && touched.EmailSafetyCode ? "input-error" : ""} `}
                                                        {...formAttr(runform, "EmailSafetyCode")}
                                                    />
                                                    {errorContainer(runform, "EmailSafetyCode")}
                                                </div>
                                                <div className="form-group">
                                                    <div className={`${styles.personal_info_btn} text-center d-flex pt-3`}>
                                                        <button type="button" className={`${styles.button} ${styles.button_border}`} onClick={() => runform.resetForm()}>
                                                            Clear
                                                        </button>
                                                        <button type="submit" className={`${styles.button} primary_button`}>
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    )}
                </div>
                <div className="safety-item">
                    <div className={styles.title}>
                        <h6 className="mb-3">Login Safety Phrase</h6>
                    </div>
                    <p>Displayed in the user login window. By confirming your verification code, you can prevent phishing attempts from fake {companyName.toLowerCase()} e-mails and websites.</p>
                    {!loginSAfetySHow ? (
                        <div className="form-group">
                            <div className={`${styles.personal_info_btn} p-0 mb-5`}>
                                <button type="submit" className={`${styles.button} primary_button`} onClick={handleLoginSafetyShow}>
                                    Set
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.safety_phrase}>
                            <p>Set this login security phrase to prevent phishing attempts. This code shall be displayed whenever you login to your {companyName.toLowerCase()} user account.</p>
                            {props?.LoginSafetyCode && (
                                <div className={styles.safety_phrase_text}>
                                    <span>Current Login Safety Code :</span> <b>{props?.LoginSafetyCode}</b>
                                </div>
                            )}
                            <Formik
                                initialValues={{
                                    LoginSafetyCode: "",
                                }}
                                validationSchema={Yup.object().shape({
                                    LoginSafetyCode: Yup.string().required("New login safety is required."),
                                })}
                                onSubmit={(values, { resetForm }) => {
                                    setLoginSubmitData(values, resetForm);
                                }}
                            >
                                {(runform) => {
                                    const { touched, errors } = runform;
                                    return (
                                        <Form onSubmit={runform.handleSubmit}>
                                            <div className={styles.personalinfocontent}>
                                                <label>
                                                    New Login Safety
                                                    <span className={styles.asterisk}>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Enter code"
                                                    name="LoginSafetyCode"
                                                    maxLength={20}
                                                    className={`${styles.form_input} ${errors.LoginSafetyCode && touched.LoginSafetyCode ? "input-error" : ""} `}
                                                    {...formAttr(runform, "LoginSafetyCode")}
                                                />
                                                {errorContainer(runform, "LoginSafetyCode")}
                                            </div>
                                            <div className="form-group">
                                                <div className={`${styles.personal_info_btn} text-center d-flex pt-3`}>
                                                    <button type="button" className={`${styles.button} ${styles.button_border}`} onClick={() => runform.resetForm()}>
                                                        Clear
                                                    </button>
                                                    <button type="submit" className={`${styles.button} primary_button`}>
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
