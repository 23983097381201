import React, { Fragment, useEffect, useState } from "react";
import styles from "../../styles/Profile.module.css";
import { GetTokenApi, PostTokenApi } from "../../api/api-service";
import { toast } from "react-toastify";
import { API_Path, baseName } from "../../const";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { errorContainer, formAttr } from "../common/CommonFun";

export default function BankAccountVerification() {
    const [isAllowSubmitAgain, setIsAllowSubmitAgain] = useState(false);
    const [showBeneficiaryStep, setShowBeneficiaryStep] = useState(0);
    const [userBeneficiaryData, setUserBeneficiaryData] = useState();
    const [ImageData, setImageData] = useState();
    const [ImageName, setImageName] = useState();

    useEffect(() => {
        setShowBeneficiaryStep(6); // Coming soon
        // GetUserBeneficiary();
    }, []);

    const GetUserBeneficiary = () => {
        const getCryptoCurrency = new Promise((resolve) => {
            resolve(GetTokenApi(API_Path.getUserBeneficiary));
        });
        getCryptoCurrency.then((res) => {
            if (res) {
                if (!res.data.IsKycVerified) {
                    setShowBeneficiaryStep(1);
                } else if (!res.data.IsAllowBankVerify) {
                    setShowBeneficiaryStep(5);
                } else {
                    if (res.data.Id > 0) {
                        setShowBeneficiaryStep(3);
                        setIsAllowSubmitAgain(false);
                        if (res.data.BankStatus == 3) {
                            setIsAllowSubmitAgain(true);
                        }
                    } else {
                        setShowBeneficiaryStep(2);
                    }
                }
                setUserBeneficiaryData(res.data);
            } else {
                toast.error(res.statusText);
            }
        });
    };

    const handleFileChange = (e) => {
        const image = new Image();
        let fr = new FileReader();
        fr.onload = function () {
            if (fr !== null && typeof fr.result == "string") {
                image.src = fr.result;
            }
        };
        fr.readAsDataURL(e.target.files[0]);
        image.onload = () => {
            setImageData(e.target.files[0]); // Pass the file directly to setSubmitData
            setImageName(e.target.files[0].name);
        };
    };

    const setSubmitData = (formdata, resetForm) => {
        console.log(ImageData, "save"); // Log the formdata instead of ImageData
        let data1 = new FormData();
        data1.append("images", ImageData); // Use the file instead of ImageData
        console.log(data1, { bankaccountdocfile: data1, userBeneficiaryModel: formdata });

        // let editProfile = new Promise((resolve) => {
        //     resolve(PostTokenApi(API_Path.userBankAccountVerification,{bankaccountdocfile :data1 , userBeneficiaryModel : {...formdata} } ));
        // });
        // editProfile.then((res) => {
        //     if (res.data.Isvalid === true) {
        //         toast.success(res.data.Successes[0]);
        //         resetForm();
        //     } else {
        //         toast.error(res.data.Errors[0]);
        //     }
        // });
    };

    return (
        <Fragment>
            <div className={`${styles.personal_info_body}`}>
                <div className="row">
                    <div className="col-md-12">
                        <div className={`${styles.title} d-flex justify-content-between`}>
                            <h6>Bank Account Verification</h6>
                            {showBeneficiaryStep === 2 && <span>Edit</span>}
                        </div>

                        {showBeneficiaryStep == 6 && (
                            <div className={`${styles.detatils_white_box} py-4 text-center`}>
                                <div className={styles.coming_soon}>
                                    <h5 className="mb-2">COMING SOON!</h5>
                                    <p className="mb-0">We are working on the bank account verification process.</p>
                                </div>
                            </div>
                        )}

                        {showBeneficiaryStep === 2 && (
                            <>
                                <Formik
                                    initialValues={{
                                        AccountNo: "",
                                        ConfirmAccountNo: "",
                                        AccountName: "",
                                        AccountType: "",
                                        BankIFSC: "",
                                    }}
                                    validationSchema={Yup.object().shape({
                                        AccountNo: Yup.string().min(10, "Bank account number must be at least 10 characters").required("Bank account number is required."),
                                        ConfirmAccountNo: Yup.string()
                                            .test("passwords-match", "Account number must match", function (value) {
                                                return this.parent.AccountNo === value;
                                            })
                                            .required("Re-enter bank account number is required."),
                                        AccountName: Yup.string().required("Bank holder name is required."),
                                        AccountType: Yup.string().required("Select account type is required."),
                                        BankIFSC: Yup.string().required("IFSC/MICR code is required."),
                                    })}
                                    onSubmit={(values, { resetForm }) => {
                                        setSubmitData(values, resetForm);
                                    }}
                                >
                                    {(runform) => {
                                        const { touched, errors } = runform;
                                        return (
                                            <Form onSubmit={runform.handleSubmit}>
                                                <div className={styles.personalinfo_width}>
                                                    <div className={styles.personalinfocontent}>
                                                        <label>
                                                            Bank account no.
                                                            <span className={styles.asterisk}>*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="AccountNo"
                                                            placeholder="12121212121212"
                                                            className={`${styles.form_input} ${errors.AccountNo && touched.AccountNo ? "input-error" : ""} `}
                                                            {...formAttr(runform, "AccountNo")}
                                                        />
                                                        {errorContainer(runform, "AccountNo")}
                                                    </div>
                                                    <div className={styles.personalinfocontent}>
                                                        <label>Re-enter bank account no.</label>
                                                        <input
                                                            type="password"
                                                            name="ConfirmAccountNo"
                                                            placeholder="∗∗∗∗∗∗∗∗∗∗∗∗∗∗"
                                                            className={`${styles.form_input} ${errors.ConfirmAccountNo && touched.ConfirmAccountNo ? "input-error" : ""} `}
                                                            {...formAttr(runform, "ConfirmAccountNo")}
                                                        />
                                                        {errorContainer(runform, "ConfirmAccountNo")}
                                                    </div>
                                                    <div className={styles.personalinfocontent}>
                                                        <label>
                                                            Bank holder name.
                                                            <span className={styles.asterisk}>*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            name="AccountName"
                                                            placeholder=""
                                                            className={`${styles.form_input} ${errors.AccountName && touched.AccountName ? "input-error" : ""} `}
                                                            {...formAttr(runform, "AccountName")}
                                                        />
                                                        {errorContainer(runform, "AccountName")}
                                                    </div>
                                                    <div className={styles.personalinfocontent}>
                                                        <label>Account type</label>
                                                        <select
                                                            className={`${styles.form_input} ${errors.AccountType && touched.AccountType ? "input-error" : ""} `}
                                                            name="AccountType"
                                                            {...formAttr(runform, "AccountType")}
                                                        >
                                                            <span className={styles.asterisk}>*</span>
                                                            <option value="">Select account type</option>
                                                            <option value="1">Savings account</option>
                                                            <option value="2">Salary account</option>
                                                            <option value="3">Current account</option>
                                                        </select>
                                                        {errorContainer(runform, "AccountType")}
                                                    </div>
                                                    <div className={styles.personalinfocontent}>
                                                        <label>
                                                            IFSC/MICR code.
                                                            <span className={styles.asterisk}>*</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            name="BankIFSC"
                                                            placeholder=""
                                                            className={`${styles.form_input} ${errors.BankIFSC && touched.BankIFSC ? "input-error" : ""} `}
                                                            {...formAttr(runform, "BankIFSC")}
                                                        />
                                                        {errorContainer(runform, "BankIFSC")}
                                                    </div>
                                                </div>
                                                <div className={`${styles.title} mt-4`}>
                                                    <h6>Upload Document</h6>
                                                </div>
                                                <div className={`${styles.choosefile_lbl} d-flex align-items-center`}>
                                                    <label>
                                                        Choose files
                                                        <input type="file" width="48" height="48" name="image" onChange={(event) => handleFileChange(event)} />
                                                    </label>
                                                    <span>{ImageData ? ImageName : "No file Choose"}</span>
                                                </div>
                                                <div className={styles.choosefile_text}>
                                                    <p> Upload your bank account statement of cancel check detail.</p>
                                                </div>
                                                <div className="form-group">
                                                    <div className={`${styles.personal_info_btn} text-center d-flex p-0`}>
                                                        <button type="submit" className={`${styles.button} primary_button`}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </>
                        )}

                        {showBeneficiaryStep === 3 && (
                            <div className="row mb-4">
                                <div className="col-5">
                                    <div className={`${styles.detatils_white_box} h-100`}>
                                        <div className={styles.title}>
                                            <h6 className="text-info">Account Details</h6>
                                        </div>
                                        <div className={styles.personal_details}>
                                            <ul>
                                                <li>
                                                    <b>Account No.: </b>
                                                    <span>{userBeneficiaryData?.FirstName}</span>
                                                </li>
                                                <li>
                                                    <b>IFSC/SWIFT Code: </b>
                                                    <span>{userBeneficiaryData?.MiddleName ?? ""}</span>
                                                </li>
                                                <li>
                                                    <b>Account Type: </b>
                                                    <span>{userBeneficiaryData?.LastName}</span>
                                                </li>
                                                <li>
                                                    <b>status: </b>
                                                    <span
                                                        className={
                                                            userBeneficiaryData?.BankStatus === 0
                                                                ? styles.status_danger
                                                                : userBeneficiaryData?.BankStatus === 1
                                                                ? styles.status_success
                                                                : userBeneficiaryData?.BankStatus === 2
                                                                ? styles.status_warning
                                                                : userBeneficiaryData?.BankStatus === 3
                                                                ? styles.status_danger
                                                                : ""
                                                        }
                                                    >
                                                        {userBeneficiaryData?.BankStatus === 0
                                                            ? "Not Verified"
                                                            : userBeneficiaryData?.BankStatus === 1
                                                            ? "Verified"
                                                            : userBeneficiaryData?.BankStatus === 2
                                                            ? "In Process"
                                                            : userBeneficiaryData?.BankStatus === 3
                                                            ? "Rejected"
                                                            : ""}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-7">
                                    <div className={`${styles.detatils_white_box} h-100`}>
                                        <div className={styles.title}>
                                            <h6 className="text-info">Document (Bank Account)</h6>
                                        </div>
                                        <div className={styles.personal_details}>
                                            <img src={userBeneficiaryData?.DocFileUrl} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {isAllowSubmitAgain && (
                            <div className="col-12 mt-5">
                                {userBeneficiaryData?.RejectReason && (
                                    <div className={styles.title}>
                                        <h6>Reject Reason : {userBeneficiaryData?.RejectReason}</h6>
                                    </div>
                                )}
                                <div>
                                    <div className={styles.title}>
                                        <h6>Ready to submit your Bank Account ?</h6>
                                    </div>
                                    <div className={`${styles.kyc_alert} ${styles.warning_info}`}>
                                        <span>
                                            You have not completed your Bank Account Verification. We shall approve your Bank Account Verification instantly if the bank detail are valid, clearly
                                            visible and information provided by you is correct.
                                        </span>
                                    </div>
                                    <div className={`${styles.personal_info_btn} py-2`}>
                                        <button type="submit" className={`${styles.button} primary_button`}>
                                            Submit Again
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showBeneficiaryStep === 4 && (
                            <div className="col-md-12">
                                <div>
                                    <p>
                                        <span>Dear {baseName} User,</span>
                                    </p>
                                    <p>
                                        We are upgrading our <b>"Banking & Payments"</b> integrations to ensure seamless experience for the users. The process involves compliance with local banking
                                        regulations and shall be completed in next few days.
                                    </p>
                                    <p>We regret the inconvenience caused to you.</p>
                                    <p>
                                        <span>Regards,</span>
                                        <br />
                                        <span>{baseName} Team</span>
                                    </p>
                                </div>
                            </div>
                        )}

                        {showBeneficiaryStep === 5 && (
                            <div className="col-md-12">
                                <div className="alert alert-info">
                                    <span>Bank account verification is for Indian Users only.</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
