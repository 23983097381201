import React, { useEffect } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/common/Login";
import Register from "./pages/common/Register";
import ConfirmPassword from "./pages/common/ConfirmPassword";
import LoginVerify from "./pages/common/LoginVerify";
import ResendVerification from "./pages/common/ResendVerification";
import TradingRules from "./pages/TradingRules";
import OurFees from "./pages/OurFees";
import MarketStatistics from "./pages/MarketStatistics";
import ProfessionalConnectivity from "./pages/ProfessionalConnectivity";
import Security from "./pages/Security";
import ForgotPassword from "./pages/common/ForgotPassword";
import SystemMonitor from "./pages/SystemMonitor";
import KycAmlPolicy from "./pages/Kyc&amlPolicy";
import LowEnforcementPolicy from "./pages/LowEnforcementPolicy";
import AboutUs from "./pages/AboutUs";
import FullOrderBook from "./pages/FullOrderBook";
import Downloads from "./pages/Downloads";
import OurCommunity from "./pages/OurCommunity";
import Careers from "./pages/Careers";
import TrademarkNotices from "./pages/TrademarkNotices";
import Blog from "./pages/Blog";
import Tickets from "./pages/Tickets";
import CoinInfo from "./pages/CoinInfo";
import TermsOfService from "./pages/TermsOfService";
import ApitermsOfService from "./pages/ApitermsOfService";
import RiskdisClosureStatement from "./pages/RiskdisClosureStatement";
import CookiesPolicy from "./pages/CookiesPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RegistrationCompletedModal from "./pages/common/RegistrationCompletedModal";
import VerificationMailModal from "./pages/common/VerificationMailModal";
import EmailVerificationModal from "./pages/common/ApiverifyModal";
import ResendemailVerifyModal from "./pages/common/ResendemailVerifyModal";
import NotFound from "./pages/NotFound";
import Profile from "./pages/Profile";
import TicketsDetails from "./pages/TicketsDetails";
import OrderHistory from "./pages/OrderHistory";
import BlogDetails from "./pages/BlogDetails";
import TradingAdvance from "./pages/TradingAdvance";
import TradingClassic from "./pages/TradingClassic";
import ScrollToTop from "./ScrollToTop";
import ApiverifyModal from "./pages/common/ApiverifyModal";
import FreezeAccount from "./pages/common/FreezeAccount";
import AssetWallet from "./pages/AssetWallet";

export default function RoutesMain() {
    function RequireAuth({ children, redirectTo }) {
        let isAuthenticated = localStorage.getItem("FunexAuthorizationData");
        return isAuthenticated !== null && isAuthenticated !== undefined && isAuthenticated !== "" ? children : <Navigate to={redirectTo} />;
    }

    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />

                <Route path="/register-verify" element={<RegistrationCompletedModal />} />
                <Route path="/register-email-verify/:id" element={<VerificationMailModal />} />
                <Route path="/emailverify/:id" element={<EmailVerificationModal />} />
                <Route path="/resend-email-verify" element={<ResendemailVerifyModal />} />

                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ConfirmPassword />} />
                <Route path="/login-verify/:id" element={<LoginVerify />} />
                <Route path="/resendemailverify" element={<ResendVerification />} />

                <Route path="/apiverify/:id" element={<ApiverifyModal />} />
                <Route
                    path="/freezeaccount/:id"
                    element={
                        // <RequireAuth redirectTo="/">
                        <FreezeAccount />
                        // </RequireAuth>
                    }
                />

                <Route path="/trading/classic/:id" element={<TradingClassic />} />
                <Route path="/trading/advance/:id" element={<TradingAdvance />} />

                <Route path="/security" element={<Security />} />
                <Route path="/professional-connectivity" element={<ProfessionalConnectivity />} />
                <Route path="/orderbook" element={<FullOrderBook />} />
                <Route path="/market-statistics" element={<MarketStatistics />} />
                <Route path="/coin-status" element={<SystemMonitor />} />

                <Route path="/trading-rules" element={<TradingRules />} />
                <Route path="/fees" element={<OurFees />} />
                <Route path="/law-enforcement-policy" element={<LowEnforcementPolicy />} />
                <Route path="/trademark-notices" element={<TrademarkNotices />} />
                <Route path="/kyc-aml-policy" element={<KycAmlPolicy />} />
                <Route path="/info" element={<CoinInfo />} />

                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/download" element={<Downloads />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:id" element={<BlogDetails />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/community" element={<OurCommunity />} />

                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/api-terms-of-service" element={<ApitermsOfService />} />
                <Route path="/risk-disclosure-statement" element={<RiskdisClosureStatement />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/cookies-policy" element={<CookiesPolicy />} />

                <Route
                    path="/tickets"
                    element={
                        <RequireAuth redirectTo="/">
                            <Tickets />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/tickets-detail/:id"
                    element={
                        <RequireAuth redirectTo="/">
                            <TicketsDetails />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/useraccount/profile"
                    element={
                        <RequireAuth redirectTo="/">
                            <Profile />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/useraccount/profile/:subPage?"
                    element={
                        <RequireAuth redirectTo="/">
                            <Profile />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/orderhistory"
                    element={
                        <RequireAuth redirectTo="/">
                            <OrderHistory />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/useraccount"
                    element={
                        <RequireAuth redirectTo="/">
                            <AssetWallet />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/useraccount/:subPage?"
                    element={
                        <RequireAuth redirectTo="/">
                            <AssetWallet />
                        </RequireAuth>
                    }
                />

                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
}
