import React, { useState } from "react";
import Logo from "./Logo";
import styles from "../../styles/register.module.css";
import authImg from "../../assets/images/auth_img_two.png";
import { Link } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { API_Path, baseUrl } from "../../const";
import { PostApi } from "../../api/api-service";
import { toast } from "react-toastify";
import Loading from "../../components/loader/Loading";

export default function ResendVerification() {
    const [loading, setLoading] = useState(false);
    const setSubmitData = (formData, resetForm) => {
        setLoading(true);
        let data = {
            EmailIdOrApi: formData.email,
            BaseUrl: baseUrl,
        };
        const emailverifyData = new Promise((resolve) => {
            resolve(PostApi(API_Path.resendEmailVerify, data));
        });
        emailverifyData.then((res) => {
            resetForm(formData);
            if (res) {
                if (res.data.Isvalid === true) {
                    toast.success(res.data.Successes[0]);
                } else {
                    toast.error(res.data.Errors[0]);
                }
                setLoading(false);
            }
        });
    };
    return (
        <React.Fragment>
            {loading && <Loading />}
            <div className="container-fluid">
                <div className="row">
                    <div className={`${styles.col_width_left} ${styles.col_width_flex}`}>
                        <div className={styles.main_box}>
                            <div className={styles.lr_logo}>
                                <Logo />
                            </div>
                            <div className="mb-4">
                                <h3>
                                    Resend Verification <br /> E-mail
                                </h3>
                                <div className={`${styles.agreeTermsStyle} my-4`}>Please enter your registered e-mail address and click on the link below to receive verification e-mail.</div>
                            </div>
                            <Formik
                                initialValues={{ email: "" }}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string().email("Email id is invalid.").required("Email id is required."),
                                })}
                                onSubmit={(FormData, { resetForm }) => {
                                    setSubmitData(FormData, resetForm);
                                }}
                            >
                                {(runform) => {
                                    const { touched, errors } = runform;
                                    return (
                                        <Form onSubmit={runform.handleSubmit}>
                                            <div className={`${styles.inputWidth} mb-3`}>
                                                <label className={`${styles.login_label_text} d-block`}>Email</label>
                                                <div className="d-block position-relative">
                                                    <Field
                                                        type="email"
                                                        className={` ${styles.login_comn_input} ${errors.email && touched.email && "input-error"}`}
                                                        name="email"
                                                        placeholder="example@gmail.com"
                                                    />
                                                    <span className={styles.login_input_icon}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                                            <path
                                                                d="M6.66675 3.33337H3.33341C2.41294 3.33337 1.66675 4.07957 1.66675 5.00004V15C1.66675 15.9205 2.41294 16.6667 3.33341 16.6667H16.6667C17.5872 16.6667 18.3334 15.9205 18.3334 15V5.00004C18.3334 4.07957 17.5872 3.33337 16.6667 3.33337H13.3334"
                                                                stroke="#404040"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M18.3334 5.83337L10.8584 10.5834C10.6011 10.7446 10.3037 10.83 10.0001 10.83C9.69648 10.83 9.39902 10.7446 9.14175 10.5834L1.66675 5.83337"
                                                                stroke="#404040"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <ErrorMessage name="email" component="div" className="error-message" />
                                            </div>
                                            <div className={styles.loginBtn}>
                                                <button type="submit" className="button primary_button w-100">
                                                    Submit
                                                </button>
                                            </div>
                                            <p className={`${styles.login_bottomtext} text-start`}>
                                                E-mail Verified? <Link to="/login"> Login</Link>
                                            </p>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                    <div className={`${styles.col_width_right} p-0 d-lg-block d-none`}>
                        <div className={`${styles.login_side_img} position-relative`}>
                            <img src={authImg} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
