import React, { useEffect, useState, useRef } from "react";
import styles from "../styles/fullorderbook.module.css";
import Layout from "../components/Layout/Layout";
import { API_Path, socketUrl } from "../const";
import { GetApi } from "../api/api-service";
import io from "socket.io-client";
import { toast } from "react-toastify";
import emptyRecordImg from "../assets/images/empty_record.svg";
import Loading from "../components/loader/Loading";
import arrow from "../assets/images/arrow.svg";
import { exportDropdown, parseFloatAmt } from "../components/common/CommonFun";

const socket = io.connect(socketUrl);

export default function FullOrderBook() {
    const [loader, setLoader] = useState(false);
    const [pairs, setPairs] = useState([]);
    const [firstCoins, setFirstCoins] = useState([]);
    const [selectedFirstCoin, setSelectedFirstCoin] = useState("BTC");
    const [lastCoins, setLastCoins] = useState([]);
    const [selectedLastCoin, setSelectedLastCoin] = useState("USDT");
    const [buySpread, setBuySpread] = useState(0);
    const [sellSpread, setSellSpread] = useState(0);
    const [priceDecimal, setPriceDecimal] = useState(0);
    const [volumeDecimal, setVolumeDecimal] = useState(0);
    const [bookSell, setBookSell] = useState([]);
    const [bookBuy, setBookBuy] = useState([]);
    const [showLoaderBookSell, setShowLoaderBookSell] = useState(true);
    const [showLoaderBookBuy, setShowLoaderBookBuy] = useState(true);

    const initializedRef = useRef(false);

    useEffect(() => {
        if (!initializedRef.current) {
            initializedRef.current = true;
            getMarketMainCoin();
        }
    }, []);

    useEffect(() => {
        exportDropdown();
    }, [lastCoins, firstCoins]);

    useEffect(() => {
        const pairName = selectedFirstCoin + selectedLastCoin;
        const pairObj = pairs.find((x) => x.PAIRNAME === pairName);
        if (pairObj) {
            setBuySpread(pairObj.SPREAD_BUY);
            setSellSpread(pairObj.SPREAD_SELL);
            setPriceDecimal(pairObj.PRICE_PRECISION);
            setVolumeDecimal(pairObj.VOLUME_PRECISION);
        }
        setLoader(true);
        socketConnect(true);
        sendSocketService();
        return () => {
            socketConnect(false);
        };
    }, [selectedFirstCoin, selectedLastCoin, pairs]);

    const getMarketMainCoin = async () => {
        setLoader(true);
        try {
            const response = await GetApi(API_Path.GetMarketMainCoin);
            if (response.data.length > 0) {
                setLoader(false);
                setPairs(response.data);
                const firstCoins = [...new Set(response.data.map((item) => item.FIRST))];
                if (firstCoins.length > 0) {
                    setFirstCoins(firstCoins);
                    const activePair = response.data.find((x) => x.v_active === "1");
                    setSelectedFirstCoin(activePair?.FIRST || firstCoins[0]);
                }

                const lastCoins = [...new Set(response.data.map((item) => item.LAST))];
                if (lastCoins.length > 0) {
                    setLastCoins(lastCoins);
                    setSelectedLastCoin(lastCoins[0]);
                }

                const pairname = localStorage.getItem("RunningPair");
                if (pairname) {
                    const selectedData = response.data.find((pair) => pair.PAIRNAME.toLowerCase() === pairname.toLowerCase());
                    setSelectedFirstCoin(selectedData.FIRST);
                    setSelectedLastCoin(selectedData.LAST);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const socketConnect = (connect) => {
        if (connect) {
            if (!socket.connected) {
                socket.connect();
                setTimeout(() => {
                    if (!socket.connected) {
                        console.log("Error connecting to socket");
                    }
                }, 5000);
            }
        } else {
            if (socket.connected) {
                socket.disconnect();
            }
        }
    };

    const sendSocketService = () => {
        socketReceiveMarkets();
        // setTimeout(() => {
        socketSendMarkets();
        // }, 200);
    };

    const socketReceiveMarkets = () => {
        setLoader(false);
        socket.on("ReceiveOrderBook", (data) => {
            try {
                if (data.aggAsks) {
                    setBookSell([...data.aggAsks].reverse());
                }
                if (data.aggBids) {
                    setBookBuy(data.aggBids);
                }
                setShowLoaderBookSell(false);
                setShowLoaderBookBuy(false);
            } catch (ex) {
                console.log("Error processing data:", ex);
            }
        });
    };

    const socketSendMarkets = () => {
        const pairObj = pairs.find((x) => x.PAIRNAME === selectedFirstCoin + selectedLastCoin);
        if (pairObj) {
            socket.emit("SendOrderBook", {
                PAIR: selectedFirstCoin + selectedLastCoin,
                COUNT: 5000,
                BUY_SPREAD: parseFloat(pairObj.SPREAD_BUY),
                SELL_SPREAD: parseFloat(pairObj.SPREAD_SELL),
                priceDecimal: pairObj.PRICE_PRECISION,
                volumeDecimal: pairObj.VOLUME_PRECISION,
            });
        } else {
            setBookSell([]);
            setBookBuy([]);
        }
    };

    const handleLastCoin = (item) => {
        setSelectedLastCoin(item);
    }

    return (
        <Layout>
            {loader && <Loading />}
            <div className="common_bg_style bg-position">
                <div className="container">
                    <div className="row">
                        <div className={styles.head_section}>
                            <div className="title_heading d-flex p-0">
                                <p>Trading /</p>
                                <h4>Full Order Book</h4>
                            </div>
                            {firstCoins.length > 0 && lastCoins.length > 0 && (
                                <div className={styles.adv_filter}>
                                    <div className="custom-list-dropdown" id="dropdown_firstCoin">
                                        <span className="selected-display" id="destination">
                                            {selectedFirstCoin}
                                        </span>
                                        <img src={arrow} className="ri-arrow-down-s-line" alt="" />
                                        <ul className="list-item-dropdown">
                                            {firstCoins.map((item, i) => (
                                                <li key={i} onClick={() => setSelectedFirstCoin(item)}>
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="custom-list-dropdown" id="dropdown_lastCoin">
                                        <span className="selected-display" id="destination">
                                            {selectedLastCoin}
                                        </span>
                                        <img src={arrow} className="ri-arrow-down-s-line" alt="" />
                                        <ul className="list-item-dropdown">
                                            {lastCoins.map((item, i) => (
                                                <li key={i} onClick={() => handleLastCoin(item)}>
                                                    {item}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={`${styles.table_section} row`}>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className={styles.coin_list_left}>
                                <div className={styles.table_head}>
                                    <ul>
                                        <li>Amount ({selectedFirstCoin})</li>
                                        <li>Price ({selectedLastCoin})</li>
                                        <li>Total ({selectedFirstCoin})</li>
                                    </ul>
                                </div>
                                <div className={`${styles.table_body} position-relative`}>
                                    <ul>
                                        {bookBuy.length > 0 ? (
                                            bookBuy.map((buy, i) => (
                                                <li key={i}>
                                                    <p className="ng-binding">{parseFloatAmt(buy.volume, volumeDecimal)}</p>
                                                    <p className="green_color">{parseFloatAmt(buy.price, priceDecimal)}</p>
                                                    <p className="ng-binding">{buy.totalBase}</p>
                                                </li>
                                            ))
                                        ) : (
                                            <li className="d-block">
                                                <div className={`${styles.empty_record} ${styles.empty_record_exchange}`}>
                                                    <img src={emptyRecordImg} alt="" />
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className={styles.coin_list_right}>
                                <div className={styles.table_head}>
                                    <ul>
                                        <li>Amount ({selectedFirstCoin})</li>
                                        <li>Price ({selectedLastCoin})</li>
                                        <li>Total ({selectedFirstCoin})</li>
                                    </ul>
                                </div>
                                <div className={`${styles.table_body} ${styles.table_body_right}`}>
                                    <ul>
                                        {bookSell.length > 0 ? (
                                            bookSell.map((sell, i) => (
                                                <li key={i}>
                                                    <p className="ng-binding">{parseFloatAmt(sell.volume, volumeDecimal)}</p>
                                                    <p className="red_color">{parseFloatAmt(sell.price, priceDecimal)}</p>
                                                    <p className="ng-binding">{sell.totalBase}</p>
                                                </li>
                                            ))
                                        ) : (
                                            <li className="d-block">
                                                <div className={`${styles.empty_record} ${styles.empty_record_exchange}`}>
                                                    <img src={emptyRecordImg} alt="" />
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
