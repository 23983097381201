import React from "react";
import styles from "../styles/termsofservice.module.css";
import { Link } from "react-router-dom";
import { baseName, baseUrl, companyName, supportUrl } from "../const";
import Layout from "../components/Layout/Layout";

export default function TermsOfService() {
    return (
        <Layout>
            <section className={styles.section}>
                <div className="container">
                    <div className={styles.section_body}>
                        <div className="title_heading d-flex">
                            <p>Explore /</p>
                            <h4>Terms Of Service</h4>
                        </div>
                        <div className={styles.section_content}>
                            <p>
                                The "Terms of Use" given here together with {companyName.toLowerCase()} “Privacy Policy” comprises a legal “Agreement” between the “User” (referred to as "you" or
                                "your" and means any natural person or entity and its authorized representative that uses or subscribes to the “Services”) and {companyName.toLowerCase()} Projects Pte.
                                Ltd. Singapore & its subsidiaries and/or any of its partners which operates {companyName.toLowerCase()} Platform (referred herein or hereafter as "
                                {companyName.toLowerCase()}", "we", "us" or “our”). By using or accessing the services provided by {companyName.toLowerCase()} or its partners through websites, mobile
                                sites, mobile applications and desktop applications, applets and other applications you are accepting this agreement on behalf of yourself or the entity that you
                                represent.
                            </p>
                            <p>
                                You warrant that you have the right, authority and capacity to enter into this agreement. By clicking on the "I Agree" link, you accept that you have read and
                                understood all the terms and conditions given in this "Terms of Use" or Terms and Privacy Policy available on the website. Also, certain features of{" "}
                                {companyName.toLowerCase()} Platform may require you to adhere to additional guidelines, terms, or rules while using these services.
                            </p>
                            <p>
                                We may amend this Agreement related to the Services from time to time. Amendments will be effective upon our posting of such updated “Agreement” at this location or the
                                amended policies or supplemental terms on the applicable service. Your continued access or use of the “Services” after such posting constitutes your consent to be bound
                                by the Agreement, as amended.
                            </p>

                            <div className={styles.pere_content}>
                                <h1>PLEASE READ AND UNDERSTAND THE FOLLOWING TERMS AND CONDITIONS AS THEY GOVERN THE USE OF {baseName.toUpperCase()} & ITS SERVICES BY YOU:</h1>
                                <p>
                                    <b>(A) </b>YOU ACKNOWLEDGE THAT TRADING IN CRYPTOCURRENCIES AND DIGITAL ASSETS INVOLVES A HIGH DEGREE OF RISK. CRYPTOCURRENCIES AND DIGITAL ASSETS ARE SUBJECT TO
                                    CONSTANT AND FREQUENT FLUCTUATIONS IN VALUE AND EXCHANGE RATES, AND THE VALUE OF YOUR CRYPTOCURRENCY AND DIGITAL ASSETS MAY INCREASE OR DECREASE AT ANY TIME.
                                    ACCORDINGLY, YOU MAY SUFFER A COMPLETE LOSS OF THE FUNDS HELD IN YOUR ACCOUNT. YOU ACKNOWLEDGE THAT YOU ARE SOLELY RESPONSIBLE FOR DETERMINING THE NATURE,
                                    SUITABILITY, AND APPROPRIATENESS OF THESE TRADING RISKS FOR YOU. YOU ACKNOWLEDGE AND AGREE THAT {companyName.toUpperCase()} BEARS NO RESPONSIBILITY OR LIABILITY TO
                                    YOU OR ANY OTHER PERSON WHATSOEVER FOR ANY LOSSES OR GAINS INCURRED IN CONNECTION WITH YOUR USE OF THE SERVICES. YOU ALSO ACKNOWLEDGE AND AGREE THAT{" "}
                                    {companyName.toUpperCase()} DOES NOT GIVE ADVICE OR RECOMMENDATIONS REGARDING THE TRADING OF CRYPTOCURRENCIES, INCLUDING THE SUITABILITY AND APPROPRIATENESS OF, AND
                                    INVESTMENT STRATEGIES FOR, CRYPTOCURRENCIES.
                                </p>
                                <p>
                                    <b>(B)</b> YOU ALSO ACKNOWLEDGE AND AGREE THAT {companyName.toUpperCase()} AND YOUR ABILITY TO USE THE SERVICES MAY BE DETRIMENTALLY IMPACTED BY REGULATORY ACTION
                                    OR CHANGES IN REGULATIONS CONCERNING THE CRYPTOCURRENCY AND DIGITAL ASSETS MARKETS. YOU AGREE THAT WE MAY DISCLOSE YOUR PERSONAL AND ACCOUNT INFORMATION IF WE
                                    BELIEVE THAT IT IS REASONABLY NECESSARY TO COMPLY WITH A LAW, REGULATION, LEGAL PROCESS, OR GOVERNMENTAL REQUEST. YOU HEREBY ACKNOWLEDGE AND AGREE THAT{" "}
                                    {companyName.toUpperCase()} IS NOT A FINANCIAL INSTITUTION, BANK, CREDIT UNION, TRUST, HEDGE FUND, BROKER OR INVESTMENT OR FINANCIAL ADVISOR, AND IS NOT SUBJECT TO
                                    THE SAME LAWS, REGULATIONS, DIRECTIVES OR REQUIREMENTS APPLICABLE TO SUCH PERSONS OR ENTITIES. YOU ACKNOWLEDGE AND AGREE THAT NO ORAL OR WRITTEN INFORMATION OR
                                    ADVICE PROVIDED BY {companyName.toUpperCase()}, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, NOR ANY INFORMATION OBTAINED THROUGH THE SERVICES, WILL OR SHALL
                                    CONSTITUTE INVESTMENT, OR FINANCIAL ADVICE. YOU ARE SOLELY AND EXCLUSIVELY RESPONSIBLE FOR ALL TRADING DECISIONS MADE BY YOU WHILE USING THE SERVICES.
                                </p>
                                <p>
                                    <b>(C)</b> {companyName.toUpperCase()} IS MERELY A TECHNOLOGY PLATFORM WHERE CRYPTOCURRENCY AND DIGITAL ASSETS ARE TRADED AND YOU ARE SOLELY AND ENTIRELY
                                    RESPONSIBLE FOR THE LEGAL, REGULATORY AND TAX COMPLIANCE OF ALL TRANSACTIONS CARRIED OUT BY YOU USING THE SERVICES.
                                </p>
                            </div>
                            <p>
                                You may not access or use the services or accept this agreement if you are not at least 18 years old. If you do not agree with all of the provisions of this agreement,
                                do not access and/or use the site. These terms require the use of Arbitration on an individual basis to resolve disputes, rather than jury trials or class actions, and
                                also limit the remedies available to you in the event of a dispute.
                            </p>
                            <div className={styles.pere_content_box}>
                                <h1>1. AGREEMENT IMPORTANT CONDITIONS</h1>
                                <p>
                                    We may amend this Agreement related to the Services from time to time. Amendments will be effective upon our posting of such updated “Agreement” at this location or
                                    the amended policies or supplemental terms on the applicable service. Your continued access or use of the “Services” after such posting constitutes your consent to
                                    be bound by the Agreement, as amended.
                                </p>
                                <p>
                                    All such changes made in “Terms of Use” will be effective immediately once they get posted here. {companyName} also encourages it’s users to regularly review these
                                    “Terms of Use”. Your continued access or use of the “Services” constitutes your consent to be bound by the Agreement, as amended.
                                </p>
                            </div>
                            <div className={styles.pere_content_box}>
                                <h1>2. ELIGIBILITY</h1>
                                <bdi>By accepting this Agreement, you consent that:</bdi>
                                <p>
                                    You may not access or use the services or accept this agreement if you are not at least 18 years old or any other age, legal in your jurisdiction for entering into
                                    a binding contract as per the law. You warrant that you have the right, authority and capacity to enter into this agreement.
                                </p>
                                <p>
                                    You understand and accept that {companyName.toLowerCase()} reserves the right to decide about the cryptocurrency and digital assets which can be traded on its
                                    platform and specific countries where our services can be provided.
                                </p>
                                <bdi>You have not been previously banned by {companyName.toLowerCase()} from using its services.</bdi>
                                <p>
                                    You don’t have any other {companyName.toLowerCase()} account, currently. If you are entering or accessing this account on behalf of any other legal entity where you
                                    work as an Office, Director, Employee or Agent, you must have a valid warrant to bind such a legal entity.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h1>3. PROHIBITION OF ACCESS TO SERVICES</h1>
                                <p>
                                    You are prohibited from using our services if you are an individual or organization banned under UN Security Council trade and economic sanctions list.
                                    Additionally, You must not be a resident individual or organization from a country falling under FATF Black List ( High-Risk Jurisdictions subject to a Call for
                                    Action) & Grey List (Jurisdictions Under Increased Monitoring). We can restrict the availability of our services to certain countries/regions at our sole
                                    discretion.
                                </p>
                                <p>
                                    The Services are only available in jurisdictions where they may be legally offered for sale. The Services are not available to “U.S. Persons”, as such term is
                                    defined under Regulation S promulgated under the Securities Act of 1933. By accessing or using the {companyName.toLowerCase()} platform, you represent and warrant
                                    that you are not a U.S. Person.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h1>4. SERVICES DESCRIPTION</h1>
                                <p>
                                    {companyName} provides an online cryptocurrency and digital assets trading platform. Through {companyName.toLowerCase()}, you can seamlessly trade or exchange many
                                    popular cryptocurrencies and digital assets like Bitcoin (BTC), Ethereum (ETH), Bitcoin Cash (BCH), Litecoin etc. We are focused on constantly expanding the scope
                                    of our products and services to enhance the customer experience.
                                </p>
                                <p>
                                    We are committed to protect and safeguard our user's data. However, {companyName.toLowerCase()} disclaims any and all responsibility or liability in relation to the
                                    accuracy, completeness and suitability of the content made available through the services, including the customer content, or any content or services provided by
                                    third parties. {companyName.toLowerCase()} does not control or vet customer content and is not responsible for what users post, transmit, or share on or through the
                                    services. {companyName.toLowerCase()} is not responsible or liable in any manner for any third-party services associated with or utilized in connection with the
                                    services, including the failure of any such third-party services or supported platforms.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h1>5. {companyName.toUpperCase()} ACCOUNT REGISTRATION</h1>
                                <b>(A) REGISTRATION & LOGIN</b>
                                <p>
                                    In order to access {companyName.toLowerCase()} services, every user must register or create their account at{" "}
                                    <Link to="/register" target="_blank">
                                        “Register”
                                    </Link>
                                    . For successful registration, you must enter a valid email ID along with a strong password and accept the “Terms of Use”. You must provide valid, accurate and
                                    complete information while registering your account on {companyName.toLowerCase()}. Every user account is linked to a unique e-mail ID and/or mobile no. even if it
                                    belongs to a Corporate entity or business user.
                                </p>
                                <b>(B) USER IDENTITY VERIFICATION & KYC</b>
                                <p>
                                    You must agree to provide your personal information at the time of creating an account with {companyName.toLowerCase()} for the sole purpose of user identity
                                    verification. Such user information shall be used for complying with AML and CFT laws and identifying other fraudulent activities which a user might carry out
                                    through {companyName.toLowerCase()}
                                    platform. The usage of personal information collected from the users shall be governed by our privacy policy. As per the existing standards followed by various
                                    global crypto exchanges, we reserve the right to keep your personal data for a period of 5 years after you cease to have a relationship with{" "}
                                    {companyName.toLowerCase()} or your account is deleted by {companyName.toLowerCase()}. You can acquire information about how your personal information is used by{" "}
                                    {companyName.toLowerCase()}.
                                </p>
                                <p>
                                    You must regularly update your relevant personal information and {companyName.toLowerCase()} reserves it right to suspend or terminate its services for a user who
                                    has provided outdated, incomplete, wrong, or false information. In case we detect fraudulent user behavior or submission of wrong information, your account shall be
                                    immediately suspended or terminated on the basis of outcome of our internal enquiry.
                                </p>
                                <p>
                                    By registering or creating your account with {companyName.toLowerCase()}, you consent and authorize us to make inquiries directly, indirectly or via third parties
                                    to confirm or verify your identity. You agree that {companyName.toLowerCase()} can take reasonable actions to protect you and other platform users from any type of
                                    financial fraud.
                                    {companyName.toLowerCase()} will also be authorized from your end to disclose your personal data with financial crime agencies or any other fraud prevention
                                    agencies to protect you and other platform users from frauds.
                                </p>
                                <b>(C) ACCOUNT USAGE GUIDELINES FOR USERS</b>
                                <p>
                                    The account registered on the {companyName.toLowerCase()} platform must be used only by the individual under whose name it has been registered or the authorized
                                    official of the corporate entity registered with {companyName.toLowerCase()}. {companyName} has the rights to freeze, suspend or terminate any account which is
                                    found to be used in violation of these terms. You must immediately report unauthorized usage of your account and {companyName.toLowerCase()} shall not be
                                    responsible or liable in any manner for loss or damage arising out of unauthorized usage of your account.
                                </p>
                                <b>(D) ACCOUNT SECURITY</b>
                                <p>
                                    {companyName} has implemented industry-leading security standards to protect and safeguard user funds, cryptocurrency and digital assets. However, you hereby agree
                                    that you shall not disclose your confidential account information such as username, account password and One Time Password (OTP) with anyone, including any
                                    third-party. and take all reasonable steps to safeguard and protect your account and your personal information.
                                </p>
                                <span>
                                    YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR THE LEGAL, REGULATORY AND TAX COMPLIANCE OF ALL TRANSACTIONS CARRIED OUT BY YOU USING THE SERVICES.{" "}
                                    {companyName.toUpperCase()} SHALL NOT BE RESPONSIBLE OR LIABLE IN ANY MANNER FOR LOSS OR DAMAGE ARISING OUT OF ILLEGAL USAGE OF YOUR ACCOUNT.
                                </span>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h1>6. USAGE OF {companyName.toUpperCase()} SERVICES : GUIDELINES</h1>
                                <b>(A) LICENSE</b>
                                <p>
                                    Considering the continued adherence to the express terms and conditions given here, {companyName.toLowerCase()} shall provide you a limited, royalty-free,
                                    non-exclusive, revocable, non-transferable access right to the Services through your personal computer or any other internet-enabled device. You are not authorized
                                    to use or render any of our Services for commercial or resale purposes, including placing orders on behalf of any other person or third-party. All such usage of our
                                    Services shall be categorized as illegal and in violation of the “Terms of Use”. Usage of Services in any manner that is not stated in “Terms of Use” or explicitly
                                    authorized is prohibited.
                                </p>
                                <p>
                                    {companyName}, in any manner, does not transfer any legal ownership of intellectual property rights to you or any other user with respect to the usage of our
                                    Services. All the {companyName.toLowerCase()} platform assets including the text, graphics, photographs, sounds, computer code, artwork, visual interfaces,
                                    documentation and the “look and feel”, expression, structure, selection, coordination, design and arrangement of the content available on our platform or through
                                    our Services are directly, exclusively, singly owned, controlled and/or licensed by {companyName.toLowerCase()} or its partners, owners, licensors, affiliates and
                                    related parties.
                                </p>
                                <p>
                                    Any feedback, ideas, suggestions or other information received by {companyName.toLowerCase()} from users via email, official social media channels, through our
                                    websites, or other medium shall be owned by {companyName.toLowerCase()} and {companyName.toLowerCase()} shall have the sole right together with intellectual
                                    property rights to the content obtained in such manner. No user can claim compensation or ownership against any Feedback or Suggestion provided to{" "}
                                    {companyName.toLowerCase()}.
                                </p>
                                <b>(B) PROHIBITIONS & RESTRICTIONS</b>
                                <h6>While using the Services you agree and covenant to observe the following:</h6>
                                <p>
                                    All activities carried out by you during the use of our Services will be in compliance of applicable laws, rules, regulations related to cryptocurrency trading in
                                    your country and/or jurisdiction as well as the guidelines proposed by {companyName.toLowerCase()}.
                                </p>
                                <p>
                                    Your use of our Services, in any manner, would not be allowed to violate public ethics, interests or any legitimate interests of other persons. You will not take
                                    any such action that would negatively affect, disrupt or inhibit other users from accessing our Services.
                                </p>
                                <p>
                                    You consent that using our services while staying involved in market manipulation activities (e.g. such as price manipulation, quote stuffing, wash trading,
                                    self-trading, front running, etc.) will get you permanently banned from accessing our services.
                                </p>
                                <p>
                                    You, in any case, will not download, copy, duplicate, reproduce, disassemble, disseminate, publish, broadcast, further transmit, remover or alter any of the
                                    proprietary labels or notices without obtaining prior written consent from {companyName.toLowerCase()}.
                                </p>
                                <h6>In any case, you shall not:</h6>
                                <p>
                                    Use any "page-scrapper," "robot," "spider," "deep-link," or any other device, algorithm, script or methodology and/or other manual process to gather, acquire, copy
                                    or monitor our services. You are also not allowed to circumvent or reproduce the presentation or navigational structure of our Services in an attempt to gather some
                                    information, documents or materials through any means that are not publicly made available through our Services;
                                </p>
                                <p>
                                    Attempt to gain unauthorized access to any portion or feature of our Services or any other systems and networks linked to our Services or to any servers hosting
                                    {companyName.toLowerCase()} services through the means of password “mining”, “security breach”, “cyber-attack” or any other prohibited or illegitimate manner;
                                </p>
                                <p>
                                    Scan, test or probe the vulnerability or sensitivity of the servers connected to our services, neither you should breach the authentication measures used by our
                                    Services or any other networks connected to our Services;
                                </p>
                                <p>
                                    Take any action that imposes a disproportionate and unreasonable load on the infrastructure of our Services or {companyName.toLowerCase()} IT systems or networks or
                                    any other network part of our infrastructure;
                                </p>
                                <p>
                                    Use any particular software, device or perform a routine that may cause harm to the proper working of our Services or any of the transactions processed on our
                                    Servers or negatively affect, disrupt or inhibit other users and their transactions while using our Services;
                                </p>
                                <p>Violate (or assist any other party in violating) any applicable law, statute, ordinance, or regulation;</p>
                                <p>Intentionally try to defraud (or assist in the defrauding of) {companyName.toLowerCase()} or any other User;</p>
                                <p>Provide false, inaccurate, or misleading information;</p>
                                <p>Trade taking advantage of any technical glitch, malfunction, failure, delay, default, or security breach;</p>
                                <p>Uartake in any transaction involving the proceeds of illegal activity;</p>
                                <p>Use the Services to engage in conduct that is detrimental to {companyName.toLowerCase()} or to any other User or any other third party;</p>
                                <p>Defame, harass, or violate the privacy or intellectual property rights of {companyName.toLowerCase()} or any other User;</p>
                                <p>
                                    Upload, display or transmit any messages, photos, videos or other media that contain illegal goods, pornographic, violent, obscene or copyrighted images or
                                    materials for use as an avatar, in connection with a payment or payment request, or otherwise;
                                </p>
                                <p>Upload, transmit, or distribute to or through the Services any computer viruses, worms, or any software intended to damage or alter a computer system or data;</p>
                                <p>
                                    Attempt to reverse engineer, de-compile, disable, interfere with the performance of the Services, any third-party use of the Services, or any third-party data
                                    contained therein (except to the extent such restrictions are prohibited by applicable law;)
                                </p>
                                <p>Access our Services in order to build a competitive product or service or copy any ideas, features, functions, or graphics of the Services.</p>
                                <p>
                                    By accessing the Service, you agree that {companyName.toLowerCase()} shall have the right (without obligation) to investigate any violation of these “Terms of Use’,
                                    unilaterally determine whether you have violated these terms, and take actions to apply relevant rules without receiving your consent or giving prior notice to you.
                                    You consent that {companyName.toLowerCase()} has the right to investigate the violation of any of the terms given here and take appropriate measures including the
                                    legal action against you without receiving your consent or giving prior notice to you.
                                </p>
                                <h6>Examples of appropriate measures taken from our side include:</h6>
                                <p>Block order requests.</p>
                                <p>Send warning alerts and messages in relation to specific user activity.</p>
                                <p>Publishing the alleged services violations and initiate legal actions.</p>
                                <b>(C) ACCOUNT USAGE GUIDELINES FOR USERS</b>
                                <p>
                                    The account registered on the {companyName.toLowerCase()} platform must be used only by the individual under whose name it has been registered or the authorized
                                    official of the corporate entity registered with {companyName.toLowerCase()}. {companyName} has the rights to freeze, suspend or terminate any account which is
                                    found to be used in violation of these terms. You must immediately report unauthorized usage of your account and {companyName.toLowerCase()} shall not be
                                    responsible or liable in any manner for loss or damage arising out of unauthorized usage of your account.
                                </p>
                                <b>(D) ACCOUNT SECURITY</b>
                                <p>
                                    {companyName} has implemented industry-leading security standards to protect and safeguard user funds, cryptocurrency and digital assets. However, you hereby agree
                                    that you shall not disclose your confidential account information such as username, account password and One Time Password (OTP) with anyone, including any
                                    third-party. and take all reasonable steps to safeguard and protect your account and your personal information.
                                </p>
                                <bdi className="pb-0">
                                    YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR THE LEGAL, REGULATORY AND TAX COMPLIANCE OF ALL TRANSACTIONS CARRIED OUT BY YOU USING THE SERVICES.{" "}
                                    {companyName.toUpperCase()} SHALL NOT BE RESPONSIBLE OR LIABLE IN ANY MANNER FOR LOSS OR DAMAGE ARISING OUT OF ILLEGAL USAGE OF YOUR ACCOUNT.
                                </bdi>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h1>7. ORDERS AND SERVICE FEES</h1>

                                <b>(A) ORDER PLACING</b>
                                <p>
                                    Using Our Services, when you place an order (“trading activity”), your Account balance will get updated automatically reflecting your order and this order will be
                                    stored in {companyName.toLowerCase()} order book to match it with orders placed by other users (price and time priority). If a part or whole of your order matches
                                    with any of the orders placed by other users, our Services will execute an exchange “trade”. Once the trade is completed, your Account will be updated to confirm
                                    that your order has been closed or executed successfully. In specific cases related to No-Fills (entire order is open) and Partial-Fills (trade/s executed for part
                                    of an order), the order will remain open in your “Order Book” for a period of 30 days from the order date or until cancelled by you; whichever is earlier. You
                                    consent to authorize {companyName.toLowerCase()} to transfer the funds or cryptocurrency and digital assets to settle your trade/s with the counterparty.
                                </p>
                                <b>(B) CANCELLATION PROCESS</b>
                                <p>
                                    You can also cancel an order placed via our Services if your order has not been matched with other order/s. However, you can’t modify, cancel, withdraw an executed
                                    trade or restrict {companyName.toLowerCase()} from settling your trade with the counterparty. In case of partial matching of your order, you are allowed to cancel
                                    the remaining unmatched/pending part of your order. {companyName} also holds complete control or rights over cancellations of your order or any cancellation request
                                    received from your side. In case of not having sufficient funds (“Digital Currency”) in your Account or Wallet, {companyName.toLowerCase()} also reserves the right
                                    to cancel your entire order or may complete only partial order depending upon the Digital currency available in your account.
                                </p>
                                <b>(C) TRADE CORRECTIONS</b>
                                <p>
                                    You acknowledge and agree that {companyName.toLowerCase()} may correct, reverse or cancel, on its sole discretion, any order, trade, transfer, or other transaction
                                    or activity with respect to which {companyName.toLowerCase()} has discovered that there was an error or abnormality, whether such error or abnormality was caused by
                                    you, {companyName.toLowerCase()} or a third party or due to technical issues, system issues, software failure or otherwise. {companyName} provides no guarantee or
                                    warranty that any such attempt will be successful and will have no responsibility or liability for such error(s) or any correction attempt(s)
                                </p>
                                <b>(D) FEES & CHARGES</b>
                                <p>
                                    You should agree to pay the {companyName.toLowerCase()} fees given on{" "}
                                    <Link to="/fees" target="_blank">
                                        “Fees”
                                    </Link>
                                    . However, cryptocurrency and digital assets withdrawal fees are regularly adjusted according to blockchain conditions. {companyName} reserves its right to revise
                                    the trading fees and the updated fees shall be applied to all the trades executed on or after the effective date.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h1>8. MARGIN TRADING & FUTURES</h1>
                                <b>(A) MARGIN & FUTURES TRADING RISKS</b>
                                <span className="pt-0">
                                    Both Margin Trading and Futures Trading carry high risk and as a Margin and/or Futures trader, you understand and accept that you solely bear all the risks
                                    associated with margin and futures trading and borrowing services. These risks may include but are not limited to:
                                </span>
                                <p>
                                    Cryptocurrency and Digital Assets markets are highly volatile and shift rapidly in terms of liquidity, the use of leverage from your side might work in favor of you
                                    or against you and can also lead to large losses or gains far in excess of your original capital;
                                </p>
                                <p>
                                    You are not entitled to get any forked coins split from any of the blockchain assets in your Margin or Futures account, despite your being not involved in any
                                    Margin or Future trading.
                                </p>
                                <p>
                                    In certain cases, you might not be in a position to liquidate. This might be due to either insufficient amount of liquidity in the market, technical glitches or
                                    other factors beyond our control.
                                </p>
                                <p>
                                    Contingent orders placed from your side may not help to limit or avoid the losses faced by you since adverse market conditions may make it difficult or even
                                    impossible to execute such orders.
                                </p>
                                <b>(B) ENTERING MARGIN & FUTURES TRADING SEGMENT</b>
                                <p>
                                    You are prohibited from using our services if you are an individual or organization banned under UN Security Council trade and economic sanctions list.
                                    Additionally, You must not be a resident individual or organization from a country falling under FATF Black List ( High-Risk Jurisdictions subject to a Call for
                                    Action) & Grey List (Jurisdictions Under Increased Monitoring);
                                </p>
                                <p>
                                    The Services are not available to “U.S. Persons”, as such term is defined under Regulation S promulgated under the Securities Act of 1933. By accessing or using the
                                    {companyName.toLowerCase()} platform, you represent and warrant that you are not a U.S. Person;
                                </p>
                                <p>
                                    You understand and accept that you are solely responsible for bearing all the risks, rewards and damages associated with margin and futures trading and borrowing
                                    services;
                                </p>
                                <p>
                                    You shall always maintain sufficient amount of funds and/or cryptocurrency and digital assets into your margin trading and futures account. Failure to maintain
                                    sufficient amount of funds and/or assets may lead to forced liquidation of assets from your margin or futures trading account.
                                </p>
                                <p>
                                    {companyName} does not provide any guarantee or assurance to minimize or avoid financial losses or any other damage which may arise as a result of using the
                                    Services. Even though you have the option to force liquidate the positions, you shall be responsible for covering the asset shortfall when all your position are
                                    liquidated but your account does not have sufficient funds to cover the Margin Loan Amount.
                                </p>
                                <p>
                                    {companyName}, at its sole discretion, also holds the power to transfer funds or cryptocurrency and digital asset balances from your margin or futures trading
                                    account to your spot exchange account and/or from your spot exchange account to your margin or futures trading account to minimize losses or cover the shortfall.
                                </p>
                                <p>
                                    You acknowledge and agree that {companyName.toLowerCase()} will have no obligation to provide you with any support or maintenance in connection with the Services.{" "}
                                    {companyName} is neither liable nor responsible for any loss or damage arising out of usage of the Services including during the times of planned and un-planned
                                    maintenance periods.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h1>9. LIABILITY</h1>
                                <b>(A) WARRANTIES DISCLAIMER</b>
                                <p>
                                    TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE SERVICES ARE PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND {companyName.toUpperCase()} EXPRESSLY
                                    DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
                                    FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE SERVICES WILL MEET YOUR
                                    REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE,
                                    COMPLETE, LEGAL, OR SAFE. {companyName.toUpperCase()} DOES NOT GUARANTEE THAT ANY ORDER TRASMITTED BY A USER WILL BE ACCEPTED, RECORDED, REMAIN OPEN OR EXECUTED. IF
                                    APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE {companyName.toUpperCase()} PLATFORM, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS
                                    FROM THE DATE OF FIRST USE. {companyName.toUpperCase()} DISCLAIMS ANY AND ALL RESPONSIBILITY OR LIABILITY IN RELATION TO THE CONTENT MADE AVAILABLE THROUGH THE
                                    SERVICES, INCLUDING THE CUSTOMER CONTENT, OR ANY CONTENT OR SERVICES PROVIDED BY THIRD PARTIES. {companyName.toUpperCase()} DOES NOT CONTROL OR VET CUSTOMER CONTENT
                                    AND IS NOT RESPONSIBLE FOR WHAT USERS POST, TRANSMIT, OR SHARE ON OR THROUGH THE SERVICES. {companyName.toUpperCase()} IS NOT RESPONSIBLE OR LIABLE IN ANY MANNER
                                    FOR ANY THIRD-PARTY SERVICES ASSOCIATED WITH OR UTILIZED IN CONNECTION WITH THE SERVICES, INCLUDING THE FAILURE OF ANY SUCH THIRD-PARTY SERVICES OR SUPPORTED
                                    PLATFORMS. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME JURISDICTIONS DO NOT ALLOW
                                    LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                                </p>
                                <span className="pt-0">
                                    EXCEPT FOR THE EXPRESS STATEMENTS SET FORTH IN THIS AGREEMENT, YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU HAVE NOT RELIED UPON ANY OTHER STATEMENT OR UNDERSTANDING,
                                    WHETHER WRITTEN OR ORAL, WITH RESPECT TO YOUR USE AND ACCESS TO THE SERVICES. YOU HEREBY CONSENT THAT {companyName.toUpperCase()} WILL NOT BE LIABLE FOR ANY LOSSES
                                    OR DAMAGES ARISING OUT OF:
                                </span>
                                <p>ANY INACCURACY, DEFECT OR OMISSION OF CRYPTOCURRENCY AND DIGITAL ASSETS MARKET DATA;</p>
                                <p>ANY ERROR OR DELAY IN THE TRANSMISSION OF SUCH MARKET DATA;</p>
                                <p>INTERRUPTION IN SERVICES AND DISSEMINATION OF MARKET DATA;</p>
                                <p>ANY LOSSES OR DAMAGES INCURRED AS A RESULT OF TECHNICAL GLITCHES, HACKING, ACTIONS OF OTHER USERS AND OMISSIONS OR VIOLATION OF THIS AGREEMENT.</p>
                                <b>(B) INDEMNIFICATION</b>
                                <p>
                                    YOU AGREE TO INDEMNIFY AND HOLD {companyName.toUpperCase()} (AND ITS OFFICERS, EMPLOYEES, AND AGENTS) HARMLESS, INCLUDING FROM COSTS AND ATTORNEYS’ FEES, FROM ANY
                                    CLAIM OR DEMAND MADE BY ANY THIRD PARTY DUE TO OR ARISING OUT OF (A) YOUR USE OF THE SERVICES, (B) YOUR VIOLATION OF THIS AGREEMENT, (C) YOUR VIOLATION OF
                                    APPLICABLE LAWS OR REGULATIONS, OR (D) THIRD-PARTY SERVICES. {companyName.toUpperCase()} RESERVES THE RIGHT, AT YOUR EXPENSE, TO ASSUME THE EXCLUSIVE DEFENSE AND
                                    CONTROL OF ANY MATTER FOR WHICH YOU ARE REQUIRED TO INDEMNIFY US, AND YOU AGREE TO COOPERATE WITH OUR DEFENSE OF THESE CLAIMS. YOU AGREE NOT TO SETTLE ANY MATTER
                                    WITHOUT THE PRIOR WRITTEN CONSENT OF {companyName.toUpperCase()}. {companyName.toUpperCase()} WILL USE REASONABLE EFFORTS TO NOTIFY YOU OF ANY SUCH CLAIM, ACTION OR
                                    PROCEEDING UPON BECOMING AWARE OF IT.
                                </p>
                                <b>(C) LIMITATION OF LIABILITY</b>
                                <p>
                                    IN NO EVENT WILL {companyName.toUpperCase()}, ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS,
                                    REPRESENTATIVES, SUPPLIERS OR CONTRACTORS BE LIABLE FOR ANY INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL OR SIMILAR DAMAGES OR LIABILITIES WHATSOEVER
                                    (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF DATA, INFORMATION, REVENUE, PROFITS OR OTHER BUSINESS OR FINANCIAL BENEFIT) ARISING OUT OF OR IN CONNECTION WITH
                                    THE SERVICES, ANY PERFORMANCE OR NON- PERFORMANCE OF THE SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF{" "}
                                    {companyName.toUpperCase()} AND ITS AFFILIATES, WHETHER UNDER CONTRACT, STATUTE, STRICT LIABILITY OR OTHER THEORY EVEN IF {companyName.toUpperCase()} HAS BEEN
                                    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES EXCEPT TO THE EXTENT OF A FINAL JUDICIAL DETERMINATION THAT SUCH DAMAGES WERE A RESULT OF {companyName.toUpperCase()}’S
                                    GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT OR INTENTIONAL VIOLATION OF LAW. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL
                                    ORCONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU. NOTWITHSTANDING THE FOREGOING, IN NO EVENT WILL THE LIABILITY OF {companyName.toUpperCase()},
                                    ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS, EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS ARISING OUT
                                    OF OR IN CONNECTION THE SERVICES, ANY PERFORMANCE OR NON-PERFORMANCE OF THE SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF{" "}
                                    {companyName.toUpperCase()} OR ITS AFFILIATES WHETHER UNDER CONTRACT, STATUTE, STRICT LIABILITY OR OTHER THEORY, EXCEED THE AMOUNT OF THE FEES PAID BY YOU TO{" "}
                                    {companyName.toUpperCase()} UNDER THIS AGREEMENT IN THE TWELVE-MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM FOR LIABILITY.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h1>10. NEWS & ANNOUNCEMENTS</h1>
                                <p>
                                    All major {companyName.toLowerCase()} official announcements, news related to its products and airdrops shall be made available on our website’s official
                                    announcement page{" "}
                                    <Link to={`${supportUrl}/news`} target="_blank">
                                        “News”
                                    </Link>
                                    .
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h1>11. AGREEMENT TERMINATION</h1>
                                <p>
                                    We may terminate your Account or suspend your access to the Services at any time and with immediate effect for any reason or no reason, in our sole and absolute
                                    discretion. We may decline to process any deposit or withdrawal without prior notice and may limit or suspend your use of one or more Services at any time, in our
                                    sole discretion. For example, we may, in our sole discretion, delay deposits or withdrawals if we believe the transaction is suspicious, may involve fraud or
                                    misconduct, violates applicable laws, or violates the terms of this Agreement. If you have a balance remaining in an Account which has been suspended, we may freeze
                                    such balance for so long as the Account is suspended. If the Account is terminated due to fraud, violation of law, or violation of this Agreement,{" "}
                                    {companyName.toLowerCase()} may, in its discretion, confiscate any balance remaining in the Account and deliver it to any applicable government, law enforcement, or
                                    other authorities where circumstances warrant.
                                </p>
                                <span>
                                    {companyName} also reserves the right to freeze, suspend or terminate your account or related accounts owned by your affiliates or partners as well as lock the
                                    funds in all these accounts if it detects any fraudulent, illegal or suspicious activity from your account or violation of any of these Terms of Use,{" "}
                                    {companyName.toLowerCase()} Privacy Policy, or violation of any of the applicable laws and regulations. {companyName} has complete right to keep, store or retrieve
                                    the transaction data or other related information of all such accounts. The termination scenario shall be applied to the below cases where:
                                </span>
                                <p>The account is linked to any legal or regulatory proceeding, criminal investigation or any other litigation;</p>
                                <p>
                                    Transaction is suspicious, may involve fraud or misconduct, violates applicable laws, or violates the terms of this Agreement or {companyName.toLowerCase()} Privacy
                                    Policy;
                                </p>
                                <p>Unauthorized access to your account has been detected or reported;</p>
                                <p>The information provided during the account registration on {companyName.toLowerCase()} is false, inaccurate, incomplete or outdated;</p>
                                <p>
                                    You willingly registered on the {companyName.toLowerCase()} platform using any other person’s name and e-mail ID after creating your original account, directly or
                                    indirectly;
                                </p>
                                <p>
                                    At anytime and with immediate effect, for any or no reason, whenever {companyName.toLowerCase()} considers it fit to terminate your User Account and access to the
                                    Services at its sole discretion with no liability or responsibility for the losses or damages suffered by you as a consequence.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h1>12. NO LIABILITY FOR YOUR PERSONAL TRADING DECISIONS</h1>
                                <p>{companyName} is neither liable nor responsible for providing any advice related to trading in cryptocurrency and digital assets markets.</p>
                                <p>
                                    YOU HEREBY ACKNOWLEDGE AND AGREE THAT {companyName.toUpperCase()} IS NOT A FINANCIAL INSTITUTION, BANK, CREDIT UNION, TRUST, HEDGE FUND, BROKER OR INVESTMENT OR
                                    FINANCIAL ADVISOR, AND IS NOT SUBJECT TO THE SAME LAWS, REGULATIONS, DIRECTIVES OR REQUIREMENTS APPLICABLE TO SUCH PERSONS OR ENTITIES. YOU ACKNOWLEDGE AND AGREE
                                    THAT NO ORAL OR WRITTEN INFORMATION OR ADVICE PROVIDED BY {companyName.toUpperCase()}, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, NOR ANY INFORMATION OBTAINED
                                    THROUGH THE SERVICES, WILL OR SHALL CONSTITUTE INVESTMENT, OR FINANCIAL ADVICE. YOU ARE SOLELY AND EXCLUSIVELY RESPONSIBLE FOR ALL TRADING DECISIONS MADE BY YOU
                                    WHILE USING THE SERVICES.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h1>13. STRICT ADHERENCE TO COUNTRY SPECIFIC REGULATIONS</h1>
                                <p>
                                    {companyName.toUpperCase()} IS MERELY A TECHNOLOGY PLATFORM WHERE CRYPTOCURRENCY AND DIGITAL ASSETS ARE TRADED AND YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR THE
                                    LEGAL, REGULATORY AND TAX COMPLIANCE OF ALL TRANSACTIONS CARRIED OUT BY YOU USING THE SERVICES.
                                </p>
                                <p>
                                    {companyName} reserves it right to demand the “Proof of Source of Funds” from any user, to ensure that the funds and cryptocurrency and digital assets used for
                                    trading on our platform originated from legitimate sources. We always endeavor to be compliant with prevailing KYC, CFT & AML guidelines issued by FATF.{" "}
                                    {companyName} may, in its discretion, confiscate any balance remaining in the Account and deliver it to any applicable government, law enforcement, or other
                                    authorities where circumstances warrant.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h1>14. PRIVACY POLICY</h1>
                                <p>
                                    At the time of registering with {companyName.toLowerCase()} and while carrying out the KYC verification on our platform, you are required to furnish certain
                                    personally identifiable information like Name, DOB, Address of Residence etc. and need to provide supporting documents to successfully complete the process. Please
                                    visit and review our privacy policy at{" "}
                                    <Link to="/privacy-policy" target="_blank">
                                        “Privacy Policy”
                                    </Link>{" "}
                                    to know how we collect, analyze, process and use your personal information.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h1>15. DISPUTES RESOLUTION : MANDATORY ARBITRATION & CLASS ACTION WAIVER</h1>
                                <span className="pt-0">
                                    PLEASE CAREFULLY READ AND UNDERSTAND THE DISPUTE RESOLUTION MECHANISM. IT IS PART OF YOUR CONTRACT WITH {companyName.toUpperCase()} AND CONTAINS INFORMATION ABOUT
                                    PROCEDURE FOR MANDATORY ARBITRATION AND WAIVER OF YOUR RIGHTS TO BRING CLASS ACTION.
                                </span>
                                <b>(A) MANDATORY ARBITRATION:</b>
                                <b>DISPUTE NOTIFICATION</b>
                                <p>
                                    In the case of disputes, controversies or claims involving {companyName.toLowerCase()}: You and {companyName.toLowerCase()} agree to resolve any claims relating to
                                    this Agreement (including any question regarding their existence, validity, termination, or any services or products provided and any representations made by us)
                                    through final and binding arbitration, except as set forth under Exceptions to Arbitrate Agreement below. You agree to first give us an opportunity to resolve any
                                    claims by contacting us on our website / mobile / desktop applications.
                                </p>
                                <p>
                                    You can contact us and raise a ticket on{" "}
                                    <Link to={`${supportUrl}/sendtickets`} target="_blank">
                                        “Send Tickets.”
                                    </Link>
                                </p>
                                <b>ARBITRATE AGREEMENT</b>
                                <p>
                                    In the case of disputes, controversies or claims involving {companyName.toLowerCase()}: You and {companyName.toLowerCase()} agree to resolve any claims relating to
                                    this Agreement (including any question regarding their existence, validity, termination, or any services or products provided and any representations made by us)
                                    through final and binding arbitration, except as set forth under Exceptions to Arbitrate Agreement below. You agree to first give us an opportunity to resolve any
                                    claims by contacting us on our website / mobile / desktop applications or by contacting us via the link address given above in section (a).
                                </p>
                                <p>
                                    If we are not able to resolve your claims within 90 days of receiving the notice, you may seek relief through arbitration or in the Small Claims Tribunals of
                                    Singapore (“SCT”), as set forth below.
                                </p>
                                <b>PROCEDURE OF ARBITRATION</b>
                                <p>
                                    Either you or {companyName.toLowerCase()} may submit a dispute (after having made good faith efforts to resolve such dispute) for final and binding resolution by
                                    arbitration under the arbitration rules of the Singapore International Arbitration Centre (“SIAC”), which are deemed to be incorporated into these Terms by
                                    reference. The arbitration tribunal shall consist of a sole arbitrator to be appointed by the President of SIAC. The language of the arbitration hearings shall be
                                    English and the seat, or legal place, of arbitration shall be Singapore. JUDGMENT ON ANY ARBITRAL AWARD MAY BE GIVEN IN ANY COURT HAVING JURISDICTION OVER THE PARTY
                                    (OR OVER THE ASSETS OF THE PARTY) AGAINST WHOM SUCH AN AWARD IS RENDERED.
                                </p>
                                <b>EXCEPTIONS TO ARBITRATE AGREEMENT</b>
                                <p>
                                    Either party may instead choose to assert the claims in the SCT if the claims fall within the jurisdiction of the SCT, and either party may seek injunctive relief
                                    or other urgent equitable relief in a court of competent jurisdiction. However, for the avoidance of doubt, where the claims fall outside of the jurisdiction of the
                                    SCT, the claims will be referred to and finally resolved by SIAC arbitration
                                </p>

                                <b>(B) WAIVER OF JURY TRIAL & CLASS ACTION</b>
                                <b>WAIVER OF JURY TRIAL</b>
                                <p>
                                    THE PARTIES HEREBY WAIVE THEIR RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, as applicable, instead electing that all claims and disputes
                                    shall be resolved by arbitration under this Arbitration Agreement.
                                </p>
                                <p>
                                    The Arbitration procedures are typically more limited, more efficient and less costly than rules applicable in a court and are subject to very limited review by a
                                    court. In the event any litigation should arise between you and {companyName.toLowerCase()} in any state or federal court in a suit to vacate or enforce an
                                    arbitration award or otherwise, YOU AND {companyName.toUpperCase()} WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing that the dispute be resolved by a judge.
                                </p>
                                <b>WAIVER OF CLASS OR CONSOLIDATED ACTIONS</b>
                                <p>
                                    ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS
                                    OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.
                                </p>

                                <b>(C) CONFIDENTIALITY</b>
                                <p>
                                    All aspects of the arbitration proceeding, including but not limited to the award of the arbitrator and compliance therewith, shall be strictly confidential. The
                                    parties agree to maintain confidentiality unless otherwise required by law. However, this clause shall not prevent a party from submitting to a court of law any
                                    information necessary to enforce this Agreement, to enforce an arbitration award, or to seek injunctive or equitable relief.
                                </p>

                                <b>(D) OTHER IMPORTANT CLAUSES OF ARBITRATION AGREEMENT</b>
                                <p>
                                    If any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable by a court of competent jurisdiction, then such specific
                                    part or parts shall be of no force and effect and shall be severed and the remainder of the Agreement shall continue in full force and effect.
                                </p>
                                <p>
                                    Any or all of the rights and limitations set forth in this Arbitration Agreement may be waived by the party against whom the claim is asserted. Such waiver shall
                                    not waive or affect any other portion of this Arbitration Agreement.
                                </p>
                                <p>
                                    This Arbitration Agreement will survive the termination of your relationship with {companyName.toLowerCase()}. Notwithstanding the foregoing, either party may seek
                                    emergency equitable relief before a court of competent jurisdiction in order to maintain the status quo pending arbitration. A request for interim measures shall
                                    not be deemed a waiver of any other rights or obligations under this Arbitration Agreement.
                                </p>
                                <p>
                                    Notwithstanding the foregoing, claims of defamation and infringement or misappropriation of the other party’s patent, copyright, trademark or trade secrets shall
                                    not be subject to this Arbitration Agreement.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h1>16. FORCE MAJEURE</h1>
                                <p>
                                    {baseName} is not responsible for damages caused by delay or failure to perform undertakings under this Agreement when the delay or failure is due to strikes,
                                    fires, floods, power outages or failures, acts of God or the state’s enemies, lawful acts of public authorities, any and all market movements, shifts, or
                                    volatility, computer, server, or Internet malfunctions, security breaches or cyberattacks, criminal acts, delays or defaults caused by common carriers, acts or
                                    omissions of third parties, or any other delays, defaults, failures or interruptions that cannot reasonably be foreseen or provided against. In the event of force
                                    majeure, {baseName.toLowerCase()} is excused from any and all performance obligations and this Agreement shall be fully and conclusively at an end.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h1>17. MISCELLANEOUS</h1>
                                <p>
                                    With respect to this Agreement, your relationship to {companyName.toLowerCase()} is that of an independent contractor, and neither party is an agent or partner of
                                    the other. This Agreement, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without{" "}
                                    {companyName.toLowerCase()}’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null
                                    and void. {companyName.toLowerCase()} may freely assign this Agreement. The terms and conditions set forth in this Agreement shall be binding upon assignees.
                                </p>
                                <p>
                                    The Terms of Use given here together with {companyName.toLowerCase()} “Privacy Policy” comprises an entire, valid agreement between both parties in relation to the
                                    use of the Services. This Agreement supersedes all previous ones entered between the concerned parties. No other document signed between the parties can modify,
                                    supplement, or alter the terms given in these Terms of Use.
                                </p>
                                <p>
                                    Presence of any links related to the Services provided by {companyName.toLowerCase()} on third-party websites does not imply endorsement by{" "}
                                    {companyName.toLowerCase()} of any third-party products, services or information presented thereon, nor does {companyName.toLowerCase()} guarantee about the
                                    accuracy or genuineness of the information contained thereon. Despite our best efforts, {companyName.toLowerCase()} can’t effectively control the third-party
                                    websites, we therefore advise that you should carefully read and understand each website’s “Terms of Use” and “Privacy Policy”, separately.
                                </p>
                                <p>
                                    The communications between you and {companyName.toLowerCase()} use electronic means, whether you use our platform or send us emails, or whether{" "}
                                    {companyName.toLowerCase()} posts notices on the its website or communicates with you via email. For contractual purposes, you (a) consent to receive communications
                                    from {companyName.toLowerCase()} in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that{" "}
                                    {companyName.toLowerCase()} provides to you electronically satisfy any legal requirement that such communications would satisfy if it were be in a hardcopy writing.
                                    The foregoing does not affect your non-waivable rights.
                                </p>
                                <p>
                                    You can reach out to us for clarifications and queries related to these “Terms of Use”, {companyName.toLowerCase()} Privacy Policy or any matter concerning our
                                    Services by connecting with our {companyName.toLowerCase()} Customer Support team and raising a ticket on{" "}
                                    <Link to={`${supportUrl}/sendtickets`} target="_blank">
                                        “Send Tickets”
                                    </Link>
                                    . We shall be happy to resolve your queries related to the Services.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
