import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/cookiesPolicy.module.css';
import { baseName } from '../const';
import Layout from '../components/Layout/Layout';

export default function CookiesPolicy() {
    return (
        <Layout>

            <section className={styles.section}>
                <div className='container'>
                    <div className={styles.section_body}>
                        <div className='title_heading d-flex'>
                            <p>Explore /</p>
                            <h4>Cookies Policy</h4>
                        </div>
                        <div className={styles.section_content}>
                            <p>At {baseName.toLowerCase()}, we use cookies to enhance site navigation, analyze site usage & personalize content to provide social media features and to improve our marketing efforts. We also share information about your use of our site with our social media, advertising and analytics partners.</p>
                            <p>Cookies are files, often including unique identifiers, that are sent by web servers to web browsers, and which may then be sent back to the server each time the browser requests a page from the server.</p>
                            <div className={styles.pere_content_box}>
                                <h2>WHY WE USE “COOKIES” :</h2>
                                <ul>
                                    <li>To ensure fast access to our website through cache ;</li>
                                    <li>To remember your preferences by using the functional cookies ;</li>
                                    <li>To help us identify and resolve the technical errors faced by you while accessing our platform ;</li>
                                    <li>To record your site interactions in order to provide more personalized user experience including displaying relevant advertisements.</li>
                                </ul>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h2>WE VALUE YOUR PRIVACY :</h2>
                                <ul>
                                    <li>You can choose to reject or accept our ‘Cookies’ terms, based on which some website features or content may not be available to you.</li>
                                    <li>The user information we receive via cookies may be shared with our affiliates or other third parties for specific purposes strictly in accordance with our <Link to="/privacy-policy">“Privacy Policy”</Link> .</li>
                                    <li>To disable cookies in your browser, go to “Settings &gt; Cookies &gt; Block Cookies” or similar option. Additionally, you can opt to delete all the stored cookies from your browser.</li>
                                </ul>
                            </div>
                        </div> 
                    </div>
                </div>
            </section>

        </Layout>
    );
}
