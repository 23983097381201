import React from 'react';
import styles from '../styles/LowEnforcementPolicy.module.css';
import { companyName } from '../const';
import Layout from '../components/Layout/Layout';

export default function LowEnforcementPolicy() {
    return (
        <Layout>
            <section>
                <div className='container'>
                    <div className='title_heading d-flex'>
                        <p>Explore /</p>
                        <h4>Low Enforcement Policy</h4>
                    </div>
                    <div className={styles.lowenforcement_body}>
                        <h5>We, At {companyName},</h5>
                        <h6 className='mb-4'>Might receive certain information requests about some of our customers  from international law enforcement agencies.</h6>
                        <p className='mb-2'>Primarily, the customer information requested by international agencies from us can be divided into two Categories  :</p>
                        <span>Trading Activity Information</span>
                        <span className='mb-4'>Customer Personal Information</span>
                        <p>When information requests are placed by designated authorities, {companyName} backs it up with a proper legal process such as subpoenas, search warrants, and production orders. Every information access request is properly analyzed for voluntary disclosure. After thorough analysis, only required information is handed over to the designated authorities from these international agencies.</p>
                        <p>We reserve the right to request the international law enforcement agencies to protect sensitive data of our customers and our organization, at all times. Wherever permitted by law, {companyName} shall be happy to address individual queries and requests related to sharing of the personal data with law enforcement agencies.</p>
                    </div>
                </div>
            </section>
        </Layout>
    );
}