import React, { useEffect, useState } from "react";
import styles from "../../styles/assetWallet.module.css";
import Table from "react-bootstrap/esm/Table";
import Modal from "react-bootstrap/Modal";
import { GetTokenApi } from "../../api/api-service";
import { API_Path } from "../../const";
import { toast } from "react-toastify";
import DataTableComponents from "../data_Table/DataTableComponents";
import moment from "moment";
import NoDataFoundImg from "../nodatafound/NoDataFoundImg";
import { exportDropdown, maxDateString } from "../common/CommonFun";
import arrow from "../../assets/images/arrow.svg";

export default function AssetFundsHistory() {
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [activeTab, setActiveTab] = useState("crypto");
    const [date, setDate] = useState({ fromDate: "", toDate: "" });
    const [CoinType, setCoinType] = useState({});
    const [allData, setAllData] = useState({
        allCoinData: [],
        transactionDataType: [],
        allStatus: [],
    });
    const [loading, setLoading] = useState(false);
    const [cryptoHistoryData, setCryptoHistoryData] = useState([]);
    const [totleRecode, setTotleRecode] = useState(25);
    const [withdrawDetail, setWithdrawDetail] = useState({});
    const [cryptoDetail, setCryptoDetail] = useState({});
    const [selectedCoin, setSelectedCoin] = useState("Select Coin");
    const [selectedStatus, setSelectedStatus] = useState("Select Status");
    const [selectedStatusValue, setSelectedStatusValue] = useState("0");
    const [selectedTransactionType, setSelectedTransactionType] = useState("Deposit");

    useEffect(() => {
        GetStatus();
    }, [activeTab]);

    useEffect(() => {
        if (selectedStatus != "Select Status" && selectedStatusValue != 0 && selectedCoin != "Select Coin" && date.fromDate && date.toDate) getCryptoHistoryData(1, 25);
        else getCryptoHistoryData(1, 25);
    }, [selectedTransactionType]);

    useEffect(() => {
        if (selectedStatus === "Select Status" && selectedStatusValue == 0 && selectedCoin === "Select Coin" && date.fromDate === "" && date.toDate === "") getCryptoHistoryData(1, 25);
    }, [selectedStatus === "Select Status" && selectedStatusValue == 0 && selectedCoin === "Select Coin" && date.fromDate === "" && date.toDate === ""]);

    const statusMap = allData.allStatus.reduce((map, item) => {
        map[item.Status] = item.Name;
        return map;
    }, {});

    const handleTabClick = (tabKey) => setActiveTab(tabKey);
    const handleTransactionType = (type) => setSelectedTransactionType(type);
    const handleClose = () => setShowDetailsModal(false);
    const handleStatusChange = (status) => {
        setSelectedStatus(statusMap[status]); // Update the selectedStatus based on the status
        setSelectedStatusValue(status);
    };
    const handleShow = (data) => {
        setWithdrawDetail(data);
        setShowDetailsModal(true);
        GetCryptoDetail(data.TransactionId);
    };

    useEffect(() => {
        GetCoinFilter();
        GetCoinType();
        GetTransactionType();

        exportDropdown();
    }, []);

    const GetCoinType = async () => {
        try {
            const res = await GetTokenApi(API_Path.getCoinType);
            if (res && res.data) {
                setCoinType(res.data);
            } else {
                toast.error(res.data.Errors);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const GetCoinFilter = async () => {
        try {
            const res = await GetTokenApi(API_Path.getCoinFilter);
            if (res && res.data) {
                setAllData((prevState) => ({
                    ...prevState,
                    allCoinData: res.data,
                }));
            } else {
                toast.error(res.data.Errors);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const GetTransactionType = async () => {
        try {
            const res = await GetTokenApi(API_Path.getTransactionType);
            if (res && res.data) {
                setAllData((prevState) => ({
                    ...prevState,
                    transactionDataType: res.data,
                }));
            } else {
                toast.error(res.data.Errors);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const GetStatus = async () => {
        let data = `coinType=${activeTab}&transactionType=${selectedTransactionType}`;
        try {
            const res = await GetTokenApi(API_Path.getStatus + data);
            if (res && res.data) {
                setAllData((prevState) => ({
                    ...prevState,
                    allStatus: res.data,
                }));
            } else {
                toast.error(res.data.Errors);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const columns = [
        { name: "Transaction Id", selector: (row) => row.TransactionId },
        {
            name: "Created Date",
            selector: (row) => moment(row.EntDate).format("DD/MM/YYYY"),
            sortable: true,
            cell: (row) => <div>{moment(row.EntDate).format("DD/MM/YYYY")}</div>,
        },
        { name: "Wallet", selector: (row) => row.WalletName },
        { name: "Coin", selector: (row) => row.Coin },
        { name: "Amount", selector: (row) => row.Amount },
        {
            name: "Status",
            selector: (row) => row.TransactionStatusName,
            cell: (row) => (
                <div className={row.TransactionStatusName === "Rejected" ? "yallow_color" : row.TransactionStatusName === "Cancelled" ? "red_color" : "green_color"}>{row.TransactionStatusName}</div>
            ),
        },
        {
            name: "View",
            cell: (row) => (
                <button className={styles.view_btn} onClick={() => handleShow(row)}>
                    View
                </button>
            ),
        },
    ];

    const getCryptoHistoryData = (page, rowPerPage, flag) => {
        setLoading(true);
        let FromDate = date.fromDate;
        let Todate = date.toDate;
        var queryString = `q={PageNo:${page},PageSize:${rowPerPage},SortColumn:"EntDate",SortDir:"desc",SearchValue:""}`;
        if (flag) {
            if (selectedStatusValue != 0) queryString += `&transactionStatus=${selectedStatusValue}`;
            if (selectedCoin != "Select Coin") queryString += `&coin=${selectedCoin}`;
            if (FromDate) queryString += `&fromDate=${FromDate}`;
            if (Todate) queryString += `&toDate=${Todate}`;
            // if (searchData && searchData.length > 0) {
            //     queryString = `q={PageNo:${page},PageSize:${rowPerPage},SortColumn:"EntDate",SortDir:"desc",SearchValue:"${searchData}"}`;
            // }
        }
        const CryptoHistoryData = new Promise((resolve) => {
            const apiPath = selectedTransactionType === "Deposit" ? API_Path.getCryptoHistoryDeposit : API_Path.getCryptoHistoryWithdraw;
            resolve(GetTokenApi(apiPath + queryString));
        });
        CryptoHistoryData.then((res) => {
            setLoading(false);
            if (res) {
                setCryptoHistoryData(res.data.List);
                setTotleRecode(res.data.TotalCount);
            } else {
                toast.error(res.statusText);
            }
            setLoading(false);
        });
    };

    const handleSearchData = () => getCryptoHistoryData(1, 25, true);

    const handleResetData = () => {
        setDate({ fromDate: "", toDate: "" });
        setSelectedTransactionType("Deposit");
        setSelectedStatus("Select Status");
        setSelectedStatusValue(0);
        setSelectedCoin("Select Coin");
    };

    const GetCryptoDetail = async (id) => {
        try {
            const res = await GetTokenApi(API_Path.getCryptoDetail + id);
            if (res && res.data) {
                setCryptoDetail();
            } else {
                toast.error(res.data.Errors);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <>
            <section>
                <div className={styles.history_section}>
                    <div className="container">
                        <div className={styles.history_section_head}>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="title_heading">
                                    <h4>Funds History</h4>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12">
                                <div className={styles.history_section_btns}>
                                    <ul>
                                        <li>
                                            <h6>From</h6>
                                            <div className={styles.form_input}>
                                                <input
                                                    type="date"
                                                    className={`${styles.form_input} rounded-0`}
                                                    value={date.fromDate ?? ""}
                                                    max={date.toDate ? date.toDate : maxDateString}
                                                    onChange={(e) => setDate({ ...date, fromDate: e.target.value })}
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <h6>To</h6>
                                            <div className={styles.form_input}>
                                                <input
                                                    type="date"
                                                    className={`${styles.form_input} rounded-0`}
                                                    value={date.toDate ?? ""}
                                                    min={date.fromDate}
                                                    max={maxDateString}
                                                    onChange={(e) => setDate({ ...date, toDate: e.target.value })}
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            <div className="custom-list-dropdown" id="dropdown_transactionDataType">
                                                <span className="selected-display" id="destination">
                                                    {selectedTransactionType}
                                                </span>
                                                <img src={arrow} className="ri-arrow-down-s-line" alt="" />
                                                <ul className="list-item-dropdown">
                                                    {allData?.transactionDataType.map((item, i) => (
                                                        <li key={i} onClick={() => handleTransactionType(item.Name)}>
                                                            {item.Name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="custom-list-dropdown" id="dropdown_allCoinData">
                                                <span className="selected-display" id="destination">
                                                    {selectedCoin}
                                                </span>
                                                <img src={arrow} className="ri-arrow-down-s-line" alt="" />
                                                <ul className="list-item-dropdown">
                                                    {allData?.allCoinData.map((item, i) => (
                                                        <li key={i} onClick={() => setSelectedCoin(item.Coin)}>
                                                            {item.Coin}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <h6 className="mt-2">Status</h6>
                                            <div className="custom-list-dropdown" id="dropdown_allStatus">
                                                <span className="selected-display" id="destination">
                                                    {selectedStatus}
                                                </span>
                                                <img src={arrow} className="ri-arrow-down-s-line" alt="" />
                                                <ul className="list-item-dropdown">
                                                    {allData?.allStatus.map((item, i) => (
                                                        <li key={i} onClick={() => handleStatusChange(item.Status)}>
                                                            {item.Name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={styles.reset_btn}>
                                                <button
                                                    onClick={handleSearchData}
                                                    disabled={
                                                        activeTab === "fiat" ||
                                                        (selectedStatus === "Select Status" &&
                                                            selectedStatusValue == 0 &&
                                                            selectedCoin === "Select Coin" &&
                                                            date.fromDate === "" &&
                                                            date.toDate === "") ||
                                                        (selectedStatus != "Select Status" && selectedStatusValue != 0 && selectedCoin != "Select Coin" && date.fromDate && date.toDate)
                                                    }
                                                >
                                                    Search
                                                </button>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={styles.reset_btn}>
                                                <button onClick={handleResetData} disabled={activeTab === "fiat"}>
                                                    Reset
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.history_section_body}>
                        <div className="container">
                            <div className={styles.history_section_content}>
                                <div className={styles.history_section_text}>
                                    <h4>{CoinType.Crypto === "crypto" ? (selectedTransactionType === "Deposit" ? "Crypto Deposit" : "Crypto Withdraw") : "Fiat"}</h4>
                                </div>
                                <div className={styles.history_section_tab}>
                                    <ul className={`${styles.tab} ${styles.navtabs}`}>
                                        <li className={styles.navitem}>
                                            <button
                                                className={`${styles.navlink} ${activeTab === "crypto" ? styles.active : ""} ms-0 text-capitalize`}
                                                aria-current="page"
                                                onClick={() => handleTabClick("crypto")}
                                            >
                                                {CoinType.Crypto}
                                            </button>
                                        </li>
                                        <li className={styles.navitem}>
                                            <button className={`${styles.navlink} ${activeTab === "fiat" ? styles.active : ""} text-capitalize`} onClick={() => handleTabClick("fiat")}>
                                                {CoinType.Fiat}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tickets_table_body">
                                {activeTab === "crypto" ? (
                                    cryptoHistoryData && cryptoHistoryData.length > 0 ? (
                                        <DataTableComponents fetchData={getCryptoHistoryData} columns={columns} userdata={cryptoHistoryData} TotalRows={totleRecode} loading={loading} />
                                    ) : (
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Transaction Id</th>
                                                    <th>Created</th>
                                                    <th>Wallet</th>
                                                    <th>Coin</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <NoDataFoundImg />
                                        </Table>
                                    )
                                ) : (
                                    <div className={`${styles.detatils_white_box} py-4 text-center m-4`}>
                                        <div className={styles.coming_soon}>
                                            <h5 className="mb-2">COMING SOON!</h5>
                                            <p className="mb-0">We are working on the Fiat process.</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ------------------------ fiat  details modal----------------------- */}

            <Modal show={showDetailsModal} onHide={handleClose} centered className={`${styles.fial_details_modal} history_fial_modal d-block`}>
                <Modal.Header closeButton>
                    <Modal.Title>Withdraw Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.modal_details_body}>
                        <ul>
                            <li>
                                <div className={styles.modal_details_text}>
                                    <p>TransactionId </p>
                                    <bdi>{withdrawDetail.TransactionId}</bdi>
                                </div>
                            </li>
                            <li>
                                <div className={styles.modal_details_text}>
                                    <p>Created </p>
                                    <bdi>{moment(withdrawDetail.EntDate).format("DD/MM/YYYY h:mm:ss A")}</bdi>
                                </div>
                            </li>
                            <li>
                                <div className={styles.modal_details_text}>
                                    <p>Wallet </p>
                                    <bdi>{withdrawDetail.WalletName}</bdi>
                                </div>
                            </li>
                            <li>
                                <div className={styles.modal_details_text}>
                                    <p>Coin </p>
                                    <bdi>{withdrawDetail.Coin}</bdi>
                                </div>
                            </li>
                            <li>
                                <div className={styles.modal_details_text}>
                                    <p>Amount </p>
                                    <bdi>{withdrawDetail.Amount}</bdi>
                                </div>
                            </li>
                            <li>
                                <div className={styles.modal_details_text}>
                                    <p>Status </p>
                                    <bdi
                                        className={
                                            withdrawDetail.TransactionStatusName === "Rejected" ? "yallow_color" : withdrawDetail.TransactionStatusName === "Cancelled" ? "red_color" : "green_color"
                                        }
                                    >
                                        {withdrawDetail.TransactionStatusName}
                                    </bdi>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
