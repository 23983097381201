import React from "react";
import styles from "../styles/security.module.css";
import securityImg from "../assets/images/security.svg";
import securityImg1 from "../assets/images/security_img1.svg";
import securityImg2 from "../assets/images/security_img2.svg";
import { companyName } from "../const";
import Layout from "../components/Layout/Layout";

export default function Security() {
    return (
        <Layout>
            <div className="container">
                <div className={styles.security_banner_section}>
                    <div className="row align-items-center">
                        <div className="col-sm-12 col-lg-6 col-md-6 text-center">
                            <div className={styles.security_banner_img} data-aos="fade-up" data-aos-duration="1000">
                                <img src={securityImg} className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 col-md-6" data-aos="fade-right" data-aos-duration="2000">
                            <div className={styles.security_section_left}>
                                <span className="mb-2">World-class security protecting your investments</span>
                                <h4>Securing your investments and funds is number one on our checklist</h4>
                                <p>At {companyName.toLowerCase()}, we follow a solid, comprehensive security approach to safeguard all your investments. </p>
                                <p>Our expert security team has developed a number of effective measures to rule out any chance of information or money theft from our user accounts.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <section className={styles.Security_section}>
                    <div className={`${styles.flex_direction} ${styles.align_items} row`}>
                        <div className="col-sm-12 col-lg-6 col-md-6">
                            <div className={styles.security_section_blog}>
                                <h4>We put security above the rest</h4>
                                <p className="mb-2">Flawless security team for faultless fund protection</p>
                                <p className="mb-2">
                                    Our covenant team is a mix of global security personnel who exercise a “high risk, high reward” security approach for protecting our client assets or personal
                                    information while maintaining the highest degree of system performance and unmatched client experience.
                                </p>
                                <p className="mb-2">
                                    We have years of experience in building robust security measures or programs for the world's most trusted exchanges, identifying the consumer data breaches, and
                                    doing r&d work to develop robust security technologies that measure potential vulnerabilities in our it systems & server architecture.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12  col-md-6 col-lg-6 text-center">
                            <div className={styles.security_section_blog_right}>
                                <img src={securityImg1} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className={styles.Security_section}>
                    <div className={`${styles.align_items} row`}>
                        <div className="col-sm-12 col-lg-6 col-md-6 text-center">
                            <div className={`${styles.security_section_blog_right} ps-0`}>
                                <img src={securityImg2} className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-6 col-md-6">
                            <div className={`${styles.security_section_blog} ${styles.security_section_blog_two}`}>
                                <h4>WE DELIVER…</h4>
                                <h6>Safest Coin Storage</h6>
                                <p>
                                    We store 90% of user deposits in a dedicated, air-gapped, and geographically distributed cold storage. We hold complete right over stored deposits, giving you
                                    on-demand withdrawal option.
                                </p>
                                <h6>Institutional-Grade Platform Security</h6>
                                <p>
                                    All our servers are kept under 24/7 surveillance using video monitors and armed guards. Physical access and code deployment on servers are made null void without
                                    permission. Nothing transpasses without strict review.
                                </p>
                                <h6>Unparalleled Information Security</h6>
                                <p>
                                    All sensitive and private information of user accounts is kept in an encrypted and password protected form at both system and data level. Access is strictly
                                    controlled and monitored.{" "}
                                </p>
                                <h6>Faultless Penetration Testing</h6>
                                <p>
                                    One of our dedicated, expert security team is assigned to rigorously check vulnerabilities and every imaginable data attack on our own systems to ensure world-class
                                    security.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={`${styles.Security_section} pb-0`}>
                    <div className={styles.security_featurea}>
                        <div className={styles.security_title} data-aos="fade-up" data-aos-duration="1000">
                            <h2>SECURITY FEATURES</h2>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-3 col-md-3 col-sm-4" data-aos="fade-up" data-aos-duration="1000">
                                <div className={styles.security_featurea_body}>
                                    <div className={styles.security_fea_head}>
                                        <h2>1</h2>
                                        <h6>Two Factor Authentication</h6>
                                    </div>
                                    <p>
                                        (Google and FIDO U2F) keep <br /> your account secure.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-4" data-aos="fade-up" data-aos-duration="1000">
                                <div className={styles.security_featurea_body}>
                                    <div className={styles.security_fea_head}>
                                        <h2>2</h2>
                                        <h6>Withdrawal Protection</h6>
                                    </div>
                                    <p>
                                        An e-mail confirmation with <br /> self-serve accounts.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-4" data-aos="fade-up" data-aos-duration="1000">
                                <div className={styles.security_featurea_body}>
                                    <div className={styles.security_fea_head}>
                                        <h2>3</h2>
                                        <h6>Account Recovery</h6>
                                    </div>
                                    <p>
                                        Prohibited by Phone/SMS, keep control <br /> of your account in your own hand.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-4" data-aos="fade-up" data-aos-duration="1000">
                                <div className={styles.security_featurea_body}>
                                    <div className={styles.security_fea_head}>
                                        <h2>4</h2>
                                        <h6>Real Time Monitoring</h6>
                                    </div>
                                    <p>
                                        Any fraud or suspicious <br /> activates
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-4" data-aos="fade-up" data-aos-duration="1000">
                                <div className={styles.security_featurea_body}>
                                    <div className={styles.security_fea_head}>
                                        <h2>5</h2>
                                        <h6>SSL Encryption</h6>
                                    </div>
                                    <p>Secure your browsing</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-4" data-aos="fade-up" data-aos-duration="1000">
                                <div className={styles.security_featurea_body}>
                                    <div className={styles.security_fea_head}>
                                        <h2>6</h2>
                                        <h6>Full Encryption</h6>
                                    </div>
                                    <p>Sensitive data</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-4" data-aos="fade-up" data-aos-duration="1000">
                                <div className={styles.security_featurea_body}>
                                    <div className={styles.security_fea_head}>
                                        <h2>7</h2>
                                        <h6>24/7 Customer Support</h6>
                                    </div>
                                    <p>We provide 24/7 customer support</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={`${styles.Security_section} pb-3`} data-aos="fade-up" data-aos-duration="1000">
                    <div className={styles.security_policies}>
                        <div className={`${styles.security_title} mb-3`}>
                            <h2>Our Security Policies</h2>
                        </div>
                        <p className="mb-0">
                            {companyName} and its expert security team are putting their vehement efforts to offer institutional-grade security on our <br /> exchange platform to clients for protecting their funds and sensitive data or information.
                        </p>
                        <p>
                            Continuous refinement in security policies, auditing methods and minimizing the possibility of any unforeseeable data breach <br /> event are some tactics, exercised by our
                            expert team to improve our security standards. Some of the security policies with <br /> respect to the safety methods are refined regularly by our team. Here are some of
                            our safety tools that we apply to safeguard <br /> our client investment and private information:
                        </p>
                    </div>
                </section>

                <section className={styles.Security_section_policies} data-aos="fade-up" data-aos-duration="1000">
                    <div className={styles.security_policies}>
                        <div className={`${styles.security_title} mb-3`}>
                            <h2>Securing Your Account</h2>
                        </div>
                        <p className="mb-0">
                            At {companyName.toLowerCase()}, we prioritize and invest lot of our time and resources in security. While some of the security measures are default, <br />
                            we can level up your security if needed. Hence it is important for our every customer to take full advantage of our security tools or <br /> advice that we offer to them.
                        </p>
                    </div>
                </section>

                <section className="row" data-aos="fade-up" data-aos-duration="1000">
                    <div className="col-sm-12 col-lg-6 col-md-6">
                        <div className={styles.security_advanced_left}>
                            <h5>(A) For Advanced Account Protection</h5>
                            <div className={styles.security_advanced_body}>
                                <ul>
                                    <li>
                                        <h5>➤ Two-Factor Authentication (2-FA)</h5>
                                        <p>You can easily set up two-factor authentication using Google Authenticator for withdrawals and trading via your account.</p>
                                    </li>
                                    <li>
                                        <h5>➤ Withdrawals Protection</h5>
                                        <p>
                                            To ensure protected withdrawals on your account, you can create advanced API keys easily. Our security system regularly observes the withdrawals from the
                                            different IP address.
                                        </p>
                                    </li>
                                    <li>
                                        <h5>➤ Cryptocurrency Storage</h5>
                                        <p>
                                            We use air-gapped and geographically distributed cold storage to store user funds. Only a small percentage of crypto assets are accessible for daily trade
                                            operations. Keeping maximum funds in cold wallets, we do not compromise on user account security.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className=" col-sm-12 col-lg-6 col-md-6">
                        <div className={styles.security_advanced_right}>
                            <h5>(B) Basic Guidelines To Protect Your Account</h5>
                            <div className={styles.security_advanced_body_right}>
                                <ul>
                                    <li>
                                        <p>
                                            You must set up two-factor authentication (2-FA) at login to keep your investment account highly secure. The most essential security feature on our
                                            platform.
                                        </p>
                                    </li>
                                    <li>
                                        <p>You must also secure your e-mail account registered with your {companyName.toLowerCase()} account by setting a strong password and 2-FA.</p>
                                    </li>
                                    <li>
                                        <p>
                                            Avoid any phishing or hijacking event by staying alert and taking necessary actions on receiving e-mail notifications from our side about phishing attacks.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
}
