import React, { useState } from "react";
import styles from "../../styles/Profile.module.css";
import Modal from "react-bootstrap/Modal";
import { API_Path, companyName } from "../../const";
import { PostTokenApi } from "../../api/api-service";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { errorContainer, formAttr } from "../common/CommonFun";
import Loading from "../loader/Loading";

export default function LoginPassword() {
    const [verifyCode, setVerifyCode] = useState();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleshowModal = () => setShowModal(true);
    const handleCloseChangePassModal = () => setShowChangePasswordModal(false);
    const handleshowChangePassModal = () => {
        setShowModal(false);
        setShowChangePasswordModal(true);
    };

    const UserPasswordrequest = () => {
        let editProfile = new Promise((resolve) => {
            resolve(PostTokenApi(API_Path.userPasswordRequest));
        });
        editProfile.then((res) => {
            if (res.data.Isvalid === true) {
                toast.success(res.data.Successes[0]);
            } else {
                toast.error(res.data.Errors[0]);
            }
        });
    };

    const setSubmitData = (formdata, resetForm) => {
        setLoading(true);
        let currentPasswordVerify = new Promise((resolve) => {
            resolve(PostTokenApi(API_Path.userPasswordVerify, { ...formdata }));
        });
        currentPasswordVerify.then((res) => {
            setLoading(false);
            if (res.data.Isvalid === true) {
                toast.success(res.data.Successes[0]);
                setVerifyCode(formdata.VerifyCode);
                handleshowChangePassModal();
                resetForm();
            } else {
                toast.error(res.data.Errors[0]);
            }
        });
    };

    const setSubmitPasswordData = (formdata, resetForm) => {
        setLoading(true);
        let data = {
            ...formdata,
            VerifyCode: verifyCode,
        };
        let currentUserPassword = new Promise((resolve) => {
            resolve(PostTokenApi(API_Path.updateUserPassword, data));
        });
        currentUserPassword.then((res) => {
            setLoading(false);
            if (res.data.Isvalid === true) {
                resetForm();
                localStorage.removeItem("FunexAuthorizationData");
                window.location.href = "/login";
                toast.success(res.data.Successes[0]);
            } else {
                toast.error(res.data.Errors[0]);
            }
        });
    };

    return (
        <React.Fragment>
            {loading && <Loading />}
            <div className={`${styles.personal_info_body}`}>
                <div className={styles.title}>
                    <h6>Login Password</h6>
                </div>
                <p>You can change your {companyName.toLowerCase()} Login Password.</p>
                <div className="form-group">
                    <div className={`${styles.personal_info_btn} text-center d-flex p-0`}>
                        <button type="submit" className={`${styles.button} primary_button`} onClick={handleshowModal}>
                            Change
                        </button>
                    </div>
                </div>
            </div>

            <Modal show={showModal} centered className={styles.verify_mail_modal}>
                <Modal.Header className="pb-0">
                    <Modal.Title>Change password</Modal.Title>
                    <div onClick={handleCloseModal} className={styles.close_modal_icon}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                    </div>
                </Modal.Header>

                <Modal.Body className={styles.resend_verify_mail_body}>
                    <p>For your security, we will send a verification OTP to registered e-mail ID.</p>
                    <Formik
                        initialValues={{ VerifyCode: "" }}
                        validationSchema={Yup.object().shape({
                            VerifyCode: Yup.string().required("Authentication code is required."),
                        })}
                        onSubmit={(values, { resetForm }) => {
                            setSubmitData(values, resetForm);
                        }}
                    >
                        {(runform) => {
                            const { touched, errors } = runform;
                            return (
                                <form onSubmit={runform.handleSubmit}>
                                    <div className={`${styles.inputWidth} mb-3 w-auto`}>
                                        <div className="d-block position-relative">
                                            <label>
                                                Authentication code
                                                <span className={styles.asterisk}>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="VerifyCode"
                                                {...formAttr(runform, "VerifyCode")}
                                                placeholder="Authentication code"
                                                className={`${styles.form_input} ${errors.VerifyCode && touched.VerifyCode ? "input-error" : ""} `}
                                            />
                                            <button type="submit" className={styles.auth_login_btn} onClick={UserPasswordrequest}>
                                                Send Code
                                            </button>
                                        </div>
                                        {errorContainer(runform, "VerifyCode")}
                                    </div>
                                    <div className="form-group">
                                        <div className={`${styles.personal_info_btn} text-center d-flex p-0`}>
                                            <button type="button" className={`${styles.button} ${styles.button_border}`} onClick={() => runform.resetForm()}>
                                                Clear
                                            </button>
                                            <button type="submit" className={`${styles.button} primary_button`}>
                                                Enable
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            );
                        }}
                    </Formik>
                </Modal.Body>
            </Modal>
            <Modal show={showChangePasswordModal} centered className={styles.verify_mail_modal}>
                <Modal.Header className="pb-0">
                    <Modal.Title>Change password</Modal.Title>
                    <div onClick={handleCloseChangePassModal} className={styles.close_modal_icon}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                    </div>
                </Modal.Header>

                <Modal.Body className={styles.resend_verify_mail_body}>
                    <div>
                        <Formik
                            initialValues={{
                                CurrentPassword: "",
                                NewPassword: "",
                                ConfirmNewPassword: "",
                            }}
                            validationSchema={Yup.object().shape({
                                CurrentPassword: Yup.string().required("Current password is required."),
                                NewPassword: Yup.string()
                                    .required("New password is required.")
                                    .matches(
                                        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&_^#])[A-Za-z\d@$!%*?&_^#]{8,20}$/,
                                        "Password must contain at least one number, one uppercase letter, one lowercase letter and one symbol i.e. @, $, !, %, *, &, _, #, ^ etc. Password length should be 8 to 16 characters."
                                    )
                                    .test("passwords-match", "current password and new password do match", function (value) {
                                        return this.parent.CurrentPassword != value;
                                    }),
                                ConfirmNewPassword: Yup.string()
                                    .required("Confirm password is required.")
                                    .test("passwords-match", "Passwords must match", function (value) {
                                        return this.parent.NewPassword === value;
                                    }),
                            })}
                            onSubmit={(values, { resetForm }) => {
                                setSubmitPasswordData(values, resetForm);
                            }}
                        >
                            {(runform) => {
                                const { touched, errors } = runform;
                                return (
                                    <Form onSubmit={runform.handleSubmit}>
                                        <div className={`${styles.personalinfo_width} d-block`}>
                                            <div className={`${styles.personalinfocontent} w-100`}>
                                                <label>current password*</label>
                                                <input
                                                    type="password"
                                                    placeholder="current password"
                                                    name="CurrentPassword"
                                                    className={`${styles.form_input} ${errors.CurrentPassword && touched.CurrentPassword ? "input-error" : ""} `}
                                                    {...formAttr(runform, "CurrentPassword")}
                                                />
                                                {errorContainer(runform, "CurrentPassword")}
                                            </div>
                                            <div className={`${styles.personalinfocontent} w-100`}>
                                                <label>new password*</label>
                                                <input
                                                    type="password"
                                                    placeholder="new password"
                                                    name="NewPassword"
                                                    className={`${styles.form_input} ${errors.NewPassword && touched.NewPassword ? "input-error" : ""} `}
                                                    {...formAttr(runform, "NewPassword")}
                                                />
                                                {errorContainer(runform, "NewPassword")}
                                            </div>
                                            <div className={`${styles.personalinfocontent} w-100`}>
                                                <label>confirm new password*</label>
                                                <input
                                                    type="password"
                                                    placeholder="confirm password"
                                                    name="ConfirmNewPassword"
                                                    className={`${styles.form_input} ${errors.ConfirmNewPassword && touched.ConfirmNewPassword ? "input-error" : ""} `}
                                                    {...formAttr(runform, "ConfirmNewPassword")}
                                                />
                                                {errorContainer(runform, "ConfirmNewPassword")}
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className={`${styles.personal_info_btn} text-center d-flex py-4`}>
                                                <button type="button" className={`${styles.button} ${styles.button_border}`} onClick={() => runform.resetForm()}>
                                                    Clear
                                                </button>
                                                <button type="submit" className={`${styles.button} primary_button`}>
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}
