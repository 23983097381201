import React, { useEffect, useState } from "react";
import styles from "../../styles/register.module.css";
import { API_Path, baseName, FunexAuthorizationData } from "../../const";
import { GetTokenApi, PostTokenApi } from "../../api/api-service";
import Logo from "./Logo";
import SideImg from "../../assets/images/auth_img_two.png";
import Loading from "../../components/loader/Loading";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { GetURLParameterIndexOfLastSlashValue } from "../../components/common/CommonFun";

export default function FreezeAccount() {
    const navigate = useNavigate();
    const [email, setEmail] = useState(null);
    const [errorMsg, setErrorMsg] = useState([]);
    // const EncryptedUrl = "mnW5IaCrLhWtIcx8Tqh93__MsHbZ8wL3bMkYnrVS5Tao%3d";
    let linkUrlValue = "";

    useEffect(() => {
        setEmail(localStorage.getItem("FunexAuthData"));
    }, []);

    useEffect(() => {
        var urlValue = GetURLParameterIndexOfLastSlashValue();
        if (urlValue != null && urlValue != undefined && urlValue != "") {
            linkUrlValue = urlValue;
            getFreezeAccountUser(); // call API
        } else {
            navigate("/login");
        }
    }, []);

    const getFreezeAccountUser = async () => {
        try {
            const res = await GetTokenApi(API_Path.getFreezeAccountUser + linkUrlValue);
            if (res) {
                if (res.data.Isvalid === true) {
                    // setEmail(res.data.EmailIdOrApi);
                } else {
                    setErrorMsg(res.data.Errors[0]);
                    if (Array.isArray(res.data.Errors)) {
                        res.data.Errors.forEach(function (err) {
                            errorMsg.push(err);
                        });
                    }
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const setSubmitData = async (formData) => {
        const data = {
            EmailIdOrApi: email,
            OneTimeCode: formData.verifyCode,
            EncryptedUrl: linkUrlValue,
        };
        try {
            const res = await PostTokenApi(API_Path.userFreezeAccount, data);
            if (res) {
                if (res.data.Isvalid === true) {
                    toast.success("User account has been freeze successfully.");
                } else {
                    toast.error(res.data.Errors);
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleResendFreezeAccount = async () => {
        const data = {
            EncryptedUrl: linkUrlValue,
        };
        try {
            const res = await PostTokenApi(API_Path.resendFreezeAccountUserVerify, data);
            if (res) {
                if (res.data.Isvalid === true) {
                    toast.success("User freeze account verification code send successfully.");
                } else {
                    toast.error(res.data.Errors);
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className={`${styles.col_width_left} ${styles.col_width_flex}`}>
                        <div className={styles.main_box}>
                            <div className={styles.lr_logo}>
                                <Logo />
                            </div>
                            <div className="mb-4">
                                <h3>Freeze account with {baseName}</h3>
                            </div>
                            {errorMsg.length > 0 && (
                                <>
                                    <div className={`${styles.alert} ${styles.alert_danger}`}>
                                        <span>{errorMsg}</span>
                                    </div>
                                    <p className={`${styles.login_bottomtext} text-start`}>
                                        Click here to? <Link to="/">Home</Link>
                                    </p>
                                </>
                            )}
                            {errorMsg.length <= 0 && (
                                <>
                                    <div className={styles.verify_mail_body}>
                                        <p className="mb-4">
                                            We sent a verification code to <b>{email}</b>. Please follow that instruction to complete freeze your account.
                                        </p>
                                    </div>
                                    <div className={`${styles.agreeTermsStyle} my-4`}>
                                        Email Id : <b>{email}</b>
                                    </div>
                                    <Formik
                                        initialValues={{ verifyCode: "" }}
                                        validationSchema={Yup.object().shape({
                                            verifyCode: Yup.string().required("Verify Code is required."),
                                        })}
                                        onSubmit={(values, { resetForm }) => {
                                            setSubmitData(values, resetForm);
                                        }}
                                    >
                                        {(runform) => {
                                            const { touched, errors } = runform;
                                            return (
                                                <Form onSubmit={runform.handleSubmit}>
                                                    <div className={`${styles.inputWidth} mb-3`}>
                                                        <label className={`${styles.login_label_text} d-block`}>Verify Code</label>
                                                        <div className="d-block position-relative">
                                                            <Field
                                                                type="text"
                                                                className={` ${styles.login_comn_input} ${errors.verifyCode && touched.verifyCode && "input-error"}`}
                                                                name="verifyCode"
                                                                placeholder="verify code"
                                                            />
                                                            <span className={styles.login_input_icon}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                                                    <path
                                                                        d="M6.24984 17.4999C8.78114 17.4999 10.8332 15.4479 10.8332 12.9166C10.8332 10.3853 8.78114 8.33325 6.24984 8.33325C3.71853 8.33325 1.6665 10.3853 1.6665 12.9166C1.6665 15.4479 3.71853 17.4999 6.24984 17.4999Z"
                                                                        stroke="#404040"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                    <path d="M17.5 1.66675L15.1667 4.00008M9.5 9.66675L13.6667 5.50008" stroke="#404040" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path
                                                                        d="M12.9165 6.24992L15.4165 8.74992L18.3332 5.83325L15.8332 3.33325"
                                                                        stroke="#404040"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <ErrorMessage name="verifyCode" component="div" className="error-message" />
                                                    </div>
                                                    <div className={styles.loginBtn}>
                                                        <button type="submit" className="button primary_button w-100">
                                                            Confirm
                                                        </button>
                                                    </div>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                    <p className={`${styles.login_bottomtext} text-start`}>
                                        Not freeze account?{" "}
                                        {FunexAuthorizationData !== null ? <Link to="/useraccount/profile/api-management">Account</Link> : <Link to="/">Home</Link>}
                                    </p>
                                    <div className={styles.par_cont}>
                                        <p>If you have not received the email, do the following:</p>
                                        <ul>
                                            <li>Make sure provided freeze account link is correct</li>
                                            <li>Check Promotions and Spam folders</li>
                                        </ul>
                                    </div>
                                    <div className={`${styles.reset_pass_para} mt-3 mb-4`}>
                                        Note: Make sure that this link will expire in <b>10 minutes</b>.
                                    </div>
                                    <p className={`${styles.login_bottomtext} text-start`}>
                                        If you do not receive mail within 5 minutes,{" "}
                                        <Link to="/login" onClick={() => handleResendFreezeAccount()}>
                                            click here!
                                        </Link>
                                    </p>
                                </>
                            )}

                            <p className={`${styles.login_bottomtext} text-start`}>
                                If none of the above helps, please contact us at <b className="cursor-pointer">support@funexcoin.com</b>
                            </p>
                        </div>
                    </div>
                    <div className={`${styles.col_width_right} p-0 d-lg-block d-none`}>
                        <div className={`${styles.login_side_img} position-relative`}>
                            <img src={SideImg} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
