import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { useLocation, useNavigate } from "react-router";
import style from "../styles/blog.module.css";
import { Link } from "react-router-dom";

export default function BlogDetails() {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState({});
    const checkURL = `/blog/${location?.state?.item?.NAME_URL}`;

    useEffect(() => {
        if (checkURL != location.pathname) navigate("/blog", { replace: true });
        else setData(location.state.item);
    }, []);

    return (
        <Layout>
            <section>
                <div className="container">
                    <div className="title_heading">
                        <p>
                            <Link to="/blog" className={style.blog_title}>
                                Blog /
                            </Link>
                        </p>
                        <h4>{data?.NAME}</h4>
                    </div>
                    <div className="blog-inner">
                        <div className={style.inner_page}>
                            <img src={`https://exchange-admin.funexcoin.com/site/images/blogs/${data?.BLOG_FILE}`} alt="" className="mb-3" />
                            <p>
                                <b>{data?.SHORT_DESCRIPTION}</b>
                            </p>
                            <div dangerouslySetInnerHTML={{ __html: data?.DESCRIPTION }}></div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
