import React from "react";
import emptyRecordImg from "../../assets/images/empty_record.svg";
import styles from "../../styles/noDataFound.module.css";

export default function NoDataFoundImg({ data, bordered }) {
    return (
        <React.Fragment>
            <tbody className={`${styles.empty_record_body} ${bordered ? "border-0" : ""}`}>
                <tr className="d-block">
                    <td className="d-block">
                        <div className={styles.empty_record}>
                            <img src={emptyRecordImg} alt="" />
                            <span>{data ?? "No data found"}</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </React.Fragment>
    );
}
