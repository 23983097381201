import React, { useContext, useEffect, useState } from "react";
import styles from "../styles/trading.module.css";
import DataNotFound from "../assets/images/data_not_found.svg";
import Layout from "../components/Layout/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_Path, ApiBaseUrl, socketUrl } from "../const";
import { GetApi, PostTokenApi } from "../api/api-service";
import { toast } from "react-toastify";
import emptyRecordImg from "../assets/images/empty_record.svg";
import io from "socket.io-client";
import { errorContainer, expoStrToNumStr, formAttr, parseFloatAmt } from "../components/common/CommonFun";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../context/AuthContext";

import NotFound from "./NotFound";

const socket = io.connect(socketUrl);

let marketTabs = [];

let exOpen = [];
let exFill = [];
let exTradeHis = [];

var mintotal = 0,
    minsize = 0,
    maxsize = 0,
    minprice = 0,
    maxprice = 0;

var allOrders = [];

var limitPrice = 0;

var Makettotalbuy = "";
var stopPrice = "";
var ExcoinBalance = "";
var Makettotalsell = "";
var ExcurrBalance = "";

var userWallets = [];
var listOfPairsData = [];
var currencyData = [];

export default function TradingAdvance() {
    const navigate = useNavigate();
    const { id } = useParams();
    let initialized = false;

    const [loading, setLoading] = useState(false);

    // const [FirstCoin, BaseCoin, RunningPair, isLogin] = useFetch();
    const RunningPair = localStorage.getItem("RunningPair");
    const BaseCoin = localStorage.getItem("BaseCoin");
    const FirstCoin = localStorage.getItem("FirstCoin");
    const isLogin = localStorage.getItem("FunexAuthorizationData") ?? false;
    const { userCurrentData } = useContext(AuthContext);
    // var favouriteList = [];
    const [coinData, setCoinData] = useState({
        pair: RunningPair,
        firstCoin: FirstCoin,
        baseCoin: BaseCoin,
    });

    const [activeMarketTab, setActiveMarketTab] = useState("BTC");
    const [activeOrderTab, setActiveOrderTab] = useState("open");
    const [activeTab, setActiveTab] = useState("buy");
    const [activeLimitTab, setActiveLimitTab] = useState("limit");
    const [marketShow, setMarketShow] = useState(false);
    const [favouriteList, setFavouriteList] = useState(() => {
        const savedList = localStorage.getItem("favoritePairs");
        return savedList ? JSON.parse(savedList) : [];
    });
    const [orderBookType, setOrderBookType] = useState("multi");
    const [receiveData, setReceiveData] = useState();
    const [currentUserData, setCurrentUserData] = useState({
        userID: 0,
        isTrading: false,
        isNYE: false,
        isfreeze: false,
    });
    const [currHisPrice, setCurrHisPrice] = useState();

    const [isUp, setIsUp] = useState(false);
    const [isDown, setIsDown] = useState(false);
    const [hisColor, setHisColor] = useState("gray_color");
    const [issame, setIssame] = useState(true);

    const [currentSellPrice, setCurrentSellPrice] = useState(0);
    const [currentBuyPrice, setCurrentBuyPrice] = useState(0);
    const [bookSell, setBookSell] = useState([]);
    const [bookBuy, setBookBuy] = useState([]);
    const [showLoaderBookSell, setShowLoaderBookSell] = useState(true);
    const [showLoaderBookBuy, setShowLoaderBookBuy] = useState(true);
    const [marketSearchQuery, setMarketSearchQuery] = useState("");

    const [FunexAuthorizationData, setFunexAuthorizationData] = useState(null);

    const [urlPairName, setUrlPairName] = useState("");
    const [checkUrlPairName, setCheckUrlPairName] = useState(true);

    const [priceDecimal, setPriceDecimal] = useState(0);
    const [volumeDecimal, setVolumeDecimal] = useState(0);

    const [priceBuySell, setPriceBuySell] = useState();
    const [sizeAmount, setSizeAmount] = useState();
    const [totalBuySell, setTotalBuySell] = useState();

    const [receiveMarket, setReceiveMarket] = useState([]);

    const [coinBalance, setCoinBalance] = useState([]);
    const [currBalance, setCurrBalance] = useState([]);
    const [makerFees, setMakerFees] = useState();
    const [takerFees, setTakerFees] = useState();

    const [markettotalbuy, setMakettotalbuy] = useState();
    const [makettotalsell, setMakettotalsell] = useState();

    const [trade, setTrade] = useState([]);
    const [isNotFound, setIsNotFound] = useState(false);

    const [showLoaderOpenOrder, setShowLoaderOpenOrder] = useState(true);
    const [showLoaderOrderHistory, setShowLoaderOrderHistory] = useState(true);

    const [isSocketCalled, setIsSocketCalled] = useState(false);

    const [baseCurrency, quoteCurrency] = id.split("_");

    useEffect(() => {
        setCoinData({ firstCoin: baseCurrency, baseCoin: quoteCurrency, pair: baseCurrency + quoteCurrency });
        setFunexAuthorizationData(localStorage.getItem("FunexAuthorizationData"));
    }, []);

    useEffect(() => {
        if (id !== id.toUpperCase()) navigate(`/trading/advance/${id.toUpperCase().replace(/_/g, "_")}`);
    }, [id, navigate]);

    useEffect(() => {
        if (currencyData.length > 0) {
            const isValidId = currencyData.some((data) => data.PairSymbol === baseCurrency + quoteCurrency);
            if (!isValidId) {
                setIsNotFound(true);
            } else {
                setIsNotFound(false);
                localStorage.setItem("FirstCoin", baseCurrency.toUpperCase());
                localStorage.setItem("BaseCoin", quoteCurrency.toUpperCase());
                localStorage.setItem("RunningPair", `${baseCurrency.toUpperCase()}${quoteCurrency.toUpperCase()}`);
            }
        }
    }, [currencyData]);

    useEffect(() => {
        if (!initialized) {
            if (isLogin) {
                initialized = true;
                getCurrentUserDetail();
            } else getMarketMainCoin();
        }
    }, []);

    useEffect(() => {
        SendSocket();
    }, [baseCurrency, quoteCurrency]);

    useEffect(() => {
        setPairName(baseCurrency + "_" + quoteCurrency);
    }, [baseCurrency, quoteCurrency]);

    const setPairName = (urlPairName) => {
        if (urlPairName && urlPairName !== "") {
            const temp = urlPairName.split("_");
            if (temp && temp.length === 2 && temp[0] !== "" && temp[1] !== "") {
                setUrlPairName(temp[0] + temp[1]);
                return;
            }
        }
        setUrlPairName("_");
        setCoinData({ pair: "", firstCoin: "", baseCoin: "" });
    };

    useEffect(() => {
        if (favouriteList) localStorage.setItem("favoritePairs", JSON.stringify(favouriteList));
    }, [favouriteList]);

    //  ------------------  RECEIVE DATA  ------------------

    const handleMarketShow = () => setMarketShow(!marketShow);
    const handleMarketTabClick = (tabKey) => setActiveMarketTab(tabKey);
    const handleOrderTabClick = (tabKey) => setActiveOrderTab(tabKey);
    const handlelimitTabClick = (tabKey) => setActiveLimitTab(tabKey);
    const handleTabClick = (tabKey) => setActiveTab(tabKey);
    const handleClickCoin = (coin, baseName) => {
        setCoinData({ pair: coin + baseName, firstCoin: coin, baseCoin: baseName });
        localStorage.setItem("FirstCoin", coin);
        localStorage.setItem("BaseCoin", baseName);
        localStorage.setItem("RunningPair", `${coin}${baseName}`);
        navigate(`/trading/advance/${coin + "_" + baseName}`);
    };

    //  HOT LIST COINS FUNCTION
    const setUserAllFavouritePairs = (first, last, flag) => {
        let data = { First: first, Last: last };
        const UserFavouritePairs = new Promise((resolve) => {
            resolve(PostTokenApi(flag === "addFavourite" ? API_Path.AddUserFavouritePairs : API_Path.RemoveFavouritePairs, data));
        });
        UserFavouritePairs.then((res) => {
            if (!res) toast.error("Something went wrong!");
        });
    };

    const handleFavoriteCoin = (id) => {
        const alreadyLiked = favouriteList.some((likedItem) => likedItem.id === id);
        if (!alreadyLiked) {
            const likedItem = receiveMarket.find((item) => item.PairSymbol === id);
            setFavouriteList([...favouriteList, likedItem]);
            if (localStorage.getItem("FunexAuthorizationData")) setUserAllFavouritePairs(likedItem.FirstSymbol, likedItem.LastSymbol, "addFavourite");
        }
    };

    const handleDisFavoriteCoin = (id) => {
        const newLikedCoins = favouriteList.filter((coin) => coin.PairSymbol !== id);
        setFavouriteList(newLikedCoins);
        if (localStorage.getItem("FunexAuthorizationData")) {
            const likedItem = favouriteList.find((item) => item.PairSymbol === id);
            setUserAllFavouritePairs(likedItem.FirstSymbol, likedItem.LastSymbol, "removeFavourite");
        }
    };

    const getCurrentUserDetail = () => {
        setCurrentUserData({
            userID: userCurrentData?.ID,
            isTrading: userCurrentData?.IS_TRADING,
            isNYE: userCurrentData?.USE_NYE_FEES,
            isfreeze: userCurrentData?.ISFREEZE,
        });
        if (userCurrentData?.ISFREEZE) {
            toast.error("Your account is FREEZED.");
            // userLogout();
        }
        // getUserLoginDeviceDetail();
        getMarketMainCoin();
    };

    const getMarketMainCoin = async () => {
        try {
            const response = await GetApi(API_Path.GetMarketMainCoin);

            if (response.data && response.data.length > 0) {
                listOfPairsData = response.data;

                // Clear the marketTabs array before populating it
                marketTabs.length = 0; // This clears the array without creating a new reference

                const pairLastNames = response.data.map((item) => item.LAST).filter((value, index, self) => self.indexOf(value) === index);

                let count = 0;

                pairLastNames.forEach((item) => {
                    const lastCoinImageUrl = response.data.filter((x) => x.LAST.toLowerCase() === item.toLowerCase()).map((x) => x.LastCoinImageUrl)[0];

                    marketTabs.push({
                        Id: count,
                        LastName: item,
                        TabTitle: item,
                        CoinImageUrl: lastCoinImageUrl,
                    });

                    const active = BaseCoin || "BTC";
                    if (item === active) {
                        setActiveMarketTab(item);
                    }
                    count++;
                });
                // Call socket here
                // SendSocket();

                // Manage socket connection
                setTimeout(() => {
                    manegesocket();
                }, 1000);

                // DrawTvChart("dark"); // Uncomment and define this if needed
            } else {
                toast.error("Something went wrong!");
            }
        } catch (error) {
            // console.error("Error fetching market main coin data:", error);
            // toast.error("Failed to fetch data from API.");
        }
    };

    const changeStateCoinPair = (value) => {
        navigate(`/trading/advance/${value.toUpperCase()}`);
    };

    const SendSocket = () => {
        receiveTradeHistroy();
        receiveOrderbook();
        // getReceiveData();
        Receiveallorders();
        ReceiveWalletData();
        if ((checkUrlPairName && urlPairName !== null) || urlPairName !== "") {
            if (urlPairName !== "_") {
                var pairname = urlPairName.split("_").join("");
                var list = listOfPairsData.filter((x) => x.PAIRNAME.toLowerCase() === pairname.toLowerCase());
                if (list.length > 0) {
                    setCoinData({ pair: list[0].PAIRNAME, firstCoin: list[0].FIRST, baseCoin: list[0].LAST });
                    localStorage.setItem("RunningPair", list[0].PAIRNAME.toUpperCase());
                    localStorage.setItem("FirstCoin", list[0].FIRST.toUpperCase());
                    localStorage.setItem("BaseCoin", list[0].LAST.toUpperCase());
                } else {
                    // here set and redirect to base_url
                }
            } else {
                // here set and redirect to base_url
            }
        } else {
            // check current pairname for set active pairname
            setCoinData({ pair: baseCurrency + quoteCurrency, firstCoin: coinData.firstCoin, baseCoin: coinData.baseCoin });
            if (baseCurrency + quoteCurrency == "undefined" || baseCurrency + quoteCurrency == null || coinData.firstCoin == null || coinData.baseCoin == null) {
                const CurrentActive = listOfPairsData.filter((p) => p.v_active === "1");
                setCoinData({ pair: CurrentActive[0].PAIRNAME, firstCoin: CurrentActive[0].FIRST, baseCoin: CurrentActive[0].LAST });
            } else {
                const arrPair = listOfPairsData.filter((p) => p.PAIRNAME.toLowerCase() === baseCurrency + quoteCurrency.toLowerCase());
                if (arrPair.length === 0) {
                    const CurrentActive = listOfPairsData.filter((p) => p.v_active === "1");
                    setCoinData({ pair: CurrentActive[0].PAIRNAME, firstCoin: CurrentActive[0].FIRST, baseCoin: CurrentActive[0].LAST });
                }
            }
            localStorage.setItem("RunningPair", baseCurrency + quoteCurrency.toUpperCase());
            localStorage.setItem("FirstCoin", baseCurrency.toUpperCase());
            localStorage.setItem("BaseCoin", quoteCurrency.toUpperCase());
            setCoinData({ pair: baseCurrency + quoteCurrency, firstCoin: baseCurrency, baseCoin: quoteCurrency });
            changeStateCoinPair(baseCurrency + "_" + quoteCurrency);
        }
        var CurrentActive = listOfPairsData.filter((pair) => pair.PAIRNAME == baseCurrency + quoteCurrency);
        mintotal = CurrentActive[0]?.MIN_ORDER;
        minsize = CurrentActive[0]?.min_lot_size;
        maxsize = CurrentActive[0]?.max_lot_size;
        minprice = CurrentActive[0]?.min_lot_price;
        maxprice = CurrentActive[0]?.max_lot_price;
        setPriceDecimal(CurrentActive[0]?.PRICE_PRECISION);
        setVolumeDecimal(CurrentActive[0]?.VOLUME_PRECISION);
        localStorage.setItem("mintotal", mintotal);
        localStorage.setItem("minsize", minsize);
        localStorage.setItem("maxsize", maxsize);
        localStorage.setItem("minprice", minprice);
        localStorage.setItem("maxprice", maxprice);
        setCheckUrlPairName(false);
        socket.emit("SendTradeHistory", {
            PAIR: baseCurrency + quoteCurrency,
            priceDecimal: 0,
            volumeDecimal: 0,
        });
        socket.emit("SendOrderBook", {
            PAIR: baseCurrency + quoteCurrency,
            COUNT: 17,
            priceDecimal: 0,
            volumeDecimal: 0,
        });
        socket.emit("SendMarket");
        socket.emit("sendallorder", { PAIR: baseCurrency + quoteCurrency, USER_ID: currentUserData?.userID });
        socket.emit("sendWalletData", {
            USER_ID: currentUserData?.userID,
            FIRST: baseCurrency,
            LAST: quoteCurrency,
        });
        // DepthChart(); // Call Depth trading chart
    };

    useEffect(() => {
        socket.on("ReceiveMarket", getReceiveData);
        return () => {
            socket.off("ReceiveMarket", getReceiveData);
        };
    }, [activeMarketTab, RunningPair]);

    const getReceiveData = (data) => {
        try {
            let tempMarkets = [];
            let MarketData = [];
            let receiveMarketData = [];
            let list = [];
            tempMarkets =
                data &&
                data
                    .filter((marketItem) => baseCurrency === marketItem.First && quoteCurrency === marketItem.Last) // Filter based on condition
                    .map((marketItem) => ({
                        // Construct object for each item
                        ...marketItem,
                    }));
            setReceiveData(tempMarkets);
            receiveMarketData = data.map((marketItem) => ({
                PairSymbol: marketItem.PairName,
                ShowPairSymbol: `${marketItem.First}/${marketItem.Last}`,
                FirstSymbol: marketItem.First,
                LastSymbol: marketItem.Last,
                LastPrice: marketItem.Price,
                Change: marketItem.TwentyHRPriceChangePrice,
                ChangePercent: marketItem.TwentyHRPriceChangePer,
                Volume: parseFloat(marketItem.TwentyHRVol),
                priceDecimal: marketItem.PRICE_PRECISION,
                volumeDecimal: marketItem.VOLUME_PRECISION,
                CoinImage: marketItem.coinImage,
                Low: marketItem.TwentHRLow,
                High: marketItem.TwentHRHigh,
            }));
            MarketData.push(receiveMarketData);
            if (data) {
                currencyData = receiveMarketData;
            }
            const pairNames = data
                .filter((x) => x.Last.toLowerCase() === activeMarketTab.toLowerCase())
                .map((item) => item.PairName.toLowerCase())
                .filter((value, index, self) => self.indexOf(value) === index);
            list = receiveMarketData.filter((x) => {
                return pairNames.includes(x.PairSymbol.toLowerCase());
            });
            setLoading(false);
            if (activeMarketTab === "favorite") {
                setReceiveMarket(favouriteList);
            } else {
                setReceiveMarket(list);
            }
        } catch (e) {
            // console.error(e);
        }
    };

    const filteredCoinData = receiveMarket.filter(
        (item) =>
            item.FirstSymbol.toLowerCase().includes(marketSearchQuery.toLowerCase()) ||
            item.PairSymbol.toLowerCase().includes(marketSearchQuery.toLowerCase()) ||
            item.LastSymbol.toLowerCase().includes(marketSearchQuery.toLowerCase())
    );

    // -------------------- receive Trade Histroy -----------------

    const receiveTradeHistroy = () => {
        socket.on("ReceiveTradeHistory", (data) => {
            try {
                if (data !== null) {
                    if (data !== undefined && data.length > 0 && data[0].symbol === baseCurrency + quoteCurrency) {
                        setTrade(data);
                        setCurrHisPrice(data[0].price);
                        if (data.length >= 2) {
                            if (data[0].price > data[1].price) {
                                setIsUp(true);
                                setIsDown(false);
                                setHisColor("green_color");
                                setIssame(false);
                            } else if (data[0].price < data[1].price) {
                                setIsUp(false);
                                setIsDown(true);
                                setHisColor("red_color");
                                setIssame(false);
                            } else {
                                setIsUp(false);
                                setIsDown(false);
                                setHisColor("gray_color");
                                setIssame(true);
                            }
                        } else {
                            setIsUp(false);
                            setIsDown(false);
                            setHisColor("gray_color");
                            setIssame(true);
                        }
                    } else {
                        setTrade([]);
                        setCurrHisPrice(0);
                        setIsUp(false);
                        setIsDown(false);
                        setHisColor("gray_color");
                        setIssame(false);
                    }
                }
            } catch (ex) {
                console.log("Exception:", ex.toString());
            }
        });
        return () => {
            // Cleanup function to close WebSocket connection
            socket.off("ReceiveTradeHistory");
        };
    };

    // -------------------- RECVEIVE ORDER BOOK ------------------
    const receiveOrderbook = () => {
        socket.on("ReceiveOrderBook", (data) => {
            try {
                setCurrentSellPrice(0);
                setCurrentBuyPrice(0);
                if (data.symbol === baseCurrency + quoteCurrency) {
                    if (data.aggAsks !== undefined && data.aggAsks.length > 15) {
                        setBookSell(data.aggAsks);
                        if (!isNaN(parseFloat(data.aggAsks[data.aggAsks.length - 1].price))) {
                            setCurrentBuyPrice(data.aggAsks[data.aggAsks.length - 1].price);
                        }
                    }
                    if (data.aggBids !== undefined && data.aggBids.length > 15) {
                        setBookBuy(data.aggBids);
                        if (!isNaN(parseFloat(data.aggBids[0].price))) {
                            setCurrentSellPrice(data.aggBids[0].price);
                        }
                    }
                    setShowLoaderBookSell(false);
                    setShowLoaderBookBuy(false);
                }
            } catch (ex) {
                console.log("Exception:", ex.toString());
            }
        });
        return () => {
            // Cleanup function to close WebSocket connection
            socket.off("ReceiveOrderBook");
        };
    };

    // -------------------- RECVEIVE ALL ORDERS ------------------

    const Receiveallorders = () => {
        socket.on("Receiveallorders", (data) => {
            try {
                if (data !== null) {
                    if (data.orders !== undefined && data.orders !== null) {
                        exOpen = data.orders.filter((x) => x.STATUS.toLowerCase() === "pending");
                        exFill = data.orders.filter((x) => x.STATUS.toLowerCase() === "executed" || x.STATUS.toLowerCase() === "cancelled");
                        exTradeHis = data.orders.filter((x) => x.STATUS.toLowerCase() === "executed");
                    } else {
                        exOpen = [];
                        exFill = [];
                        exTradeHis = [];
                    }
                }
                setShowLoaderOpenOrder(false);
                setShowLoaderOrderHistory(false);
            } catch (ex) {
                console.log("Exception:", ex.toString());
            }
        });
        return () => {
            // Cleanup function to close WebSocket connection
            socket.off("Receiveallorders");
        };
    };

    // ---------------------- BUY/SELL ---------------------------

    //  ---------------------- Place Order ------------------------

    const handlePlaceOrder = (e, type) => {
        const value = e.target.value;
        var regex;
        if (type === "price") regex = new RegExp(`^\\d{0,9}(\\.\\d{0,${priceDecimal}})?$`);
        else regex = new RegExp(`^\\d{0,9}(\\.\\d{0,${volumeDecimal}})?$`);

        if (regex.test(value)) {
            const parsedValue = parseFloat(value);
            if (!isNaN(parsedValue)) {
                if (type === "price") setPriceBuySell(value);
                else setSizeAmount(value);
            } else {
                if (type === "price") setPriceBuySell();
                else setSizeAmount();
            }
        }
    };

    const handleCurrentPriceSize = (type, flag) => {
        if (flag === "price") {
            setPriceBuySell(type == "sell" ? currentSellPrice : currentBuyPrice);
        } else {
            setSizeAmount(0);
            setPriceBuySell(type == "sell" ? currentSellPrice : currentBuyPrice);
        }
    };

    const OpenMoalpopup = () => {
        if (!isLogin) {
            toast.error("set value user authentication or not");
            return;
        }
        if (allOrders.length > 0) {
            return;
        }
        // if (!docVerify)  toast.error('Please upload your valid kyc to get verified first.'); return;  //-- set comment
        if (!currentUserData.isTrading) {
            toast.error("Your can not trade temporarily.");
            return;
        }
        // if (!is2FA) toast.error('Please activate 2FA to secure your account and trade.'); return;  // -- set comment

        if (currentUserData.isfreeze) toast.error("Your account is FREEZED."); // call userLogout method in side if
        setSizeAmount(parseFloat(sizeAmount));
        if (activeTab == "buy") {
            setPriceBuySell(activeLimitTab == "market" ? currentBuyPrice : limitPrice);
            setTotalBuySell(activeLimitTab == "market" ? Makettotalbuy : totalBuySell);

            if (parseFloat(totalBuySell) < parseFloat(mintotal)) {
                toast.error("Total buy order must be >=" + parseFloat(mintotal));
                return;
            }

            if ((parseFloat(minprice) > parseFloat(priceBuySell) && parseFloat(minprice) > 0) || (parseFloat(maxprice) < parseFloat(priceBuySell) && parseFloat(maxprice) > 0)) {
                toast.error("Order price must be between " + parseFloat(minprice) + " and " + parseFloat(maxprice));
                return;
            }

            if ((parseFloat(minsize) > parseFloat(sizeAmount) && parseFloat(minsize) > 0) || (parseFloat(maxsize) < parseFloat(sizeAmount) && parseFloat(maxsize) > 0)) {
                toast.error("Order size must be between " + parseFloat(minsize) + " and " + parseFloat(maxsize));
                return;
            }

            if (parseFloat(totalBuySell) > parseFloat(ExcurrBalance)) {
                toast.error("In-sufficient funds.");
                return;
            }
        } else {
            setPriceBuySell(activeLimitTab == "market" ? currentSellPrice : limitPrice);
            setTotalBuySell(activeLimitTab == "market" ? Makettotalsell : totalBuySell);

            if (parseFloat(totalBuySell) < parseFloat(mintotal)) {
                toast.error("Total sell order must be >=" + parseFloat(mintotal).toFixed(8));
                return;
            }

            if ((parseFloat(minprice) > parseFloat(priceBuySell) && parseFloat(minprice) > 0) || (parseFloat(maxprice) < parseFloat(priceBuySell) && parseFloat(maxprice) > 0)) {
                toast.error("Order price must be between " + parseFloat(minprice) + " and " + parseFloat(maxprice));
                return;
            }

            if ((parseFloat(minsize) > parseFloat(sizeAmount) && parseFloat(minsize) > 0) || (parseFloat(maxsize) < parseFloat(sizeAmount) && parseFloat(maxsize) > 0)) {
                toast.error("Order size must be between " + parseFloat(minsize) + " and " + parseFloat(maxsize));
                return;
            }

            if (parseFloat(sizeAmount) > parseFloat(ExcoinBalance)) {
                toast.error("In-sufficient funds.");
                return;
            }
        }

        allOrders = [
            {
                buysell: activeTab,
                orderprice: priceBuySell,
                volume: sizeAmount,
                orderType: activeLimitTab,
                stopPrice: stopPrice,
            },
        ];
        setSubmitOrderData();
    };

    const setSubmitOrderData = () => {
        var data = {
            buy_sell: allOrders[0].buysell,
            order_price: allOrders[0].orderprice,
            order_size: allOrders[0].volume,
            stop_limit_price: allOrders[0].orderType === "limit" ? 0 : allOrders[0].stopPrice,
            order_type: allOrders[0].orderType,
            pair: baseCurrency + quoteCurrency,
        };
        let placeOrderDetail = new Promise((resolve) => {
            resolve(PostTokenApi(API_Path.placeOrder, data));
        });
        placeOrderDetail.then((res) => {
            if (res.data.Isvalid === true) toast.success(`Order to ${allOrders[0].buysell} ${coinData.firstCoin}/${coinData.baseCoin} Created/Executed at ${allOrders[0].orderprice}`);
            else toast.error(res.data.Errors[0]);
            allOrders = [];
            setPriceBuySell(0);
            setSizeAmount(0);
        });
    };

    const handlePlaceOrderData = (data) => {
        setPriceBuySell(data.price);
        setSizeAmount(expoStrToNumStr(data.volume));
        setTotalBuySell(expoStrToNumStr(data.total));
    };

    useEffect(() => {
        if (activeLimitTab === "market") {
            if (activeTab === "sell") {
                if (!isNaN(parseFloat(currentSellPrice)) && !isNaN(parseFloat(sizeAmount))) {
                    setMakettotalsell(
                        isNaN(parseFloat(parseFloat(currentSellPrice) * parseFloat(sizeAmount)).toFixed(8)) ? 0 : parseFloat(parseFloat(currentSellPrice) * parseFloat(sizeAmount)).toFixed(8)
                    );
                } else {
                    setMakettotalsell(0);
                }
            } else {
                if (!isNaN(parseFloat(currentBuyPrice)) && !isNaN(parseFloat(sizeAmount))) {
                    setMakettotalbuy(
                        isNaN(parseFloat(parseFloat(currentBuyPrice) * parseFloat(sizeAmount)).toFixed(8)) ? 0 : parseFloat(parseFloat(currentBuyPrice) * parseFloat(sizeAmount)).toFixed(8)
                    );
                } else {
                    setMakettotalbuy(0);
                }
            }
        } else {
            if (!isNaN(parseFloat(sizeAmount)) && !isNaN(parseFloat(priceBuySell))) {
                setTotalBuySell(isNaN(parseFloat(sizeAmount) * parseFloat(priceBuySell)) ? 0 : parseFloat(parseFloat(sizeAmount) * parseFloat(priceBuySell)).toFixed(8));
                if (activeLimitTab === "Stop-limit" && activeTab === "sell") {
                    setTotalBuySell(isNaN(parseFloat(stopPrice) * parseFloat(priceBuySell)) ? 0 : parseFloat(parseFloat(stopPrice) * parseFloat(priceBuySell)).toFixed(8));
                }
            } else {
                setTotalBuySell(0);
            }
        }
    }, [priceBuySell, sizeAmount]);

    // ---------------------- Wallet Data -----------------
    const ReceiveWalletData = () => {
        socket.on("ReceiveWalletData", (data) => {
            try {
                if (data !== null) {
                    if (data !== undefined && data.wallet !== undefined && data.wallet.length > 0) {
                        userWallets = data.wallet;

                        const coinBalanceItem = data.wallet.find((x) => x.COIN.toLowerCase() === coinData.firstCoin.toLowerCase());
                        if (coinBalanceItem) setCoinBalance(coinBalanceItem.BALANCE);

                        const currbalanceItem = data.wallet.find((x) => x.COIN.toLowerCase() === coinData.baseCoin.toLowerCase());
                        if (currbalanceItem) setCurrBalance(currbalanceItem.BALANCE);
                    }

                    if (data !== undefined && data.fees !== undefined && data.fees.length > 0) {
                        setMakerFees(parseFloat(data.fees[0].maker_fees));
                        setTakerFees(parseFloat(data.fees[0].taker_fees));
                    }
                }
            } catch (ex) {
                console.log("Exception:", ex.toString());
            }
        });
        return () => {
            // Cleanup function to close WebSocket connection
            socket.off("ReceiveWalletData");
        };
    };

    const manegesocket = () => {
        var socketStatuss = socket.connected ? true : false;
        if (!socketStatuss) {
            setIsSocketCalled(true);
            socketConnect(false);
            socketConnect(true);
        } else {
            if (isSocketCalled) {
                setIsSocketCalled(false);
                setTimeout(() => {
                    // SendAllData();
                    SendSocket();
                }, 2000);
            }
        }
    };

    const socketConnect = (value) => {
        if (value) {
            socket = io(socketUrl, { autoConnect: false });
            socket.open();
        } else {
            if (socket) {
                socket.disconnect();
            }
        }
    };

    return (
        <>
            {!isNotFound ? (
                <Layout>
                    <div className={styles.trading}>
                        <div className={styles.trading_body}>
                            <div className={styles.pair_volume}>
                                {/* -- Start : COIN OPTION -- */}
                                <div className={`${styles.coin_pair} cursor-pointer`}>
                                    {favouriteList.some((likedItem) => likedItem.PairSymbol === coinData.firstCoin + coinData.baseCoin) ? (
                                        <span className={styles.dislike} onClick={() => handleDisFavoriteCoin(coinData.firstCoin + coinData.baseCoin)}>
                                            ★
                                        </span>
                                    ) : (
                                        <span className={styles.like} onClick={() => handleFavoriteCoin(coinData.firstCoin + coinData.baseCoin)}>
                                            ☆
                                        </span>
                                    )}
                                    <div className={styles.markeT_coin_hov}>
                                        <button className={styles.dropdown_toggle} onClick={() => handleMarketShow()}>
                                            <span>
                                                <b>{baseCurrency + quoteCurrency}</b>
                                            </span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none" className="ms-2">
                                                <path
                                                    d="M11.8616 4.67563C11.9912 4.56253 12.1591 4.5 12.333 4.5C12.507 4.5 12.6748 4.56253 12.8045 4.67563C12.8659 4.72879 12.9152 4.79407 12.9489 4.86715C12.9826 4.94023 13 5.01945 13 5.09959C13 5.17972 12.9826 5.25894 12.9489 5.33202C12.9152 5.4051 12.8659 5.47038 12.8045 5.52355L7.47153 10.3244C7.34188 10.4375 7.17403 10.5 7.00008 10.5C6.82613 10.5 6.65828 10.4375 6.52863 10.3244L1.19569 5.52355C0.935003 5.28959 0.935002 4.90958 1.1943 4.67563C1.4536 4.44167 1.87652 4.44167 2.1372 4.67563L6.99869 9.05046L11.8616 4.67563Z"
                                                    fill="#00c4f4"
                                                />
                                            </svg>
                                        </button>
                                        <div className={`${styles.dropdown_market}`}>
                                            <div className={styles.market_dropdown_blur}></div>
                                            <h6 className="text-start">Market</h6>
                                            <div className={styles.fav_search}>
                                                <input
                                                    type="search"
                                                    id="MarketSearch"
                                                    name="MarketSearch"
                                                    value={marketSearchQuery}
                                                    placeholder="Search"
                                                    className="ng-pristine ng-untouched ng-valid ng-empty"
                                                    onChange={(e) => setMarketSearchQuery(e.target.value)}
                                                />
                                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.475 12.2499L9.93018 9.7124" stroke="#404040" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M6.62441 11.0833C9.20915 11.0833 11.3045 8.994 11.3045 6.41667C11.3045 3.83934 9.20915 1.75 6.62441 1.75C4.03968 1.75 1.94434 3.83934 1.94434 6.41667C1.94434 8.994 4.03968 11.0833 6.62441 11.0833Z"
                                                        stroke="#404040"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                            <div className={`${styles.order_coin_tab} ${styles.market_coin_tab}`}>
                                                <ul className={`${styles.tab} ${styles.navtabs}`}>
                                                    {marketTabs &&
                                                        marketTabs.map((item, i) => (
                                                            <li className={styles.navitem} key={i}>
                                                                <button
                                                                    className={`${styles.navlink} ${activeMarketTab == item.TabTitle ? styles.active : ""} ms-0`}
                                                                    aria-current="page"
                                                                    onClick={() => {
                                                                        handleMarketTabClick(item.TabTitle);
                                                                        setLoading(true);
                                                                    }}
                                                                >
                                                                    {item.TabTitle}
                                                                </button>
                                                            </li>
                                                        ))}
                                                    <li className={styles.navitem}>
                                                        <button
                                                            className={`${styles.navlink} ${activeMarketTab === "favorite" ? styles.active : ""} ms-0`}
                                                            aria-current="page"
                                                            onClick={() => {
                                                                handleMarketTabClick("favorite");
                                                                setLoading(true);
                                                            }}
                                                        >
                                                            Hot List
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className={`${styles.trading_tbl_head} ${styles.market_table}`}>
                                                <ul className="d-flex align-items-center">
                                                    <li>Market</li>
                                                    <li>Last Price</li>
                                                    <li>Change</li>
                                                    <li>24h Vol</li>
                                                </ul>
                                            </div>
                                            <div className={`${styles.trading_tbl_body} ${loading ? "d-flex align-items-center justify-content-center my-4" : ""}`}>
                                                <ul>
                                                    {loading ? (
                                                        <div className="loading_wave">
                                                            <div className="loading_bar"></div>
                                                            <div className="loading_bar"></div>
                                                            <div className="loading_bar"></div>
                                                            <div className="loading_bar"></div>
                                                        </div>
                                                    ) : receiveMarket && receiveMarket.length > 0 && filteredCoinData.length > 0 ? (
                                                        filteredCoinData.map((item, i) => (
                                                            <li key={i} className="cursor-pointer" onClick={() => handleClickCoin(item.FirstSymbol, item.LastSymbol)}>
                                                                <div className="px-0 d-flex align-items-center">
                                                                    {favouriteList.some((likedItem) => likedItem.PairSymbol === item.PairSymbol) ? (
                                                                        <span
                                                                            className={styles.dislike}
                                                                            onClick={(e) => {
                                                                                handleDisFavoriteCoin(item.PairSymbol);
                                                                                e.stopPropagation();
                                                                            }}
                                                                        >
                                                                            ★
                                                                        </span>
                                                                    ) : (
                                                                        <span
                                                                            className={styles.like}
                                                                            onClick={(e) => {
                                                                                handleFavoriteCoin(item.PairSymbol);
                                                                                e.stopPropagation();
                                                                            }}
                                                                        >
                                                                            ☆
                                                                        </span>
                                                                    )}
                                                                    {item.ShowPairSymbol}
                                                                </div>
                                                                <div className="px-0">{parseFloatAmt(item.LastPrice, item.priceDecimal)}</div>
                                                                <div className={`${item.Change >= 0 ? "green_color" : "red_color"} px-0"`}>{item.ChangePercent}%</div>
                                                                <div>{parseFloatAmt(item.Volume, item.priceDecimal)}</div>
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <div className={`${styles.empty_record} ${styles.empty_record_exchange} py-3`}>
                                                            <img src={emptyRecordImg} alt="" />
                                                            <span>No records found</span>
                                                        </div>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* -- End : COIN OPTION -- */}

                                {/* -- Start : VOLUME COUNT -- */}
                                {receiveData && (
                                    <div className={styles.volume}>
                                        <div className={styles.volume_cont}>
                                            <span>Last Price</span>
                                            <bdi className={hisColor}>{parseFloatAmt(receiveData[0]?.Price, receiveData[0]?.PRICE_PRECISION)}</bdi>
                                        </div>
                                        <div className={styles.volume_cont}>
                                            <span>24h Change</span>
                                            <bdi className={receiveData[0]?.TwentyHRPriceChangePrice > 0 ? "green_color" : "red_color"}>
                                                {parseFloatAmt(receiveData[0]?.TwentyHRPriceChangePrice, receiveData[0]?.PRICE_PRECISION)}
                                            </bdi>
                                        </div>
                                        <div className={styles.volume_cont}>
                                            <span>24h High</span>
                                            <bdi className="ng-binding">{receiveData[0]?.TwentHRHigh ?? 0}</bdi>
                                        </div>
                                        <div className={styles.volume_cont}>
                                            <span>24h Low</span>
                                            <bdi className="ng-binding">{receiveData[0]?.TwentHRLow ?? 0}</bdi>
                                        </div>
                                        <div className={styles.volume_cont}>
                                            <span>24h Volume</span>
                                            <bdi className="ng-binding">{(receiveData[0]?.Last === "USDT" ? receiveData[0]?.USD_VOL : receiveData[0]?.BTC_VOL) + " " + receiveData[0]?.Last}</bdi>
                                        </div>
                                    </div>
                                )}
                                {/* -- End : VOLUME COUNT -- */}
                            </div>
                            <div className={`${styles.border_top_ex} p-0`}>
                                <div className="d-flex">
                                    {/* -- Start : Order Book -- */}
                                    <div className={`${styles.trading_order} ${styles.col_lg_3} col_padd_none chart_col`}>
                                        <div className={styles.buy_sell_orderbook}>
                                            <h6 className={styles.title}>
                                                Order Book
                                                <bdi>
                                                    <Link to="/orderbook">More</Link>
                                                </bdi>
                                            </h6>
                                            <div className={`${styles.dots} text-center`}>
                                                <span className={orderBookType !== "multi" ? styles.dots_opacity : ""} onClick={() => setOrderBookType("multi")}></span>
                                                <span className={orderBookType !== "green" ? styles.dots_opacity : ""} onClick={() => setOrderBookType("green")}></span>
                                                <span className={orderBookType !== "red" ? styles.dots_opacity : ""} onClick={() => setOrderBookType("red")}></span>
                                            </div>
                                            <div className={styles.trading_tbl_head}>
                                                <ul className="d-flex">
                                                    <li>Amount({baseCurrency})</li>
                                                    <li>Price({quoteCurrency})</li>
                                                    <li className="text-start">Total({baseCurrency})</li>
                                                </ul>
                                            </div>
                                            <div className={`${styles.trading_tbl_body} ${styles.tbl_height}`}>
                                                <ul>
                                                    {showLoaderBookSell ? (
                                                        <li className="loading_wave">
                                                            <div className="loading_bar"></div>
                                                            <div className="loading_bar"></div>
                                                            <div className="loading_bar"></div>
                                                            <div className="loading_bar"></div>
                                                        </li>
                                                    ) : (
                                                        (orderBookType === "multi" || orderBookType === "red") &&
                                                        bookSell &&
                                                        bookSell.length > 0 &&
                                                        bookSell.map((items, i) => {
                                                            return (
                                                                <li className="cursor-pointer" key={i} onClick={() => handlePlaceOrderData(items)}>
                                                                    <p>{expoStrToNumStr(items.volume)}</p>
                                                                    <p className="red_color">{items.price}</p>
                                                                    <p>{expoStrToNumStr(items.totalBase)}</p>
                                                                </li>
                                                            );
                                                        })
                                                    )}
                                                </ul>
                                            </div>
                                            <div className={styles.mix_price}>
                                                {isUp && (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#01BC2A" className="bi bi-arrow-up-short" viewBox="0 0 16 16">
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5"
                                                        />
                                                    </svg>
                                                )}
                                                {isDown && (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#dc3545" className="bi bi-arrow-down-short" viewBox="0 0 16 16">
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4"
                                                        />
                                                    </svg>
                                                )}
                                                <b className={hisColor}>{currHisPrice}</b>
                                            </div>

                                            <div className={styles.trading_tbl_body}>
                                                <ul>
                                                    {(orderBookType === "multi" || orderBookType === "green") &&
                                                        bookBuy.map((items, i) => {
                                                            return (
                                                                <li key={i} className="cursor-pointer" onClick={() => handlePlaceOrderData(items)}>
                                                                    <p className="">{expoStrToNumStr(items.volume)}</p>
                                                                    <p className="green_color ps-0">{items.price}</p>
                                                                    <p className="">{expoStrToNumStr(items.totalBase)}</p>
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* -- End : Order Book -- */}

                                    <div className={`${styles.trading_border} ${styles.col_lg_6} col_padd_none chart_col border-bottom-0 p-0 border-top-0`}>
                                        <div className={styles.iframe_chart}>
                                            <iframe width="100%" height="480" src={`${ApiBaseUrl}tradingview/${id.toUpperCase()}`} allowFullScreen></iframe>
                                        </div>
                                        <div className={styles.tbl_fav}>
                                            <div className={`${styles.order_coin_tab} pt-0 pb-0`}>
                                                <ul className={`${styles.tab} ${styles.navtabs}`}>
                                                    <li className={styles.navitem}>
                                                        <button
                                                            className={`${styles.navlink} ${activeOrderTab === "open" ? styles.active : ""} ms-0`}
                                                            aria-current="page"
                                                            onClick={() => handleOrderTabClick("open")}
                                                        >
                                                            Open Order
                                                        </button>
                                                    </li>
                                                    <li className={styles.navitem}>
                                                        <button
                                                            className={`${styles.navlink} ${activeOrderTab === "complete" ? styles.active : ""} ms-0`}
                                                            aria-current="page"
                                                            onClick={() => handleOrderTabClick("complete")}
                                                        >
                                                            Completed Order
                                                        </button>
                                                    </li>
                                                    <li className={styles.navitem}>
                                                        <button
                                                            className={`${styles.navlink} ${activeOrderTab === "trad" ? styles.active : ""} ms-0`}
                                                            aria-current="page"
                                                            onClick={() => handleOrderTabClick("trad")}
                                                        >
                                                            Trade History
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className={`${styles.buy_market_trades} border-0`}>
                                                <div className={styles.trading_tbl_head}>
                                                    <ul className="d-flex align-items-center">
                                                        <li>Date</li>
                                                        <li>pair</li>
                                                        <li>Type</li>
                                                        <li>Price({quoteCurrency})</li>
                                                        <li>Amount({baseCurrency})</li>
                                                        <li>Total({quoteCurrency})</li>
                                                        {activeOrderTab != "open" && <li>Deal Price</li>}
                                                        {activeOrderTab != "open" && <li>Deal Amount</li>}
                                                        {activeOrderTab != "open" && <li>Fees</li>}
                                                        {activeOrderTab === "complete" && <li>Status</li>}
                                                        {activeOrderTab != "open" && <li>Paid/Get Paid</li>}
                                                        {activeOrderTab === "open" && <li>Action</li>}
                                                    </ul>
                                                </div>
                                                <div
                                                    className={`${styles.trading_tbl_body} ${showLoaderOrderHistory ? "" : styles.display_style} ${exOpen.length === 0 ? styles.display_style : ""}`}
                                                    style={{ height: "332px" }}
                                                >
                                                    {activeOrderTab === "open" && (
                                                        <ul>
                                                            {exOpen.length < 0 ? (
                                                                exOpen.map((items, i) => (
                                                                    <li key={i}>
                                                                        <p className="red_color">{items.ENT_DATE}</p>
                                                                        <p>{baseCurrency + quoteCurrency}</p>
                                                                        <p>{items.ORDER_TYPE}</p>
                                                                        <p className="red_color">{items.ORDER_PRICE}</p>
                                                                        <p>{items.ORDER_SIZE}</p>
                                                                        <p>{items.TOTAL_PRICE}</p>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <div className={styles.data_not_found}>
                                                                    <img src={emptyRecordImg} alt="" className={`${styles.img_fluid} img-fluid`} />
                                                                    <span className="my-2">No records found</span>
                                                                </div>
                                                            )}
                                                        </ul>
                                                    )}
                                                    {activeOrderTab === "complete" && (
                                                        <ul>
                                                            {exFill.length < 0 ? (
                                                                exFill.map((items, i) => (
                                                                    <li key={i}>
                                                                        <p>{items.UPD_DATE}</p>
                                                                        <p>{items.ORDER_TYPE}</p>
                                                                        <p className="red_color">{items.ORDER_PRICE}</p>
                                                                        <p>{items.ORDER_SIZE}</p>
                                                                        <p>{items.TOTAL_PRICE}</p>
                                                                        <p>{items.EXECUTED_PRICE}</p>
                                                                        <p>{items.DEAL_AMOUNT}</p>
                                                                        <p>{items.FEES}</p>
                                                                        <p>{items.FINAL_TOTAL}</p>
                                                                        <p>{items.STATUS == "executed" ? "Executed" : "Cancelled"}</p>
                                                                        <p>{items.FINAL_TOTAL}</p>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <div className={styles.data_not_found}>
                                                                    <img src={emptyRecordImg} alt="" className={`${styles.img_fluid} img-fluid`} />
                                                                    <span className="my-2">No records found</span>
                                                                </div>
                                                            )}
                                                        </ul>
                                                    )}
                                                    {activeOrderTab === "trad" && (
                                                        <ul>
                                                            {activeOrderTab === "" && exTradeHis.length < 0 ? (
                                                                exTradeHis.map((items, i) => (
                                                                    <li key={i}>
                                                                        <p>{items.UPD_DATE}</p>
                                                                        <p>{items.ORDER_TYPE}</p>
                                                                        <p className="red_color">{items.ORDER_PRICE}</p>
                                                                        <p>{items.ORDER_SIZE}</p>
                                                                        <p>{items.TOTAL_PRICE}</p>
                                                                        <p>{items.EXECUTED_PRICE}</p>
                                                                        <p>{items.DEAL_AMOUNT}</p>
                                                                        <p>{items.FEES}</p>
                                                                        <p>{items.FINAL_TOTAL}</p>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <div className={styles.data_not_found}>
                                                                    <img src={emptyRecordImg} alt="" className={`${styles.img_fluid} img-fluid`} />
                                                                    <span className="my-2">No records found</span>
                                                                </div>
                                                            )}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${styles.border_left} ${styles.col_lg_3} col_padd_none chart_col`}>
                                        {/* -- Start : Market Trades -- */}
                                        <div className={styles.buy_market_trades}>
                                            <h6 className={styles.title}>Market Trades</h6>
                                            <div className={styles.trading_tbl_head}>
                                                <ul className="d-flex align-items-center">
                                                    <li>Price({quoteCurrency})</li>
                                                    <li>Amount({baseCurrency})</li>
                                                    <li>Time(UTC)</li>
                                                </ul>
                                            </div>
                                            <div className={`${styles.trading_tbl_body} ${styles.auto_height} ${trade.length < 0 ? styles.display_style : ""}`}>
                                                <ul>
                                                    {trade && trade.length > 0 ? (
                                                        trade.map((Trd, i) => (
                                                            <li key={i}>
                                                                <p className={Trd.side == "buy" ? "green_color" : "red_color"}>{expoStrToNumStr(Trd.price)}</p>
                                                                <p className="">{expoStrToNumStr(Trd.volume)}</p>
                                                                <p className="">{Trd.time}</p>
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <div className="p-3">
                                                            <div className={`${styles.empty_record} ${styles.empty_record_exchange}`}>
                                                                <img src={DataNotFound} alt="" />
                                                                <span>No records found</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>

                                        {/* -- End : Market Trades -- */}

                                        {/* -- Start : Spot -- */}

                                        <div className={styles.spot_section}>
                                            <h6 className={styles.title}>Place Order</h6>
                                            <div className={styles.spot_section_tab}>
                                                <ul className={`${styles.tab} ${styles.navtabs}`}>
                                                    <li className={styles.navitem}>
                                                        <button
                                                            className={`${styles.navlink} ${activeTab === "buy" ? styles.active : ""} ms-0`}
                                                            aria-current="page"
                                                            onClick={() => handleTabClick("buy")}
                                                        >
                                                            BUY
                                                        </button>
                                                    </li>
                                                    <li className={styles.navitem}>
                                                        <button className={`${styles.navlink} ${styles.bg_red} ${activeTab === "sell" ? styles.active : ""}`} onClick={() => handleTabClick("sell")}>
                                                            SELL
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className={styles.type_coin_tab}>
                                                <ul className={`${styles.tab} ${styles.navtabs}`}>
                                                    <li className={styles.navitem}>
                                                        <button
                                                            className={`${styles.navlink} ${activeLimitTab === "limit" ? styles.active : ""} ms-0`}
                                                            aria-current="page"
                                                            onClick={() => handlelimitTabClick("limit")}
                                                        >
                                                            Limit
                                                        </button>
                                                    </li>
                                                    <li className={styles.navitem}>
                                                        <button
                                                            className={`${styles.navlink} ${activeLimitTab === "market" ? styles.active : ""} ms-0`}
                                                            aria-current="page"
                                                            onClick={() => handlelimitTabClick("market")}
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="bottom"
                                                            title="Coming soon"
                                                            disabled
                                                        >
                                                            Market
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className={styles.price_area}>
                                                <div className={styles.price_form_area}>
                                                    <Formik
                                                        initialValues={{
                                                            price: priceBuySell,
                                                            amount: sizeAmount,
                                                        }}
                                                        validationSchema={Yup.object().shape({
                                                            amount: Yup.number().required("Amount is required."),
                                                            price: Yup.number().required("Price is required."),
                                                        })}
                                                        onSubmit={(values, { resetForm }) => {
                                                            OpenMoalpopup(values, resetForm);
                                                        }}
                                                    >
                                                        {(runform) => (
                                                            <Form>
                                                                <div className={styles.from_field}>
                                                                    <label>
                                                                        Price
                                                                        {/* <span className={styles.lbl_right_text}>
                                                                        0.015 USDT : <bdi>Available</bdi>
                                                                </span> */}
                                                                        <div className={styles.right_indicator}>
                                                                            <span className="cursor-pointer" onClick={() => handleCurrentPriceSize("buy", "price")}></span>
                                                                            <span className="cursor-pointer" onClick={() => handleCurrentPriceSize("sell", "price")}></span>
                                                                        </div>
                                                                    </label>
                                                                    <div className={styles.input_field}>
                                                                        <input
                                                                            type="number"
                                                                            placeholder=""
                                                                            value={priceBuySell || ""}
                                                                            name="price"
                                                                            onChange={(e) => {
                                                                                handlePlaceOrder(e, "price");
                                                                                const parsedValue = parseFloat(e.target.value);
                                                                                if (!isNaN(parsedValue)) {
                                                                                    runform.setFieldValue("price", parsedValue);
                                                                                }
                                                                            }}
                                                                        />
                                                                        <span>{quoteCurrency}</span>
                                                                    </div>
                                                                    {errorContainer(runform, "price")}
                                                                </div>
                                                                <div className={styles.from_field}>
                                                                    <label>
                                                                        Amount
                                                                        <div className={styles.right_indicator}>
                                                                            <span className="cursor-pointer" onClick={() => handleCurrentPriceSize("buy", "amount")}></span>
                                                                            <span className="cursor-pointer" onClick={() => handleCurrentPriceSize("sell", "amount")}></span>
                                                                        </div>
                                                                    </label>
                                                                    <div className={styles.input_field}>
                                                                        <input
                                                                            type="number"
                                                                            placeholder=""
                                                                            value={sizeAmount || ""}
                                                                            name="amount"
                                                                            onChange={(e) => {
                                                                                handlePlaceOrder(e, "amount");
                                                                                const parsedValue = parseFloat(e.target.value);
                                                                                if (!isNaN(parsedValue)) {
                                                                                    runform.setFieldValue("amount", parsedValue);
                                                                                }
                                                                            }}
                                                                        />
                                                                        <span>{baseCurrency}</span>
                                                                    </div>
                                                                    {errorContainer(runform, "amount")}
                                                                </div>
                                                                <div className={styles.from_field}>
                                                                    <label>Total</label>
                                                                    <div className={styles.input_field}>
                                                                        <input
                                                                            type="number"
                                                                            placeholder=""
                                                                            style={{ cursor: "no-drop" }}
                                                                            value={activeLimitTab === "market" ? (activeTab === "sell" ? makettotalsell : markettotalbuy) : totalBuySell}
                                                                            name="total"
                                                                            readOnly
                                                                        />
                                                                        <span>{quoteCurrency}</span>
                                                                    </div>
                                                                </div>
                                                                {FunexAuthorizationData !== null ? (
                                                                    activeTab === "buy" ? (
                                                                        <button type="submit" className={`${styles.buy_btn} ${styles.btn_price}`}>
                                                                            BUY
                                                                        </button>
                                                                    ) : (
                                                                        <button type="submit" className={`${styles.btn_price} ${styles.sell_btn}`}>
                                                                            SELL
                                                                        </button>
                                                                    )
                                                                ) : (
                                                                    <button type="button" className={`${styles.auth_button}`}>
                                                                        <Link to="/login">Login </Link>
                                                                        or
                                                                        <Link to="/register"> Register</Link>{" "}
                                                                    </button>
                                                                )}
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                        {/* -- End : Spot -- */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            ) : (
                <NotFound />
            )}
        </>
    );
}
