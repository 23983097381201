import React, { useState } from 'react';
import '../../styles/languageCurrencyModal.css';
import Modal from 'react-bootstrap/Modal';

export default function LanguageCurrencyModal(props) {
    // const languageData = [
    //     {name: "English" , value: "english" , s_name: "" },
    //     {name: "Bahasa Indonesia" , value: "bahasa indonesia" , s_name: "" },
    //     {name: "Deutch" , value: "deutch " , s_name: "(Schweiz)" },
    //     {name: "Espanol" , value: "Espanol" , s_name: "(Espana)" },

    //     {name: "Espanol" , value: "Espanol" , s_name: "(Latioamerica)" },
    //     {name: "English" , value: "English" , s_name: "(Australia)" },
    //     {name: "English" , value: "English" , s_name: "(India)" },
    //     {name: "English" , value: "English" , s_name: "(Nigeria)" },
        
    //     {name: "Espanol" , value: "Espanol" , s_name: "(Mexico)" },
    //     {name: "Espanol" , value: "Espanol" , s_name: "(Argentina)" },
    //     {name: "English" , value: "English" , s_name: "(Kazakhstan)" },
    //     {name: "English" , value: "English" , s_name: "(South Africa)" },

    //     {name: "English " , value: "English " , s_name: "(New Zealand)" },
    //     {name: "English" , value: "English" , s_name: "(Bahrain)" },
    //     {name: "Francais" , value: "Francais" , s_name: "" },
    //     {name: "Francais" , value: "Francais" , s_name: "(Afrique)" },

    //     {name: "Italino" , value: "Italino" , s_name: "" },
    //     {name: "Polski" , value: "Polski" , s_name: "" },
    //     {name: "Portugues " , value: "Portugues " , s_name: "(Brasil)" },
    //     {name: "Portugues " , value: "Portugues " , s_name: " (Portugal) Romana" },

    //     {name: "Svenska" , value: "Svenska" , s_name: "" },
    //     {name: "Slovencina" , value: "Slovencina" , s_name: "" },
    //     {name: "Slovenscina " , value: "Slovenscina " , s_name: "" },
    //     {name: "Tiếng Việt " , value: "Tiếng Việt" , s_name: "" },

    //     {name: "Türkçe" , value: "Türkçe" , s_name: "" },
    //     {name: "latviešu valoda" , value: "latviešu valoda" , s_name: "" },
    //     {name: "Čeština " , value: "Čeština " , s_name: "" },
    //     {name: "Ελληνικά " , value: "Ελληνικά " , s_name: "" },

    //     {name: "Русский" , value: "Русский" , s_name: "" },
    //     {name: "Русский" , value: "Русский" , s_name: "(Украина)" },
    //     {name: "Русский  " , value: "Русский  " , s_name: "(Казахстан)" },
    //     {name: "Українська " , value: "Українська " , s_name: "" },

    //     {name: "български" , value: "български" , s_name: "" },
    //     {name: "Қазақша " , value: "Қазақша " , s_name: "(Қазақстан)" },
    //     {name: "Қазақша  " , value: "Қазақша  " , s_name: "" },
    //     {name: "العربية " , value: "العربية " , s_name: "" },

    //     {name: "اردو" , value: "اردو" , s_name: "" },
    //     {name: "العربية (البحرين) " , value: "العربية (البحرين) " , s_name: "" },
    //     {name: "বাংলা" , value: "বাংলা" , s_name: "" },
    //     {name: "简体中文 " , value: "简体中文" , s_name: "" },

    //     {name: "繁體中文 " , value: "繁體中文" , s_name: "" },

    // ]
    const [activeTab , setActiveTab] = useState("language"); 

    const handleTabClick = (tabKey) => setActiveTab(tabKey);
    return (
        <React.Fragment>
            <Modal show={props.showModal} centered onHide={props.closeModal} className='lang_cur_modal'>
                <Modal.Header>
                    <Modal.Title>
                        <div className='modal_header_tab'>
                            <div className='modal_sub_tab'>
                                <ul className='tab navtabs'>
                                    <li className='navitem'>
                                        <h3 className={`navlink ${activeTab === 'language' && 'active'}`}  onClick={() => handleTabClick('language')}>Language And Region</h3>
                                    </li>
                                    <li className='navitem'>
                                        <h3 className={`navlink ${activeTab === 'currency' && 'active'}`}  onClick={() => handleTabClick('currency')}>Currency</h3>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='lang_cur_body'>
                    <div className='serchbar_lang_cur'>
                        <input type='search' placeholder={activeTab === 'language' ? 'Search Language and Region' : "Search Currency"} className='form_control' />
                    </div>
                    <div className='lang_region_content'>
                        {activeTab === 'language' ? 
                            <div className='lang_region_flex' >
                                <ul>
                                    <li className='pt-4'><p>English</p></li>
                                    <li className='pt-4'><p>Bahasa Indonesia</p></li>
                                    <li className='pt-4'><p>Deutch (Schweiz)</p></li>
                                    <li className='pt-4'><p>Espanol (Espana)</p></li>

                                    <li><p>Espanol (Latioamerica)</p></li>
                                    <li><p>English (Australia)</p></li>
                                    <li><p>English (India)</p></li>
                                    <li><p>English (Nigeria)</p></li>

                                    <li><p>Espanol (Mexico)</p></li>
                                    <li><p>Espanol (Argentina)</p></li>
                                    <li><p>English (Kazakhstan)</p></li>
                                    <li><p>English (South Africa)</p></li>

                                    <li><p>English (New Zealand)</p></li>
                                    <li><p>English (Bahrain)</p></li>
                                    <li><p>Francais</p></li>
                                    <li><p>Francais (Afrique)</p></li>

                                    <li><p>Italino</p></li>
                                    <li><p>Polski</p></li>
                                    <li><p>Portugues (Brasil)</p></li>
                                    <li><p>Portugues (Portugal) Romana</p></li>

                                    <li><p>Svenska</p></li>
                                    <li><p>Slovencina</p></li>
                                    <li><p>Slovenscina</p></li>
                                    <li><p>Tiếng Việt</p></li>

                                    <li><p>Türkçe</p></li>
                                    <li><p>latviešu valoda</p></li>
                                    <li><p>Čeština</p></li>
                                    <li><p>Ελληνικά</p></li>

                                    <li><p>Русский</p></li>
                                    <li><p>Русский (Украина)</p></li>
                                    <li><p>Русский (Казахстан)</p></li>
                                    <li><p>Українська</p></li>

                                    <li><p>български</p></li>
                                    <li><p>Қазақша (Қазақстан)</p></li>
                                    <li><p>Қазақша</p></li>
                                    <li><p>العربية</p></li>

                                    <li><p>اردو</p></li>
                                    <li><p>العربية (البحرين)</p></li>
                                    <li><p>বাংলা</p></li>
                                    <li><p>简体中文</p></li>

                                    <li><p>繁體中文</p></li>
                                </ul>
                            </div>
                        :
                            <div className='lang_region_flex' id="style-2">
                                <ul>
                                    <li className='pt-4'><p>USD - $</p></li>
                                    <li className='pt-4'><p>ARS - ARS$</p></li>
                                    <li className='pt-4'><p>AED - د.إ</p></li>
                                    <li className='pt-4'><p>AUD - A$</p></li>

                                    <li><p>BRL - R$</p></li>
                                    <li><p>BGN - лв</p></li>
                                    <li><p>BOB - $b</p></li>
                                    <li><p>BHD - .د.ب</p></li>

                                    <li><p>BDT - ৳</p></li>
                                    <li><p>CHF - CHF</p></li>
                                    <li><p>BOB - $b</p></li>
                                    <li><p>BHD - .د.ب</p></li>

                                    <li><p>BDT - ৳</p></li>
                                    <li><p>CHF - CHF</p></li>
                                    <li><p>CNY - ¥</p></li>
                                    <li><p>COP - COL$</p></li>

                                    <li><p>CAD - C$</p></li>
                                    <li><p>CZK - Kč</p></li>
                                    <li><p>DKK - KR</p></li>
                                    <li><p>EUR - €</p></li>

                                    <li><p>EGP - £</p></li>
                                    <li><p>GBP - £</p></li>
                                    <li><p>HUF - Ft</p></li>
                                    <li><p>HRK - kn</p></li>

                                    <li><p>HKD - HKD</p></li>
                                    <li><p>INR - ₹</p></li>
                                    <li><p>IDR - Rp</p></li>
                                    <li><p>JPY - ¥</p></li>

                                    <li><p>KES - KSh</p></li>
                                    <li><p>KWD - ك.د</p></li>
                                    <li><p>KZT - ₸</p></li>
                                    <li><p>MAD - م.د.</p></li>

                                    <li><p>MXN - Mex$</p></li>
                                    <li><p>MNT - ₮</p></li>
                                    <li><p>NGN - ₦</p></li>
                                    <li><p>NZD - NZ$</p></li>

                                    <li><p>OMR - ر.ع.</p></li>
                                    <li><p>PEN - S/.</p></li>
                                    <li><p>PHP - ₱</p></li>
                                    <li><p>PKR - ₨</p></li>

                                    <li><p>PLN - zł</p></li>
                                    <li><p>QAR - ر.ق</p></li>
                                    <li><p>RUB - ₽</p></li>
                                    <li><p>SAR - ر.س</p></li>

                                    <li><p>SEK - kr</p></li>
                                    <li><p>TWD - NT$</p></li>
                                    <li><p>THB - ฿</p></li>
                                    <li><p>TRY - ₺</p></li>

                                    <li><p>UAH - ₴</p></li>
                                    <li><p>UGX - USh</p></li>
                                    <li><p>UGX - USh</p></li>
                                    <li><p>VND - ₫</p></li>

                                </ul>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
}
