import React, { useEffect, useState } from "react";
import styles from "../styles/ourFees.module.css";
import Table from "react-bootstrap/Table";
import { API_Path } from "../const";
import { GetApi } from "../api/api-service";
import { toast } from "react-toastify";
import Loading from "../components/loader/Loading";
import NoDataFoundImg from "../components/nodatafound/NoDataFoundImg";
import Layout from "../components/Layout/Layout";

export default function OurFees() {
    const [activeTab, setActiveTab] = useState("trand");
    const [tradingFeesData, setTradingFeesData] = useState([]);
    const [CryptoCurrencyData, setCryptoCurrencyData] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleTabClick = (tabKey) => {
        setActiveTab(tabKey);
    };

    useEffect(() => {
        setLoading(true);
        getPairWiseTradingFeesData();
        getActiveCryptoCurrencyData();
    }, []);

    const getPairWiseTradingFeesData = () => {
        const getTradingFees = new Promise((resolve) => {
            resolve(GetApi(API_Path.getPairWiseTradingFees));
        });
        getTradingFees.then((res) => {
            setLoading(false);
            if (res) {
                setTradingFeesData(res?.data);
            } else {
                toast.error(res.statusText);
            }
        });
    };

    const getActiveCryptoCurrencyData = () => {
        const getCryptoCurrency = new Promise((resolve) => {
            resolve(GetApi(API_Path.getActiveCryptoCurrency));
        });
        getCryptoCurrency.then((res) => {
            setLoading(false);
            if (res) {
                setCryptoCurrencyData(res?.data);
            } else {
                toast.error(res.statusText);
            }
        });
    };

    return (
        <Layout>
            {loading && <Loading />}

            <section>
                <div className="container">
                    <div className="title_heading d-flex">
                        {/* <p>Trading /</p> */}
                        <h4 className="ps-0">Fees</h4>
                    </div>
                </div>
                <div className="common_bg_style">
                    <div className="container">
                        <div className={styles.fee_head}>
                            <h4 className="mb-0">{activeTab === "trand" ? "Trading Fees" : "Deposit & Withdrawal Fees"}</h4>
                            <ul className={`${styles.tab} ${styles.navtabs}`}>
                                <li className={styles.navitem}>
                                    <button className={`${styles.navlink} ${activeTab === "trand" ? styles.active : ""} mx-0`} aria-current="page" onClick={() => handleTabClick("trand")}>
                                        Tranding Fees
                                    </button>
                                </li>
                                <li className={styles.navitem}>
                                    <button className={`${styles.navlink} ${activeTab === "fees" ? styles.active : ""}`} onClick={() => handleTabClick("fees")}>
                                        Deposit & Withdrawal Fees
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.fee_table_body}>
                            {activeTab === "trand" ? (
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Pair</th>
                                            <th>Min Trade Amount</th>
                                            <th>Maker Fee</th>
                                            <th>Taker Fee</th>
                                        </tr>
                                    </thead>
                                    {tradingFeesData && tradingFeesData.length > 0 ? (
                                        <tbody>
                                            {tradingFeesData.map((item, i) => (
                                                <tr key={i}>
                                                    <td>
                                                        <span>
                                                            {item.FIRST}/<bdi>{item.LAST}</bdi>
                                                        </span>
                                                    </td>
                                                    <td>{parseFloat(item.MIN_TRADE_AMOUNT).toFixed(3)}</td>
                                                    <td>{parseFloat(item.MAKER_FEES).toFixed(2)}%</td>
                                                    <td>{parseFloat(item.TAKER_FEES).toFixed(2)}%</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <NoDataFoundImg />
                                    )}
                                </Table>
                            ) : (
                                <Table striped bordered hover>
                                    <thead className={styles.ourfee_tbl_head}>
                                        <tr>
                                            <th>Symbol</th>
                                            <th>Crypto/Digital Asset Name</th>
                                            <th>Minimum Withdraw</th>
                                            <th>Withdraw Fee</th>
                                        </tr>
                                    </thead>
                                    {CryptoCurrencyData && CryptoCurrencyData.length > 0 ? (
                                        <tbody className={styles.ourfee_tbl_body}>
                                            {CryptoCurrencyData.map((item, i) => (
                                                <tr key={i}>
                                                    <td className={styles.fees_flex}>
                                                        <img src={item.CoinImageUrl} alt="" />
                                                        <span>{item.COIN}</span>
                                                    </td>
                                                    <td>{item.COIN_NAME}</td>
                                                    <td>{item.MIN_WITHDRAW}</td>
                                                    <td>{item.WITHDRAW_FEES}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <NoDataFoundImg />
                                    )}
                                </Table>
                            )}
                        </div>
                        {activeTab === "fees" && (
                            <>
                                <div className={`${styles.deposit_footer} mb-2 pt-2`}>
                                    <h6>Deposit: </h6>
                                    <p>Free</p>
                                </div>
                                <div className={styles.deposit_footer}>
                                    <h6>Withdrawal: </h6>
                                    <p>Withdrawal fee are regularly adjusted according to blockchain conditions. please read more below.</p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>
        </Layout>
    );
}
