import React, { useState } from "react";
import styles from "../../styles/assetWallet.module.css";
import { Link } from "react-router-dom";
import DataTableComponents from "../data_Table/DataTableComponents";
import moment from "moment";
import NoDataFoundImg from "../nodatafound/NoDataFoundImg";
import { Table } from "react-bootstrap";

export default function AssetFunding() {
    const [loading, setLoading] = useState(false);
    const [gammingData, setGammingData] = useState([]);
    const [totleRecode, setTotleRecode] = useState(25);

    const columns = [
        { name: "Product", selector: (row) => row.name },
        { name: "Product Name", selector: (row) => row.full_name },
        { name: "Total Amount", selector: (row) => row.tol_bal },
        { name: "Accruing Interest", selector: (row) => row.ave_bal },
        { name: "Today's Subscription", selector: (row) => row.avel_bal },
        { name: "APY", selector: (row) => row.btc_val },
        { name: "Cumulative Interest", selector: (row) => row.btc_val },
        { name: "Auto Subscribe", selector: (row) => row.btc_val },
        { name: "Action", selector: (row) => row.btc_val },
    ];

    return (
        <section>
            <div className="container">
                <div className="d-flex align-items-center">
                    <div className="title_heading">
                        <h4 className="ps-0">Funding Wallet</h4>
                    </div>
                    <div className={styles.view_text_sectico}>
                        <Link to="/useraccount/funds-history-spot">History</Link>
                    </div>
                </div>
                {/* <div className={styles.asset_card}>
                    <div className={styles.card_details}>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className={styles.total_balance}>
                                <div>
                                    <span>Total Equity</span>
                                    <h4>
                                        1.43566815 <small> BTC ≈ 8,070,062.94 INR </small>
                                    </h4>
                                </div>
                            </div>
                            <div className={styles.today_pl}>
                                <div className="icon icon__src">
                                    <span className="text_underline d-block">Today’s PnL</span>
                                    <b className="green_color">+268144.30 INR</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tickets_table_body">
                    {gammingData.length > 0 ? (
                        <DataTableComponents columns={columns} userdata={gammingData} TotalRows={totleRecode} loading={loading} />
                    ) : (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Created On</th>
                                    <th>IP Address</th>
                                    <th>Location</th>
                                    <th>Activity</th>
                                    <th>Access Device</th>
                                </tr>
                            </thead>
                            <NoDataFoundImg />
                        </Table>
                    )}
                </div> */}
                 <div className={`${styles.detatils_white_box} py-4 text-center m-4`}>
                    <div className={styles.coming_soon}>
                        <h5 className="mb-2">COMING SOON!</h5>
                        <p className="mb-0">We are working on the Funding Wallet process.</p>
                    </div>
                </div>
            </div>
        </section>
    );
}
