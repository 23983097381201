import React, { useRef, useState } from 'react';
import Logo from './Logo';
import styles from '../../styles/register.module.css';
import authImg from '../../assets/images/auth_img.png';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from 'yup';
import { API_Path } from '../../const';
import { PostApi } from '../../api/api-service';
import { toast } from 'react-toastify';
import Loading from '../../components/loader/Loading';
 
export default function Login() {
    const recaptchaRef  = useRef();
    const Navigate = useNavigate();
    const [isVisible, setVisible] = useState(false);
    const [loading , setLoading] = useState(false);
    
    const handleToggle = () => {
        setVisible(!isVisible);
    };
    
    const setSubmitData = (formData , resetData) => {
        setLoading(true);
        let data = {
            EmailId: formData.email,
            Password: formData.password,
            Captcha : formData.isRecaptcha
        }
        const getCryptoCurrency = new Promise((resolve) => {
            resolve(PostApi(API_Path.userLogin , data));
        });
        getCryptoCurrency.then((res) => {
            if(res) {
                if (recaptchaRef.current)  recaptchaRef.current.reset();
                if (res.data.Isvalid === true) {
                    let url = res.data.Model.EncryptedUrl;
                    Navigate(`/login-verify/${url}` , { state: {id:url, name:formData.email }})
                    setLoading(false);
                    resetData(formData);
                    toast.success(res.data.Successes[0]);
                } else {
                    setLoading(false);
                    resetData(formData);
                    toast.error(res.data.Errors[0]);
                }
            }
        });
    }
    

    return (
        <React.Fragment>
            {loading && <Loading /> }
            <div className="container-fluid">
                <div className='row d-flex'>
                    <div className={`${styles.col_width_left} ${styles.col_width_flex}`}>
                        <div className={styles.main_box}>
                            <div className={styles.lr_logo}>
                                <Logo />
                            </div>
                            <div className='mb-4'>
                                <h3>Login</h3>
                            </div>
                            <Formik
                                initialValues={{ email: '', password: '' , isRecaptcha:''}}
                                validationSchema={Yup.object().shape({
                                    email: Yup.string().email('Email id is invalid.').required('Email id is required.'),
                                    password: Yup.string().required('Password is required.'),
                                    // .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&_^#])[A-Za-z\d@$!%*?&_^#]{8,20}$/,
                                    //     'Password must contain at least one number, one uppercase letter, one lowercase letter and one symbol i.e. @, $, !, %, *, &, _, #, ^ etc. Password length should be 8 to 16 characters.'
                                    // ),
                                    isRecaptcha: Yup.string().required('Captcha is required.'),
                                })}
                                onSubmit={(values, { resetForm }) => {
                                    setSubmitData(values , resetForm);
                                }}
                            >
                                {(runform) => {
                                    const { touched ,errors, setFieldValue} = runform;
                                    return (
                                        <Form onSubmit={runform.handleSubmit}>
                                            <div className={`${styles.inputWidth} mb-3`}>
                                                <label className={`${styles.login_label_text} d-block`}>Email</label>
                                                <div className="d-block position-relative">
                                                    <Field type="email" name="email" placeholder="example@gmail.com" className={` ${styles.login_comn_input} ${(errors.email && touched.email) &&'input-error'}`}  />
                                                    <span className={styles.login_input_icon}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                                        <path d="M6.66675 3.33337H3.33341C2.41294 3.33337 1.66675 4.07957 1.66675 5.00004V15C1.66675 15.9205 2.41294 16.6667 3.33341 16.6667H16.6667C17.5872 16.6667 18.3334 15.9205 18.3334 15V5.00004C18.3334 4.07957 17.5872 3.33337 16.6667 3.33337H13.3334" stroke="#404040" strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M18.3334 5.83337L10.8584 10.5834C10.6011 10.7446 10.3037 10.83 10.0001 10.83C9.69648 10.83 9.39902 10.7446 9.14175 10.5834L1.66675 5.83337" stroke="#404040" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <ErrorMessage name="email" component="div" className="error-message" />
                                            </div>
                                            <div className={`${styles.inputWidth} mb-3`}>
                                                <label className={`${styles.login_label_text} d-block`}>Password</label>
                                                <div className="d-block position-relative">
                                                    <Field type={!isVisible ? "password" : "text"} name="password" placeholder="∗∗∗∗∗∗∗∗" className={` ${styles.login_comn_input} ${(errors.password && touched.password) && 'input-error'}`} maxLength="20" />
                                                    <span className={styles.login_input_icon} onClick={handleToggle}>
                                                        {isVisible ? 
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                                            <path d="M6.24984 17.4999C8.78114 17.4999 10.8332 15.4479 10.8332 12.9166C10.8332 10.3853 8.78114 8.33325 6.24984 8.33325C3.71853 8.33325 1.6665 10.3853 1.6665 12.9166C1.6665 15.4479 3.71853 17.4999 6.24984 17.4999Z" stroke="#404040" strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M17.5 1.66675L15.1667 4.00008M9.5 9.66675L13.6667 5.50008" stroke="#404040" strokeLinecap="round" strokeLinejoin="round"/><path d="M12.9165 6.24992L15.4165 8.74992L18.3332 5.83325L15.8332 3.33325" stroke="#404040" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        :
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                                            <path d="M8.54167 9.16675H7.08333H4.16667C3.24619 9.16675 2.5 9.91294 2.5 10.8334V16.6667C2.5 17.5872 3.24619 18.3334 4.16667 18.3334H15.8333C16.7538 18.3334 17.5 17.5872 17.5 16.6667V10.8334C17.5 9.91294 16.7538 9.16675 15.8333 9.16675H12.9167H11.4583" stroke="#404040" strokeLinecap="round" strokeLinejoin="round"/><path d="M5.83301 9.16675V5.83341C5.83301 4.72835 6.27199 3.66854 7.0534 2.88714C7.8348 2.10573 8.89461 1.66675 9.99967 1.66675C11.1047 1.66675 12.1646 2.10573 12.946 2.88714C13.7274 3.66854 14.1663 4.72835 14.1663 5.83341V9.16675" stroke="#404040" strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                        }
                                                    </span>
                                                </div>
                                                <ErrorMessage name="password" component="div" className="error-message" />
                                            </div>
                                            <div className={`${styles.redirect_page_style} mt-4 cursor-pointer`}>
                                                <Link to='/resendemailverify' >Email Verify?</Link>
                                            </div>
                                            <div className={`${styles.redirect_page_style} mt-3 cursor-pointer`}>
                                                <Link to='/forgotpassword' >Forgot Password?</Link>
                                            </div>
                                            <div className='mt-4'>
                                                <ReCAPTCHA
                                                    ref={recaptchaRef}
                                                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                                    onChange={(value) => setFieldValue('isRecaptcha' , value )}
                                                    name="isRecaptcha"
                                                />
                                                <ErrorMessage name="isRecaptcha" component="div" className="error-message" />
                                            </div>
                                            <div className={styles.loginBtn}>
                                                <button type='submit' className='button primary_button w-100'>
                                                    Login
                                                </button>
                                            </div>
                                            <p className={`${styles.login_bottomtext} text-start`}>New User? <Link to="/register"> Register</Link></p>
                                        </Form>
                                    )}}
                            </Formik>
                        </div>
                    </div>
                    <div className={`${styles.col_width_right} p-0 d-lg-block d-none`}>
                        <div className={`${styles.login_side_img} position-relative`}>
                            <img src={authImg} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
