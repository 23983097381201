import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import io from "socket.io-client";
import { socketUrl } from "../../const";

export const parseFloatAmt = (amt, size) => {
    return parseFloat(amt).toFixed(size);
};

export const socket = io.connect(socketUrl);
export const socketEmit = () => socket.emit("SendMarket");

const currentDate = new Date();
export const maxDateString = currentDate.toISOString().split("T")[0];

export var settings = {
    cssEase: "linear",
    autoplay: true,
    infinite: true,
    autoplaySpeed: 0,
    dots: false,
    arrows: false,
    speed: 5000,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 5,
            },
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};

export const formAttr = (form, field) => ({
    onBlur: form.handleBlur,
    onChange: form.handleChange,
    value: form.values[field],
});

export const errorContainer = (form, field) => {
    return form.touched[field] && form.errors[field] ? <span className="error-message">{form.errors[field]}</span> : null;
};

export const currentYear = new Date().getFullYear();

export const exportTableToExcel = (data, name, columns) => {
    const excelData = data.map((row) => {
        const rowData = {};
        columns.forEach((column) => {
            rowData[column.name] = column.selector(row);
        });
        return rowData;
    });
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
    saveAs(blob, `${name}.xlsx`);
};

export const exportTableToPDF = (data, row, name, title) => {
    var doc = new jsPDF("p", "pt", "letter");
    var y = 20;
    doc.setLineWidth(2);
    doc.text(200, (y = y + 30), title);
    doc.autoTable({
        head: [row.map((header) => header.name)], // Header row
        body: data.map((rowData) => Object.values(rowData)), // Data rows
        startY: 70,
        theme: "grid",
        columnStyles: {
            0: {
                // halign: "left",
                tableWidth: 100,
            },
            1: {
                tableWidth: 100,
            },
            2: {
                tableWidth: 100,
            },
            3: {
                tableWidth: 100,
            },
        },
        headStyles: {
            fillColor: [247, 247, 247],
            textColor: [33, 33, 33],
            fontStyle: "bold",
            lineWidth: 1,
            lineColor: [0, 0, 0, 0.12],
        },
    });
    doc.save(name);
};

export const exportHandlePrint = (data, columns) => {
    const newTab = window.open("", "_blank");
    let tableContent =
        "<h2>Supports</h2><table style='width: 100%; border:1px solid #8f8f8f;border-spacing:inherit;font-family:Montserrat, sans-serif !important'><thead style='text-align: left;'><tr style='height:36px;font-size:15px;font-weight:500;'>";
    columns.forEach((column) => (tableContent += `<th style='font-size:15px;font-weight:600;padding:0 12px;border-bottom:1px solid #8f8f8f;'>${column.name}</th>`));

    tableContent += "</tr></thead><tbody>";
    data.forEach((result) => {
        tableContent += "<tr style='height:36px;'>";
        columns.forEach((column) => {
            column.selector(result, column.selector(result));
            tableContent += `<td style='font-size:14px;padding:0 12px;border-bottom:1px solid #8f8f8f;'>${column.selector(result)}</td>`;
        });
        tableContent += "</tr>";
    });

    tableContent += "</tbody></table>";
    newTab.document.write(tableContent);
    newTab.document.close();
    newTab.print();
};

export const GetURLParameterIndexOfLastSlashValue = () => {
    try {
        let value = null;
        const sPageURL = window.location.href;
        const indexOfLastSlash = sPageURL.lastIndexOf("/");

        if (indexOfLastSlash > 0 && sPageURL.length - 1 !== indexOfLastSlash) {
            value = sPageURL.substring(indexOfLastSlash + 1);
        }

        return value;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const allCountrys = [
    { value: "AF", name: "Afghanistan", number: "93", image: "af.png" },
    { value: "AX", name: "Aland Islands", number: "358", image: "ax.png" },
    { value: "AL", name: "Albania", number: "008", image: "al.png" },
    { value: "DZ", name: "Algeria", number: "213", image: "dz.png" },
    { value: "AS", name: "American Samoa", number: "1684", image: "as.png" },
    { value: "AD", name: "Andorra", number: "376", image: "ad.png" },
    { value: "AO", name: "Angola", number: "244", image: "ao.png" },
    { value: "AI", name: "Anguilla", number: "1264", image: "ai.png" },
    { value: "AQ", name: "Antarctica", number: "672", image: "aq.png" },
    { value: "AG", name: "Antigua and Barbuda", number: "1268", image: "aq.png" },
    { value: "AR", name: "Argentina", number: "54", image: "ar.png" },
    { value: "AM", name: "Armenia", number: "374", image: "am.png" },
    { value: "AW", name: "Aruba", number: "297", image: "aw.png" },
    { value: "AU", name: "Australia", number: "61", image: "au.png" },
    { value: "AT", name: "Austria", number: "43", image: "at.png" },
    { value: "AZ", name: "Azerbaijan", number: "994", image: "az.png" },
    { value: "BS", name: "Bahamas", number: "1242", image: "bs.png" },
    { value: "BH", name: "Bahrain", number: "973", image: "bh.png" },
    { value: "BD", name: "Bangladesh", number: "880", image: "bd.png" },
    // { value: "BB", name: "Barbados", number: "052" },
    { value: "BY", name: "Belarus", number: "375", image: "by.png" },
    { value: "BE", name: "Belgium", number: "32", image: "be.png" },
    { value: "BZ", name: "Belize", number: "501", image: "bz.png" },
    { value: "BJ", name: "Benin", number: "229", image: "bj.png" },
    { value: "BM", name: "Bermuda", number: "1441", image: "bm.png" },
    { value: "BT", name: "Bhutan", number: "975", image: "bt.png" },
    { value: "BO", name: "Bolivia", number: "591", image: "bo.png" },
    // { value: "BQ", name: "Bonaire, Sint Eustatius and Saba", number: "535" , image: "bd.png"},
    { value: "BA", name: "Bosnia and Herzegovina", number: "387", image: "ba.png" },
    // { value: "BW", name: "Botswana", number: "072" , image: "bd.png"},
    { value: "BV", name: "Bouvet Island", number: "47", image: "bv.png" },
    { value: "BR", name: "Brazil", number: "55", image: "br.png" },
    { value: "IO", name: "British Indian Ocean Territory", number: "246", image: "io.png" },
    { value: "BN", name: "Brunei Darussalam", number: "673", image: "bn.png" },
    { value: "BG", name: "Bulgaria", number: "359", image: "bg.png" },
    // { value: "BF", name: "Burkina Faso", number: "854" , image: "bd.png"},
    { value: "BI", name: "Burundi", number: "257", image: "bi.png" },
    { value: "CV", name: "Cape Verde", number: "238", image: "cv.png" },
    // { value: "KH", name: "Cambodia", number: "116" },
    { value: "CM", name: "Cameroon", number: "237", image: "cm.png" },
    { value: "CA", name: "Canada", number: "1", image: "ca.png" },
    // { value: "KY", name: "Cayman Islands", number: "136" , image: "cm.png"},
    { value: "CF", name: "Central African Republic", number: "236", image: "cf.png" },
    { value: "TD", name: "Chad", number: "235", image: "td.png" },
    { value: "CL", name: "Chile", number: "56", image: "cl.png" },
    { value: "CN", name: "China", number: "86", image: "cn.png" },
    { value: "CX", name: "Christmas Island", number: "61", image: "cx.png" },
    { value: "CC", name: "Cocos (Keeling) Islands", number: "61", image: "cc.png" },
    { value: "CO", name: "Colombia", number: "57", image: "co.png" },
    { value: "KM", name: "Comoros", number: "269", image: "km.png" },
    { value: "CD", name: "Congo (the Democratic Republic of the)", number: "243", image: "cd.png" },
    { value: "CG", name: "Congo", number: "242", image: "cg.png" },
    { value: "CK", name: "Cook Islands", number: "682", image: "ck.png" },
    { value: "CR", name: "Costa Rica", number: "506", image: "cr.png" },
    { value: "HR", name: "Croatia", number: "385", image: "hr.png" },
    { value: "CU", name: "Cuba", number: "53", image: "cu.png" },
    { value: "CW", name: "Curaçao", number: "531", image: "cm.png" },
    { value: "CY", name: "Cyprus", number: "357", image: "cy.png" },
    { value: "CZ", name: "Czechia", number: "420", image: "cz.png" },
    { value: "CI", name: "Côte d'Ivoire", number: "225", image: "ci.png" },
    { value: "DK", name: "Denmark", number: "45", image: "dk.png" },
    { value: "DJ", name: "Djibouti", number: "253", image: "dj.png" },
    { value: "DM", name: "Dominica", number: "1767", image: "dm.png" },
    { value: "DO", name: "Dominican Republic", number: "1849", image: "do.png" },
    { value: "EC", name: "Ecuador", number: "563", image: "ec.png" },
    { value: "EG", name: "Egypt", number: "20", image: "eg.png" },
    { value: "SV", name: "El Salvador", number: "503", image: "sv.png" },
    { value: "GQ", name: "Equatorial Guinea", number: "240", image: "gq.png" },
    { value: "ER", name: "Eritrea", number: "291", image: "er.png" },
    { value: "EE", name: "Estonia", number: "372", image: "ee.png" },
    // { value: "SZ", name: "Eswatini", number: "748", image: "dk.png" },
    { value: "ET", name: "Ethiopia", number: "251", image: "et.png" },
    { value: "FK", name: "Falkland Islands [Malvinas]", number: "500", image: "fk.png" },
    { value: "FO", name: "Faroe Islands", number: "298", image: "fo.png" },
    { value: "FJ", name: "Fiji", number: "679", image: "fj.png" },
    { value: "FI", name: "Finland", number: "358", image: "fi.png" },
    { value: "FR", name: "France", number: "33", image: "fr.png" },
    { value: "GF", name: "French Guiana", number: "594", image: "gf.png" },
    { value: "PF", name: "French Polynesia", number: "689", image: "pf.png" },
    { value: "TF", name: "French Southern Territories", number: "262", image: "tf.png" },
    { value: "GA", name: "Gabon", number: "241", image: "ga.png" },
    { value: "GM", name: "Gambia", number: "220", image: "gm.png" },
    { value: "GE", name: "Georgia", number: "995", image: "ge.png" },
    { value: "DE", name: "Germany", number: "49", image: "de.png" },
    // { value: "GH", name: "Ghana", number: "350" , image: "tf.png"},
    { value: "GI", name: "Gibraltar", number: "350", image: "gi.png" },
    { value: "GR", name: "Greece", number: "30", image: "gr.png" },
    { value: "GL", name: "Greenland", number: "299", image: "gl.png" },
    { value: "GD", name: "Grenada", number: "1473", image: "gd.png" },
    { value: "GP", name: "Guadeloupe", number: "590", image: "gp.png" },
    { value: "GU", name: "Guam", number: "1671", image: "gu.png" },
    { value: "GT", name: "Guatemala", number: "502", image: "gt.png" },
    { value: "GG", name: "Guernsey", number: "44", image: "gg.png" },
    { value: "GN", name: "Guinea", number: "224", image: "gn.png" },
    { value: "GW", name: "Guinea-Bissau", number: "245", image: "gw.png" },
    { value: "GY", name: "Guyana", number: "592", image: "gy.png" },
    { value: "HT", name: "Haiti", number: "509", image: "ht.png" },
    { value: "HM", name: "Heard Island and McDonald Islands", number: "0", image: "hm.png" },
    { value: "VA", name: "Holy See", number: "379", image: "va.png" },
    { value: "HN", name: "Honduras", number: "504", image: "hn.png" },
    { value: "HK", name: "Hong Kong", number: "852", image: "hk.png" },
    { value: "HU", name: "Hungary", number: "36", image: "hu.png" },
    { value: "IS", name: "Iceland", number: "354", image: "is.png" },
    { value: "IN", name: "India", number: "91", image: "in.png" },
    { value: "ID", name: "Indonesia", number: "62", image: "id.png" },
    // { value: "IR", name: "Iran (Islamic Republic of)", number: "364" , image: "gy.png"},
    { value: "IQ", name: "Iraq", number: "964", image: "iq.png" },
    { value: "IE", name: "Ireland", number: "353", image: "ie.png" },
    { value: "IM", name: "Isle of Man", number: "44", image: "im.png" },
    { value: "IL", name: "Israel", number: "972", image: "il.png" },
    { value: "IT", name: "Italy", number: "39", image: "it.png" },
    // { value: "JM", name: "Jamaica", number: "388", image: "it.png" },
    { value: "JP", name: "Japan", number: "81", image: "jp.png" },
    { value: "JE", name: "Jersey", number: "44", image: "je.png" },
    { value: "JO", name: "Jordan", number: "962", image: "jo.png" },
    { value: "KZ", name: "Kazakhstan", number: "7", image: "kz.png" },
    { value: "KE", name: "Kenya", number: "254", image: "ke.png" },
    { value: "KI", name: "Kiribati", number: "868", image: "ki.png" },
    // { value: "KP", name: "Korea (the Democratic People's Republic of)", number: "82" , image: "kz.png"},
    { value: "KR", name: "Korea", number: "82", image: "kr.png" },
    { value: "xk", name: "Kosovo", number: "383", image: "xk.png" },
    { value: "KW", name: "Kuwait", number: "965", image: "kw.png" },
    { value: "KG", name: "Kyrgyzstan", number: "996", image: "kg.png" },
    { value: "LA", name: "Lao People's Democratic Republic", number: "856", image: "la.png" },
    { value: "LV", name: "Latvia", number: "371", image: "lv.png" },
    { value: "LB", name: "Lebanon", number: "961", image: "lb.png" },
    { value: "LS", name: "Lesotho", number: "266", image: "ls.png" },
    { value: "LR", name: "Liberia", number: "231", image: "lr.png" },
    { value: "LY", name: "Libya", number: "218", image: "ly.png" },
    { value: "LI", name: "Liechtenstein", number: "423", image: "li.png" },
    { value: "LT", name: "Lithuania", number: "370", image: "lt.png" },
    { value: "LU", name: "Luxembourg", number: "352", image: "lu.png" },
    { value: "MO", name: "Macao", number: "853", image: "mo.png" },
    { value: "MG", name: "Madagascar", number: "261", image: "mg.png" },
    { value: "MW", name: "Malawi", number: "265", image: "mw.png" },
    { value: "MY", name: "Malaysia", number: "60", image: "my.png" },
    { value: "MV", name: "Maldives", number: "960", image: "mv.png" },
    { value: "ML", name: "Mali", number: "223", image: "ml.png" },
    { value: "MT", name: "Malta", number: "356", image: "mt.png" },
    { value: "MH", name: "Marshall Islands", number: "692", image: "mh.png" },
    { value: "MQ", name: "Martinique", number: "596", image: "mq.png" },
    { value: "MR", name: "Mauritania", number: "222", image: "mr.png" },
    // { value: "MU", name: "Mauritius", number: "262" , image: "mo.png"},
    { value: "YT", name: "Mayotte", number: "262", image: "yt.png" },
    { value: "MX", name: "Mexico", number: "52", image: "mx.png" },
    { value: "FM", name: "Micronesia", number: "691", image: "fm.png" },
    { value: "MD", name: "Moldova", number: "373", image: "md.png" },
    { value: "MC", name: "Monaco", number: "377", image: "mc.png" },
    { value: "MN", name: "Mongolia", number: "976", image: "mn.png" },
    { value: "ME", name: "Montenegro", number: "382", image: "me.png" },
    { value: "MS", name: "Montserrat", number: "1664", image: "ms.png" },
    // { value: "MA", name: "Morocco", number: "258" , image: "mo.png"},
    { value: "MZ", name: "Mozambique", number: "258", image: "mz.png" },
    // { value: "MM", name: "Myanmar", number: "104" , image: "mo.png"},
    { value: "NA", name: "Namibia", number: "264", image: "na.png" },
    { value: "NR", name: "Nauru", number: "674", image: "nr.png" },
    { value: "NP", name: "Nepal", number: "977", image: "np.png" },
    { value: "NL", name: "Netherlands", number: "31", image: "nl.png" },
    { value: "an", name: "Netherlands Antilles", number: "599", image: "an.png" },
    { value: "NC", name: "New Caledonia", number: "687", image: "nc.png" },
    { value: "NZ", name: "New Zealand", number: "64", image: "nz.png" },
    // { value: "NI", name: "Nicaragua", number: "558" , image: "ni.png"},
    { value: "NE", name: "Niger", number: "227", image: "ne.png" },
    { value: "NG", name: "Nigeria", number: "234", image: "ng.png" },
    { value: "NU", name: "Niue", number: "683", image: "nu.png" },
    { value: "NF", name: "Norfolk Island", number: "672", image: "nf.png" },
    { value: "MP", name: "Northern Mariana Islands", number: "1670", image: "mp.png" },
    { value: "NO", name: "Norway", number: "47", image: "no.png" },
    { value: "OM", name: "Oman", number: "968", image: "om.png" },
    // { value: "PK", name: "Pakistan", number: "586" , image: "om.png"},
    { value: "PW", name: "Palau", number: "680", image: "pw.png" },
    { value: "PS", name: "Palestine, State of", number: "970", image: "ps.png" },
    // { value: "PA", name: "Panama", number: "675" , image: "pa.png"},
    { value: "PG", name: "Papua New Guinea", number: "675", image: "pg.png" },
    { value: "PY", name: "Paraguay", number: "595", image: "py.png" },
    { value: "PE", name: "Peru", number: "51", image: "pe.png" },
    { value: "PH", name: "Philippines", number: "63", image: "ph.png" },
    { value: "PN", name: "Pitcairn", number: "64", image: "pn.png" },
    { value: "PL", name: "Poland", number: "48", image: "pl.png" },
    { value: "PT", name: "Portugal", number: "351", image: "pt.png" },
    { value: "PR", name: "Puerto Rico", number: "1939", image: "pr.png" },
    { value: "QA", name: "Qatar", number: "974", image: "qa.png" },
    { value: "MK", name: "Republic of North Macedonia", number: "389", image: "mk.png" },
    { value: "RO", name: "Romania", number: "40", image: "ro.png" },
    { value: "RU", name: "Russian Federation", number: "7", image: "ru.png" },
    { value: "RW", name: "Rwanda", number: "250", image: "rw.png" },
    { value: "RE", name: "Réunion", number: "262", image: "re.png" },
    { value: "BL", name: "Saint Barthélemy", number: "590", image: "bl.png" },
    { value: "SH", name: "Saint Helena, Ascension and Tristan da Cunha", number: "290", image: "sh.png" },
    { value: "KN", name: "Saint Kitts and Nevis", number: "1869", image: "kn.png" },
    { value: "LC", name: "Saint Lucia", number: "1758", image: "lc.png" },
    { value: "MF", name: "Saint Martin", number: "590", image: "mf.png" },
    { value: "PM", name: "Saint Pierre and Miquelon", number: "508", image: "pm.png" },
    { value: "VC", name: "Saint Vincent and the Grenadines", number: "1784", image: "vc.png" },
    { value: "WS", name: "Samoa", number: "685", image: "ws.png" },
    { value: "SM", name: "San Marino", number: "378", image: "sm.png" },
    { value: "ST", name: "Sao Tome and Principe", number: "239", image: "st.png" },
    { value: "SA", name: "Saudi Arabia", number: "966", image: "sa.png" },
    // { value: "SN", name: "Senegal", number: "686" , image: "sn.png"},
    { value: "RS", name: "Serbia", number: "381", image: "rs.png" },
    { value: "SC", name: "Seychelles", number: "248", image: "sc.png" },
    { value: "SL", name: "Sierra Leone", number: "232", image: "sl.png" },
    { value: "SG", name: "Singapore", number: "65", image: "sg.png" },
    // { value: "SX", name: "Sint Maarten (Dutch part)", number: "534" , image: "sx.png"},
    { value: "SK", name: "Slovakia", number: "421", image: "sk.png" },
    { value: "SI", name: "Slovenia", number: "386", image: "si.png" },
    { value: "SB", name: "Solomon Islands", number: "677", image: "sb.png" },
    { value: "SO", name: "Somalia", number: "252", image: "so.png" },
    { value: "ZA", name: "South Africa", number: "27", image: "za.png" },
    { value: "GS", name: "South Georgia and the South Sandwich Islands", number: "500", image: "gs.png" },
    { value: "SS", name: "South Sudan", number: "211", image: "ss.png" },
    { value: "ES", name: "Spain", number: "34", image: "es.png" },
    { value: "LK", name: "Sri Lanka", number: "94", image: "lk.png" },
    { value: "SD", name: "Sudan", number: "249", image: "sd.png" },
    { value: "SR", name: "Suriname", number: "597", image: "sr.png" },
    { value: "SJ", name: "Svalbard and Jan Mayen", number: "47", image: "sj.png" },
    { value: "SE", name: "Sweden", number: "46", image: "se.png" },
    { value: "sz", name: "Swaziland", number: "268", image: "sz.png" },
    { value: "ch", name: "Switzerland", number: "41", image: "ch.png" },
    { value: "TW", name: "Taiwan", number: "886", image: "tw.png" },
    { value: "TJ", name: "Tajikistan", number: "992", image: "tj.png" },
    { value: "TZ", name: "Tanzania, United Republic of", number: "255", image: "tz.png" },
    { value: "TH", name: "Thailand", number: "66", image: "th.png" },
    { value: "TL", name: "Timor-Leste", number: "670", image: "tl.png" },
    { value: "TG", name: "Togo", number: "228", image: "tg.png" },
    { value: "TK", name: "Tokelau", number: "690", image: "tk.png" },
    { value: "TO", name: "Tonga", number: "676", image: "to.png" },
    { value: "TT", name: "Trinidad and Tobago", number: "1868", image: "tt.png" },
    { value: "TN", name: "Tunisia", number: "216", image: "tn.png" },
    { value: "TR", name: "Turkey", number: "90", image: "tr.png" },
    { value: "TM", name: "Turkmenistan", number: "993", image: "tm.png" },
    { value: "TC", name: "Turks and Caicos Islands", number: "1649", image: "tc.png" },
    { value: "TV", name: "Tuvalu", number: "688", image: "tv.png" },
    { value: "UG", name: "Uganda", number: "800", image: "tw.png" },
    { value: "UA", name: "Ukraine", number: "380", image: "ua.png" },
    { value: "AE", name: "United Arab Emirates", number: "971", image: "ae.png" },
    { value: "GB", name: "United Kingdom of Great Britain and Northern Ireland", number: "44", image: "gb.png" },
    { value: "US", name: "United States of America", number: "1", image: "us.png" },
    { value: "UY", name: "Uruguay", number: "598", image: "uy.png" },
    { value: "UZ", name: "Uzbekistan", number: "998", image: "uz.png" },
    { value: "VU", name: "Vanuatu", number: "678", image: "vu.png" },
    { value: "VE", name: "Venezuela (Bolivarian Republic of)", number: "58", image: "ve.png" },
    { value: "VN", name: "Viet Nam", number: "84", image: "vn.png" },
    { value: "VG", name: "Virgin Islands (British)", number: "1284", image: "vg.png" },
    { value: "VI", name: "Virgin Islands (U.S.)", number: "1340", image: "vi.png" },
    { value: "WF", name: "Wallis and Futuna", number: "681", image: "wf.png" },
    { value: "ZM", name: "Zambia", number: "260", image: "zm.png" },
];

// ------ STRING TO NUMBER CONVERT ------
const isStringNullOrWhiteSpace = (str) => {
    return !str || str.length === 0 || /^\s*$/.test(str);
};

const isNumeric = (str) => {
    return !isNaN(str);
};
export const expoStrToNumStr = (value) => {
    var v_in_num = value.toString();
    if (isStringNullOrWhiteSpace(v_in_num) || !isNumeric(v_in_num)) {
        return "";
    }
    v_in_num = Number(v_in_num).toString();
    var sign = "";
    if (v_in_num.substring(0, 1) === "-") {
        sign = "-";
        v_in_num = v_in_num.substring(1, v_in_num.length);
    }
    v_in_num = v_in_num.replace(/^\s+|\s+$/g, "");
    var num_ar = v_in_num.split("e");
    var d = Number(num_ar[0]);
    var e = Number(num_ar[1]);

    var d_str = d.toString();
    var e_abs = Math.abs(e);
    var num_len = d_str.length;
    var l_side = "";
    var r_side = "";
    if (d_str.indexOf(".") > -1) {
        var d_ar = d_str.split(".");
        l_side = d_ar[0];
        if (l_side === "0") {
            l_side = "";
        }
        r_side = d_ar[1];
    } else {
        l_side = d_str;
    }
    var l_side_len = l_side.length;
    var r_side_len = r_side.length;
    var comb_sides = l_side + "" + r_side;
    var comb_sides_len = comb_sides.length;
    var both_sides = "";
    var num_places = 0;
    if (e === 0 || isNaN(e)) {
        both_sides = d;
    } else if (e > 0) {
        if (e_abs >= r_side_len) {
            num_len = e_abs + l_side_len;
            num_places = num_len + l_side_len;
        } else {
            num_places = Number(e_abs) + Number(1);
        }
        for (var i = 1; i <= num_len; i++) {
            if (i > comb_sides_len) {
                both_sides += "0";
            } else {
                both_sides += comb_sides.substring(i - 1, i);
            }
            if (i === num_places) {
                both_sides += ".";
            }
        }
    } else {
        num_len = e_abs + r_side_len;
        num_places = num_len + 1;
        for (var j = 1; j <= num_len; j++) {
            if (j > comb_sides_len) {
                both_sides = "0" + both_sides;
            } else {
                both_sides += comb_sides.substring(j - 1, j);
            }
        }
        both_sides = "0." + both_sides;
    }
    return "" + sign + "" + both_sides;
};

export const exportDropdown = () => {
    const selectedAll = document.querySelectorAll(".custom-list-dropdown");

    selectedAll.forEach((selected) => {
        const optionsContainer = selected.querySelector(".list-item-dropdown");
        const optionsList = optionsContainer.querySelectorAll("li");
        const arrow = selected.querySelector(".ri-arrow-down-s-line");
        const display = selected.querySelector(".selected-display");

        selected.addEventListener("click", () => {
            const isActive = selected.classList.contains("active");
            // Close all dropdowns if clicking on an already active dropdown
            if (isActive) {
                handleDropdown(selected, arrow, false);
            } else {
                const currentActive = document.querySelector(".custom-list-dropdown.active");
                if (currentActive) {
                    const currentArrow = currentActive.querySelector(".ri-arrow-down-s-line");
                    handleDropdown(currentActive, currentArrow, false);
                }
                handleDropdown(selected, arrow, true);
            }
        });

        optionsList.forEach((option) => {
            option.addEventListener("click", () => {
                display.innerHTML = option.innerHTML;
                handleDropdown(selected, arrow, false); // Close the dropdown after selection
                if ((display.innerHTML = option.innerHTML)) handleDropdown(selected, arrow, true);
            });
        });
    });

    const handleDropdown = (dropdown, arrow, open) => {
        if (open) {
            arrow.classList.add("rotated");
            dropdown.classList.add("active");
        } else {
            arrow.classList.remove("rotated");
            dropdown.classList.remove("active");
        }
    };
};
