import React, { useEffect, useState } from "react";
import styles from "../styles/tradingrules.module.css";
import Table from "react-bootstrap/Table";
import { GetApi } from "../api/api-service";
import { API_Path } from "../const";
import { toast } from "react-toastify";
import Searchbar from "../components/searchbar/Searchbar";
import Loading from "../components/loader/Loading";
import NoDataFoundImg from "../components/nodatafound/NoDataFoundImg";
import Layout from "../components/Layout/Layout";

export default function TradingRules() {
    const [activeTab, setActiveTab] = useState("btc");
    const [coinData, setCoinData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const handleTabClick = (tabKey) => {
        setActiveTab(tabKey);
    };

    useEffect(() => {
        setLoading(true);
        GetMarketMainData();
    }, []);

    const GetMarketMainData = () => {
        const getMarketMain = new Promise((resolve) => {
            resolve(GetApi(API_Path.GetMarketMainCoin));
        });
        getMarketMain.then((res) => {
            setLoading(false);
            if (res) {
                if (res.status === 200) {
                    setCoinData(res?.data);
                    setFilteredData(res.data);
                } else {
                    toast.error(res.statusText);
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    };
    const filteredCoinData = coinData.filter((item) => (item.LAST === "BTC" && activeTab === "btc") || (item.LAST === "ETH" && activeTab === "eth") || (item.LAST === "USDT" && activeTab === "usdt") ? item.PAIRNAME.toLowerCase().includes(searchQuery.toLowerCase()) : "");

    return (
        <Layout>
            {loading && <Loading />}
            <section className={`${styles.trading_section} pt-3`}>
                <div className="container">
                    <div className="title_heading d-flex">
                        <p>Trading /</p>
                        <h4>Trading Rules</h4>
                    </div>
                </div>
                <div className={`${styles.trading_rules_body} common_bg_style`}>
                    <div className={`container`}>
                        <div className={styles.trading_head}>
                            <ul className={`${styles.tab} ${styles.navtabs}`}>
                                <li className={styles.navitem}>
                                    <button className={`${styles.navlink} ${activeTab === "btc" && styles.active} ms-0`} aria-current="page" onClick={() => handleTabClick("btc")}>
                                        BTC Markets
                                    </button>
                                </li>
                                <li className={styles.navitem}>
                                    <button className={`${styles.navlink} ${activeTab === "eth" && styles.active}`} onClick={() => handleTabClick("eth")}>
                                        ETH Markets
                                    </button>
                                </li>
                                <li className={styles.navitem}>
                                    <button className={`${styles.navlink} ${activeTab === "usdt" && styles.active}`} onClick={() => handleTabClick("usdt")}>
                                        USDT Markets
                                    </button>
                                </li>
                            </ul>
                            <div className={`${styles.symbol_search_box} tradingSearchBox position-relative`}>
                                <div className="form-group">
                                    <div className="searchbar_style">
                                        <input type="search" placeholder="Search" className="form-control" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18" fill="none">
                                            <path d="M15.7469 15.7498L12.4844 12.4873" stroke="#404040" strokeLinecap="round" strokeLinejoin="round" />
                                            <path
                                                d="M8.25 14.25C11.5637 14.25 14.25 11.5637 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25Z"
                                                stroke="#404040"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.trading_table_body}>
                            <Table striped bordered hover className={styles.scroll}>
                                <thead>
                                    <tr>
                                        <th>Pair</th>
                                        <th>Min Order Size</th>
                                        <th>Max Order Size</th>
                                        <th>Min Order</th>
                                    </tr>
                                </thead>
                                {filteredCoinData && filteredCoinData.length > 0 ? (
                                    <tbody>
                                        {filteredCoinData.map(
                                            (item, i) =>
                                                ((item.LAST === "BTC" && activeTab === "btc") || (item.LAST === "ETH" && activeTab === "eth") || (item.LAST === "USDT" && activeTab === "usdt")) && (
                                                    <tr key={i}>
                                                        <td>{item.FIRST}</td>
                                                        <td>{parseInt(item.min_lot_size).toFixed(2)}</td>
                                                        <td>{parseInt(item.max_lot_size).toFixed(2)}</td>
                                                        <td>{parseInt(item.MIN_ORDER).toFixed(2)}</td>
                                                    </tr>
                                                )
                                        )}
                                    </tbody>
                                ) : (
                                    <NoDataFoundImg />
                                )}
                            </Table>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
