import React, { useEffect, useState } from "react";
import styles from "../../styles/funds.module.css";
import Table from "react-bootstrap/esm/Table";
import noImgAvl from "../../assets/images/no_image_available.png";
import { Link, useLocation } from "react-router-dom";
import { Dropdown, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik } from "formik";
import * as Yup from "yup";
import { errorContainer, expoStrToNumStr, formAttr } from "../common/CommonFun";
import { GetTokenApi, PostTokenApi } from "../../api/api-service";
import { API_Path } from "../../const";
import Loading from "../loader/Loading";
import NoDataFoundImg from "../nodatafound/NoDataFoundImg";
import QRCode from "react-qr-code";
import { minus, sum } from "../common/SafeMathService";

var isStringNullOrWhiteSpace = (str) => {
    return str === undefined || str === null || typeof str !== "string" || str.match(/^ *$/) !== null;
};

let pairsPriceData = [];
let userDailyWithdrawDisplayData = { limit: "--", total: "--" };
let withdrawConvertCoinSettingData = {};
let coinsData = [];

let UserDailyWithdrawData = {
    coin: "",
    limit: "",
    total: "",
    remaining: "",
    isValid: false,
};

let userDailyWithdrawConvertedData = { coin: "", limit: "", remaining: "" };

let baseConvertCoinSetting = {};

let coinChain = [];

export default function AssetFunds(props) {
    const defaultZero = "0.00";
    const notificationList = [];
    let dailyWithdrawLimitSetting = {};
    const stateCurrentName = "walletspotcrypto";
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("crypto");
    const [hideBtn, setHideBtn] = useState(false);
    const [coinData, setCoinData] = useState({});
    const [showDepositModal, setShowDepositModal] = useState(false);
    const [showWithdrawModal, setShowWithdrawModal] = useState({ flag: false, type: "cryptoNetwork" });
    const [selectChain, setSelectChain] = useState();
    const [allCoinData, setAllCoinData] = useState([]);
    const [dailyDrawsumData, setDailyDrawsumData] = useState();
    const [searchQuery, setSearchQuery] = useState("");
    // const [coinChain, setCoinChain] = useState([]);
    const [addressData, setAddressData] = useState({});
    const [withdrawChainData, setWithdrawChainData] = useState({});
    const [estimateSetting, setEstimateSetting] = useState({
        coin: "",
        total: 0,
        isRunningTotalConverted: false,
    });
    const [userIsKyc, setUserIsKyc] = useState(null);
    const [estimateDisplay, seteEtimateDisplay] = useState({ total: "--", totalConverted: "--" });
    const [coinBalance, setCoinBalance] = useState([]);
    const [cryptoCoins, setCryptoCoins] = useState([]);
    const [userDailyWithdrawConvertedDisplay, setuserDailyWithdrawConvertedDisplay] = useState({ limit: "--", total: "--" });
    const [withdrawModel, setWithdrawModel] = useState({
        toAddress: "",
        memo: "",
        amount: "",
        totalAmount: 0,
    });

    useEffect(() => {
        // const fetchPriceAndRepeat = async () => {
        // if (stateCurrentName == stateCurrentName) {
        // await
        // GetPairPrice();
        // setTimeout(fetchPriceAndRepeat, 5000); // Schedule the next call
        // // }
        // };

        getForNotifyCoinTransaction();
        GetPairPrice();
        getAllCoinData();
        // fetchPriceAndRepeat();
    }, []);

    const handleClose = () => {
        setShowDepositModal(false);
        setAddressData({});
        setSelectChain();
    };
    const handleWithdrawCloseModal = () => {
        setShowWithdrawModal({ flag: false, type: "cryptoNetwork" });
        setSelectChain();
    };

    const handleTabClick = (tabKey) => {
        setActiveTab(tabKey);
    };

    const getForNotifyCoinTransaction = async () => {
        try {
            const res = await GetTokenApi(API_Path.GetNotify);
            if (res && res.data) {
                setUserErrorNotification(res.data);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const setUserErrorNotification = (data) => {
        if (data != undefined && data.length > 0) {
            data.forEach((item) => {
                // forEach with data
                notificationList.push(item); // push item in variable
            });
        }
    };

    const GetPairPrice = async () => {
        try {
            const res = await GetTokenApi(API_Path.getPairPrice);
            if (res && res.data) {
                pairsPriceData = res.data;
                // callBackGetPairPrice();
            } else {
                toast.error(res.data.Errors);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    // const callBackGetPairPrice = () => {
    //     setTimeout(() => {
    //         GetPairPrice();
    //     }, 5000); // 30 seconds
    // };

    useEffect(() => {
        if (coinChain.length > 0 && showWithdrawModal.flag) {
            GetAddress(coinChain[0]);
            setWithdrawChainData((prevState) => ({
                ...prevState,
                Exist: true,
                Coin: coinChain[0].Coin,
                Network: coinChain[0].Network,
                AddressRegex: coinChain[0].AddressRegex,
                ContractAddress: coinChain[0].ContractAddress,
                TagLabel: coinChain[0].TagLabel,
                TagRegex: coinChain[0].TagRegex,
                WithdrawDescr: coinChain[0].WithdrawDescr,
                WithdrawEnable: coinChain[0].WithdrawEnable,
                WithdrawFee: coinChain[0].WithdrawFee,
                WithdrawHideEnable: coinChain[0].WithdrawHideEnable,
                WithdrawIsTag: coinChain[0].WithdrawIsTag,
                WithdrawMax: coinChain[0].WithdrawMax,
                WithdrawMin: coinChain[0].WithdrawMin,
                WithdrawScale: coinChain[0].WithdrawScale,
                WithdrawSpecialTips: coinChain[0].WithdrawSpecialTips,
            }));
        }
    }, [coinChain, showWithdrawModal.flag]);

    useEffect(() => {
        if (coinChain.length > 0 && showDepositModal) GetAddress(coinChain[0]);
    }, [coinChain, showDepositModal]);

    const handleChain = (item) => {
        setLoading(true);
        setSelectChain(item.ShortName);
        // GetAddress(item);
        setWithdrawChainData((prevState) => ({
            ...prevState,
            Exist: true,
            Coin: item.Coin,
            Network: item.Network,
            AddressRegex: item.AddressRegex,
            ContractAddress: item.ContractAddress,
            TagLabel: item.TagLabel,
            TagRegex: item.TagRegex,
            WithdrawDescr: item.WithdrawDescr,
            WithdrawEnable: item.WithdrawEnable,
            WithdrawFee: item.WithdrawFee,
            WithdrawHideEnable: item.WithdrawHideEnable,
            WithdrawIsTag: item.WithdrawIsTag,
            WithdrawMax: item.WithdrawMax,
            WithdrawMin: item.WithdrawMin,
            WithdrawScale: item.WithdrawScale,
            WithdrawSpecialTips: item.WithdrawSpecialTips,
        }));
        getTotalBalance();
        setAddressData({});
        setWithdrawBalance();
        setLoading(false);
    };

    const getTotalBalance = () => {
        let amount = parseFloat(withdrawModel.amount);
        if (isNaN(amount)) amount = 0;

        let fees = parseFloat(withdrawChainData.WithdrawFee);
        if (isNaN(fees)) fees = 0;

        let totalAmount = minus(amount, fees);
        if (totalAmount < 0) totalAmount = 0;

        setWithdrawModel({
            ...withdrawModel,
            totalAmount: expoStrToNumStr(totalAmount.toFixed(13)),
        });
    };
    const setMaxWithdrawAmount = () => {
        setWithdrawChainData((prevState) => ({ ...prevState, amount: withdrawChainData.FreeBalance }));
    };

    const getAllCoinData = async () => {
        setLoading(true);
        try {
            const [allCoinRes, cryptoCoinRes] = await Promise.all([GetTokenApi(API_Path.getAllCoin), GetTokenApi(API_Path.getCryptoCoinAll)]);

            const allCoinDataArray = Array.isArray(allCoinRes?.data) ? allCoinRes.data : [];
            const cryptoCoinDataArray = Array.isArray(cryptoCoinRes?.data?.Coins) ? cryptoCoinRes.data.Coins : [];

            if (allCoinDataArray.length && cryptoCoinDataArray.length) {
                const allCoinFilter = allCoinDataArray.map((item) => {
                    const cryptoItem = cryptoCoinDataArray.find((crypto) => item.Name === crypto.CoinName);
                    return cryptoItem ? { ...item, ...cryptoItem } : item;
                });

                const allCoinFilterData = allCoinFilter.filter((item) => cryptoCoinDataArray.some((cryptoItem) => item.Name === cryptoItem.CoinName));

                setAllCoinData(allCoinFilterData);
            } else {
                if (allCoinRes?.data?.Errors) {
                    toast.error(allCoinRes.data.Errors);
                }
                if (cryptoCoinRes?.data?.Errors) {
                    toast.error(cryptoCoinRes.data.Errors);
                }
            }

            coinsData = allCoinRes.data;
            // setCoins(allCoinRes.data || []);

            GetBaseConvertCoin();

            if (cryptoCoinRes) {
                setCryptoCoins(cryptoCoinRes.data.Coins || []);
                setWalletCoinBalance(cryptoCoinRes.data.Coins);
                getUserSpotWalletCoinBalance();
            }
        } catch (error) {
            console.error("Error:", error);
        }
        setLoading(false);
    };

    const setWalletCoinBalance = (coins) => {
        if (coins.length > 0) {
            var data = [];
            if (coinBalance.length <= 0) {
                data = coins.map((item, i) => {
                    const balancePropsObj = {
                        TotalBalance: defaultZero,
                        FreeBalance: defaultZero,
                        OnOrderBalance: defaultZero,
                        EstimateCoinValuation: defaultZero,
                    };
                    // Assign new properties and return
                    return Object.assign(item, balancePropsObj);
                });
            } else {
                data = coins.map((ele) => {
                    const obj = coinBalance && coinBalance?.filter((x) => x.Coin.toLowerCase() === ele.Coin.toLowerCase())[0];
                    if (obj) {
                        ele.TotalBalance = parseFloat(obj.TotalBalance) != 0 ? obj.TotalBalance : defaultZero;
                        ele.FreeBalance = parseFloat(obj.Free) != 0 ? obj.Free : defaultZero;
                        ele.OnOrderBalance = parseFloat(obj.Ordering) != 0 ? obj.Ordering : defaultZero;
                        ele.EstimateCoinValuation = parseFloat(obj.EstimateCoinValuation) != 0 ? obj.EstimateCoinValuation : defaultZero;
                    } else {
                        // need to set defaultZero
                        ele.TotalBalance = defaultZero;
                        ele.FreeBalance = defaultZero;
                        ele.OnOrderBalance = defaultZero;
                        ele.EstimateCoinValuation = defaultZero;
                    }
                    return ele;
                });
            }
            setCryptoCoins((prevState) => [...prevState, ...data]);
        }
    };

    const setWithdrawBalance = () => {
        setWithdrawChainData((prevState) => ({ ...prevState, FreeBalance: cryptoCoins?.FreeBalance }));
    };

    const getUserSpotWalletCoinBalance = async () => {
        try {
            const res = await GetTokenApi(API_Path.getBalance);
            if (res) {
                setCoinBalance(res.data);
                setWalletCoinBalance(res.data); // bind coins with filter zero balance data show|hide
                setEstimateTotal(); // calc total estimated balance
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const GetCoinChain = async (coin) => {
        try {
            const res = await GetTokenApi(API_Path.getChain + coin);
            if (res && res.data) {
                coinChain = res.data;
                const filterData = res.data.map((item) => item.ShortName);
                setSelectChain(filterData[0]);
            } else {
                toast.error(res.data.Errors);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const callbackSetEstimateTotalConverted = () => {
        if (!isStringNullOrWhiteSpace(estimateSetting.coin) && !isStringNullOrWhiteSpace(baseConvertCoinSetting.Coin)) {
            if (!estimateSetting.isRunningTotalConverted) {
                setEstimateSetting((prevState) => ({ ...prevState, isRunningTotalConverted: true }));
            }
            setTimeout(() => {
                if (props?.state?.current.name === stateCurrentName) {
                    setEstimateTotalConverted();
                    callbackSetEstimateTotalConverted();
                }
            }, 35 * 1000);
        } else {
            setEstimateSetting((prevState) => ({ ...prevState, isRunningTotalConverted: false }));
        }
    };

    const setEstimateTotal = () => {
        // check estimateCoin have
        const coin = estimateSetting.coin;
        if (!isStringNullOrWhiteSpace(coin)) {
            // calculate total
            let estimateTotal = 0;
            if (cryptoCoins.length > 0) {
                const list = cryptoCoins
                    .filter((x) => parseFloat(x.EstimateCoinValuation) > 0)
                    .map((x) => {
                        return parseFloat(x.EstimateCoinValuation);
                    });
                if (list.length > 0) {
                    //angular.forEach(list, function (x) { estimateTotal += x; });
                    estimateTotal = list.reduce((total, x) => sum(total, x), 0);
                }
            }
            setEstimateSetting((prevState) => ({ ...prevState, total: estimateTotal }));
            seteEtimateDisplay((prevState) => ({ ...prevState, total: setDisplayConvertedInputCoin(estimateTotal.toString(), coin) }));
            setEstimateTotalConverted();
            if (!estimateSetting.isRunningTotalConverted) {
                callbackSetEstimateTotalConverted();
            }
        } else {
            seteEtimateDisplay((prevState) => ({ ...prevState, total: "--", totalConverted: "--" }));
        }
    };

    const setDisplayConvertedCoinInput = (input, coin) => {
        // set display
        const inputStr = input == 0 ? defaultZero : input.toString();
        return coin + " " + inputStr;
    };

    const truncateDecimals = (value, scale) => {
        const factor = Math.pow(10, scale);
        return Math.floor(value * factor) / factor;
    };

    const coinConvertedValue = (base, quote, baseValue, scale) => {
        if (baseValue == 0) {
            return truncateDecimals(baseValue, scale);
        } else {
            const price = coinCurrentPrice(base, quote);
            const total = price == 0 ? price : baseValue * price;
            return truncateDecimals(total, scale);
        }
    };

    const coinConvertedValueByPrice = (price, baseValue, scale) => {
        if (baseValue == 0) {
            return truncateDecimals(baseValue, scale);
        } else {
            const total = price == 0 ? price : baseValue * price;
            return truncateDecimals(total, scale);
        }
    };

    const setEstimateTotalConverted = () => {
        if (!isStringNullOrWhiteSpace(baseConvertCoinSetting.Coin)) {
            const convertedValue = coinConvertedValue(estimateSetting.coin, baseConvertCoinSetting.Coin, estimateSetting.total, parseInt(baseConvertCoinSetting.BalanceScale));
            // display total converted
            seteEtimateDisplay((prevState) => ({ ...prevState, totalConverted: setDisplayConvertedCoinInput(convertedValue, baseConvertCoinSetting.Symbol) }));
        }
    };

    const GetBaseConvertCoin = async () => {
        try {
            const res = await GetTokenApi(API_Path.getBaseConvertCoin);
            if (res && res.data) {
                baseConvertCoinSetting = res.data.Model;
                if (baseConvertCoinSetting.Coin) {
                    baseConvertCoinSetting.BalanceScale = filterCoinBalanceScale(baseConvertCoinSetting.Coin);
                }
                GetEstimateCoin();
            } else {
                toast.error(res.data.Errors);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const setFilterCryptoCoins = () => {
        setHideBtn(!hideBtn);
    };

    const GetEstimateCoin = async () => {
        try {
            const res = await GetTokenApi(API_Path.getEstimateCoin);
            if (res && res.data) {
                setEstimateSetting((prevState) => ({ ...prevState, coin: res.data.EstimateCoin }));
                CheckUserKycStatus();
            } else {
                toast.error(res.data.Errors);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const CheckUserKycStatus = async () => {
        try {
            const res = await GetTokenApi(API_Path.checkUserKycStatus);
            if (res && res.data.Isvalid) {
                getDailyWithdrawLimitSetting(res.data.Model.IsKyc);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const getDailyWithdrawLimitSetting = async (userIsKyc) => {
        try {
            const res = await GetTokenApi(API_Path.getDailyWithdrawLimit);
            if (res) {
                if (res.data.Isvalid) {
                    dailyWithdrawLimitSetting = res.data.Model;
                    if (dailyWithdrawLimitSetting.Coin) {
                        dailyWithdrawLimitSetting.BalanceScale = filterCoinBalanceScale(dailyWithdrawLimitSetting.Coin);
                        UserDailyWithdrawData.coin = dailyWithdrawLimitSetting.Coin;
                        if (userIsKyc !== null) {
                            UserDailyWithdrawData.limit = userIsKyc ? dailyWithdrawLimitSetting.LimitKyc : dailyWithdrawLimitSetting.LimitNonKyc;
                            const WithdrawLimit = setDisplayConvertedInputCoin(userIsKyc ? dailyWithdrawLimitSetting.LimitKyc : dailyWithdrawLimitSetting.LimitNonKyc, dailyWithdrawLimitSetting.Coin);
                            userDailyWithdrawDisplayData.limit = WithdrawLimit;
                        } else {
                            UserDailyWithdrawData.limit = "";
                            userDailyWithdrawDisplayData.limit = "--";
                        }
                    }
                    GetWithdrawConvertCoin();
                } else {
                    toast.error(res.data.Errors);
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const GetWithdrawConvertCoin = async () => {
        try {
            const res = await GetTokenApi(API_Path.getWithdrawConvertCoin);
            if (res.data.Model.Coin) {
                withdrawConvertCoinSettingData = { coin: res.data.Model.Coin, BalanceScale: filterCoinBalanceScale(res.data.Model.Coin) };
            }
            validateGetUserDailyWithdrawSum(res.data.Model.Coin);
        } catch (error) {
            console.error("Error:", error);
        }
    };
    const getDailyWithDrawsum = async () => {
        try {
            const res = await GetTokenApi(API_Path.getDailyWithDrawsum);
            if (res && res.data) {
                setDailyDrawsumData(res.data.Model);
                const obj = convertUserDailyWithdrawToLimit(res.data.Model);
                if (res.data.Isvalid) {
                    UserDailyWithdrawData.total = obj?.amount.toString();
                    userDailyWithdrawDisplayData.total = setDisplayConvertedInputCoin(obj.amount.toString(), dailyWithdrawLimitSetting.Coin);
                    validateSetUserDailyWithdrawValid();
                }
            } else {
                resetUserDailyWithdrawSum();
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const validateGetUserDailyWithdrawSum = (coin) => {
        if (!isStringNullOrWhiteSpace(coin)) {
            getDailyWithDrawsum();
        } else {
            resetUserDailyWithdrawSum(); // error
        }
    };

    const filterCoinBalanceScale = (coin) => {
        const list = coinsData
            .filter((x) => x.Coin.toLowerCase() == coin.toLowerCase())
            .map((x) => {
                return x.BalanceScale;
            });
        return list.length > 0 ? list[0] : "0";
    };

    const setDisplayConvertedInputCoin = (inputStr, coin) => {
        // set display
        const input = parseFloat(inputStr) == 0 ? defaultZero : inputStr;
        return input + " " + coin;
    };

    const resetUserDailyWithdrawRemaining = () => {
        UserDailyWithdrawData.isValid = false;
        UserDailyWithdrawData.remaining = "";
    };

    const resetUserDailyWithdrawSum = () => {
        UserDailyWithdrawData.total = "";
        userDailyWithdrawDisplayData.total = "--";
        resetUserDailyWithdrawRemaining();
    };

    const validateSetUserDailyWithdrawValid = () => {
        if (!isStringNullOrWhiteSpace(UserDailyWithdrawData.limit) && !isStringNullOrWhiteSpace(UserDailyWithdrawData.total)) {
            const remaining = minus(parseFloat(UserDailyWithdrawData.limit), parseFloat(UserDailyWithdrawData.total)).toString();
            UserDailyWithdrawData.remaining = remaining;
            UserDailyWithdrawData.isValid = true;
        } else {
            resetUserDailyWithdrawRemaining();
        }
    };

    const coinConvertedValueNoTruncate = (base, quote, baseValue) => {
        if (baseValue === 0) {
            return baseValue;
        } else {
            const price = coinCurrentPrice(base, quote);
            return price === 0 ? price : baseValue * price;
        }
    };

    const coinCurrentPrice = (base, quote) => {
        base = base.toLowerCase();
        quote = quote.toLowerCase();
        if (base === quote) return 1;
        if (pairsPriceData.length > 0) {
            let priceStr = filterPairsPrice(pairsPriceData, base, quote); // Method 1
            if (priceStr !== undefined) {
                const priceNum = parseFloat(priceStr);
                if (!isNaN(priceNum)) {
                    return priceNum; // 1 baseCoin = price in quote coin
                }
            } else {
                // get opposite pair price
                priceStr = filterPairsPrice(pairsPriceData, quote, base); // Method 2
                if (priceStr !== undefined) {
                    // convert price from base coin to quote coin
                    const priceNum = parseFloat(priceStr);
                    if (!isNaN(priceNum) && priceNum !== 0) {
                        return 1 / priceNum; // price in quote coin
                    }
                }
            }
        }
        return 0;
    };

    const filterPairsPrice = (pairsPrice, base, quote) => {
        return pairsPrice.filter((x) => x.Base.toLowerCase() === base && x.Quote.toLowerCase() === quote).map((x) => x.ClosePrice)[0];
    };

    const convertUserDailyWithdrawToLimit = (list) => {
        let isValidConvert = true;
        let amountConvert = 0;
        var convertedValue;
        if (list != null && !isStringNullOrWhiteSpace(dailyWithdrawLimitSetting.Coin)) {
            if (list.length > 0) {
                let amountSum = 0;
                list.forEach((ele) => {
                    let amount = parseFloat(ele.Amount);
                    if (amount != 0) {
                        convertedValue = coinConvertedValueNoTruncate(ele.Coin, withdrawConvertCoinSettingData.coin, amount, parseInt(withdrawConvertCoinSettingData.BalanceScale));
                        if (convertedValue === 0) {
                            isValidConvert = false;
                            return; // break loop
                        } else {
                            amountSum = sum(amountSum, convertedValue);
                        }
                    }
                });
                if (isValidConvert && amountSum !== 0) {
                    convertedValue = coinConvertedValueNoTruncate(withdrawConvertCoinSettingData.coin, dailyWithdrawLimitSetting.Coin, amountSum, parseInt(dailyWithdrawLimitSetting.BalanceScale));
                    if (convertedValue === 0) {
                        isValidConvert = false;
                    } else {
                        amountConvert = convertedValue;
                    }
                }
            } else {
                isValidConvert = false;
            }
            return {
                isValid: isValidConvert,
                amount: amountConvert,
            };
        }
    };

    const filteredCoinData = allCoinData.filter((item) => item.Coin.toLowerCase().includes(searchQuery.toLowerCase()) || item.CoinName.toLowerCase().includes(searchQuery.toLowerCase()));

    const GetAddress = async (coin) => {
        setLoading(true);
        let data = {
            Coin: coin.Coin,
            Network: coin.Network,
            SameAddress: coin.IsSameAddress,
        };
        try {
            const res = await PostTokenApi(API_Path.getAddress, data);
            if (res) {
                if (res.data.Isvalid) {
                    setAddressData(res.data.Model);
                } else {
                    toast.error(res.data.Errors[0]);
                }
                setLoading(false);
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleDepositClick = (item) => {
        const coinModel = getCryptoCoinModel(item.Coin);
        if (coinModel != null) {
            setCoinData(coinModel);
            GetCoinChain(item.Coin);
            setUserDailyWithdrawRemainingLimit(item.Coin);
            setShowDepositModal(item.DepositEnable ? true : false);
            setWithdrawChainData((prevState) => ({ ...prevState, Coin: item.Coin }));
            setSelectChain();
        }
    };

    const getCryptoCoinModel = (coin) => {
        if (cryptoCoins != null && cryptoCoins.length > 0 && !isStringNullOrWhiteSpace(coin)) {
            const coinDataObj = cryptoCoins.filter((x) => x.Coin.toLowerCase() === coin.toLowerCase())[0];
            if (coinDataObj != undefined && coinDataObj != null) {
                return coinDataObj;
            }
        }
        return null;
    };

    const handleWithDrawClick = (item) => {
        // validate withdraw valid
        if (!UserDailyWithdrawData.isValid) {
            toast.error("24h withdraw limit is invalid.");
            return; // Exit the function if the limit is invalid
        }
        // Proceed with withdrawal if the limit is valid
        const coinModel = getCryptoCoinModel(item.Coin);
        if (coinModel != null) {
            setShowWithdrawModal({ flag: true, type: "cryptoNetwork" });
            setCoinData(coinModel);
            GetCoinChain(item.Coin);
            setUserDailyWithdrawRemainingLimit(item.Coin);
            setSelectChain();
            setWithdrawChainData({});
        }
    };

    const setUserDailyWithdrawRemainingLimit = (coin) => {
        const fromCoin = UserDailyWithdrawData.coin;
        const toCoin = coin;
        const balanceScale = parseInt(filterCoinBalanceScale(coin));
        const price = coinCurrentPrice(fromCoin, toCoin);

        if (price === 0) {
            userDailyWithdrawConvertedData = {
                coin: fromCoin,
                limit: UserDailyWithdrawData.limit,
                remaining: UserDailyWithdrawData.remaining,
            };
        } else {
            const limit = coinConvertedValueByPrice(price, parseFloat(UserDailyWithdrawData.limit), balanceScale).toString();
            const remaining = coinConvertedValueByPrice(price, parseFloat(UserDailyWithdrawData.remaining), balanceScale).toString();
            userDailyWithdrawConvertedData = { coin: toCoin, limit: limit, remaining: remaining };
        }
        // console.log(userDailyWithdrawConvertedData, "userDailyWithdrawConvertedData");
        setuserDailyWithdrawConvertedDisplay({
            limit: setDisplayConvertedInputCoin(userDailyWithdrawConvertedData.limit, userDailyWithdrawConvertedData.coin),
            remaining: setDisplayConvertedInputCoin(userDailyWithdrawConvertedData.remaining, userDailyWithdrawConvertedData.coin),
        });
    };

    const setSubmitWithdrawData = async (formData) => {
        if (withdrawModel.amount == 0) {
            toast.error("Amount to withdraw must be higher than 0.");
        } else {
            let data = {
                Coin: withdrawChainData.Coin,
                Network: withdrawChainData.Network,
                Address: formData.withdrawAds,
                Memo: formData.memo,
                Amount: formData.amount,
            };
            try {
                const res = await PostTokenApi(API_Path.sendWithdrawByAddressSendRequest, data);
                if (res) {
                    if (res.data.Isvalid) {
                        toast.success(res.data.Successes);
                        validateGetUserDailyWithdrawSum(res.data.Coin);
                        getUserSpotWalletCoinBalance();
                    } else {
                        toast.error(res.data.Errors[0]);
                    }
                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        toast.success("Copied deposit address.");
    };

    const isNumber = (value) => {
        return !isNaN(value) && isFinite(value);
    };
    return (
        <>
            {loading && <Loading />}

            <section>
                <div className="container">
                    <div className="title_heading">
                        <h4 className="ps-0">Spot</h4>
                    </div>
                    <div className={styles.funds_section}>
                        <div className={styles.asset_card}>
                            <div className={styles.card_details}>
                                <p>{/* Estimated Value :{estimateDisplay.total} / {estimateDisplay.totalConverted} */}</p>

                                <p>
                                    24h Withdrawal Limit : <b>{userDailyWithdrawDisplayData.limit}</b>
                                </p>
                                <p>
                                    In Use : <b>{userDailyWithdrawDisplayData.total}</b>
                                </p>
                            </div>
                        </div>
                        <div className={styles.fund_section_body}>
                            <div className={styles.funds_section_head}>
                                <div className={styles.funds_section_tab}>
                                    <ul className={`${styles.tab} ${styles.navtabs}`}>
                                        <li className={styles.navitem}>
                                            <button className={`${styles.navlink} ${activeTab === "crypto" ? styles.active : ""} ms-0`} aria-current="page" onClick={() => handleTabClick("crypto")}>
                                                Crypto
                                            </button>
                                        </li>
                                        <li className={styles.navitem}>
                                            <button className={`${styles.navlink} ${activeTab === "fiat" ? styles.active : ""}`} onClick={() => handleTabClick("fiat")}>
                                                Fiat
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className={styles.funds_section_search}>
                                    <div className={styles.coin_search_input}>
                                        <div className={styles.form_search}>
                                            <input type="search" placeholder="Search coin" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                        </div>
                                        <div className={styles.balance_btn}>
                                            <button className="button secondary_button" onClick={() => setFilterCryptoCoins()}>
                                                {!hideBtn ? "Hide 0 Balance" : "Show 0 Balances"}
                                            </button>
                                        </div>
                                    </div>
                                    <div className={styles.view_text_sectico}>
                                        <Link to="/useraccount/funds-history-spot">
                                            <p>View History</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {activeTab === "crypto" ? (
                                <div className={`tickets_table_body ${styles.trading_table_body}`}>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Coin</th>
                                                <th>Coin Name</th>
                                                <th>Total Balance</th>
                                                <th>Available Balance</th>
                                                <th>On Order</th>
                                                <th>BTC value</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        {!hideBtn ? (
                                            filteredCoinData.length > 0 ? (
                                                filteredCoinData.map((item, i) => (
                                                    <tbody key={i}>
                                                        <tr>
                                                            <td className="d-flex align-items-center">
                                                                <img src={item.ImageUrl ?? noImgAvl} alt="" />
                                                                {item.Coin}
                                                            </td>
                                                            <td>{item.Name}</td>
                                                            <td>{item.tol_bal ?? "0.00"}</td>
                                                            <td>{item.ave_bal ?? "0.00"}</td>
                                                            <td>{item.on_oder ?? "0.00"}</td>
                                                            <td>{item.btc_val ?? "0.00"}</td>
                                                            {item.action === 1 ? (
                                                                <td>
                                                                    <span>Generate Address</span>
                                                                </td>
                                                            ) : (
                                                                <td className="d-flex align-items-center">
                                                                    {item.DepositEnable && (
                                                                        <span className={item.DepositEnable ? "" : styles.dis_text} onClick={() => handleDepositClick(item)}>
                                                                            Deposit
                                                                        </span>
                                                                    )}
                                                                    {item.WithdrawEnable && (
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle
                                                                                id="dropdown-basic"
                                                                                className={`${styles.dropdown_toggle} ${item.WithdrawEnable ? "" : styles.dis_text}`}
                                                                                disabled={!item.WithdrawEnable}
                                                                            >
                                                                                WITHDRAW
                                                                            </Dropdown.Toggle>

                                                                            <Dropdown.Menu>
                                                                                <div className={styles.withdraw_dropdown}>
                                                                                    <h4>Withdraw</h4>
                                                                                    <Dropdown.Item onClick={() => handleWithDrawClick(item)}>
                                                                                        <div className={styles.dropdown_text}>
                                                                                            <b>Send via Crypto Network</b>
                                                                                            <small>Send to a know crypto address via Crypo Network</small>
                                                                                        </div>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                                            <path
                                                                                                d="M4.90188 0.699951L3.92188 1.74995L9.10187 6.99995L3.92188 12.25L4.90188 13.3L11.2019 6.99995L4.90188 0.699951Z"
                                                                                                fill="#212121"
                                                                                            />
                                                                                        </svg>
                                                                                    </Dropdown.Item>
                                                                                    {/* <Dropdown.Item onClick={() => setShowWithdrawModal({ flag: true, type: "funexID" })}>
                                                                                        <div>
                                                                                            <b>Send via Email / Phone / FUNEX ID</b>
                                                                                            <small>Send to FUNEX user with 0 fee</small>
                                                                                        </div>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                                            <path
                                                                                                d="M4.90188 0.699951L3.92188 1.74995L9.10187 6.99995L3.92188 12.25L4.90188 13.3L11.2019 6.99995L4.90188 0.699951Z"
                                                                                                fill="#212121"
                                                                                            />
                                                                                        </svg>
                                                                                    </Dropdown.Item> */}
                                                                                </div>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    )}
                                                                    {item.IsTrading && <span className={item.IsTrading ? "" : styles.dis_text}>Trade</span>}
                                                                    {item.HelpMessage !== null && (
                                                                        <button className={styles.helpbutton} data-tooltip={item.HelpMessage} data-tooltip-pos="left" data-tooltip-length="medium">
                                                                            ?
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            )}
                                                        </tr>
                                                    </tbody>
                                                ))
                                            ) : (
                                                <NoDataFoundImg />
                                            )
                                        ) : (
                                            <NoDataFoundImg />
                                        )}
                                    </Table>
                                </div>
                            ) : (
                                <div className={`${styles.detatils_white_box} py-4 text-center m-4`}>
                                    <div className={styles.coming_soon}>
                                        <h5 className="mb-1">COMING SOON!</h5>
                                        <p className="mb-0">We are working on the Fiat process.</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            {/* ---------------------------------- DEPOSIT MODAL -------------------------------------- */}

            <Modal show={showDepositModal} onHide={handleClose} centered className={`${styles.fial_details_modal} history_fial_modal d-block`} size="lg">
                <Modal.Header closeButton className={styles.modal_header}>
                    <Modal.Title className="text-start">Deposit {withdrawChainData?.Coin}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className={`col-7 ${styles.modal_deposit}`}>
                            <div className="mb-3">
                                <span className={styles.label}>Select Chain</span>
                                <div className={styles.select_chain}>
                                    {coinChain.map((item, i) => (
                                        <button key={i} className={selectChain === item.ShortName ? styles.active : ""} onClick={() => handleChain(item)} disabled={selectChain === item.ShortName}>
                                            {item.ShortName}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            {addressData && addressData.Address && (
                                <div>
                                    <div className="mb-3">
                                        <span className={styles.label}>Address</span>
                                        <div className={styles.copy_input}>
                                            <input className="form-control" value={addressData?.Address} readOnly />
                                            <button className={styles.copy_btn} onClick={() => handleCopy(addressData.Address)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#00c4f4" className="bi bi-copy" viewBox="0 0 16 16">
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
                                        <QRCode size={200} style={{ height: "auto", maxWidth: "100%", width: "100%" }} value={addressData.Address} viewBox={`0 0 256 256`} />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={`${styles.wallet_deposit_info} col-5`}>
                            <p>After making a deposit, you can stack its status on the Deposit History</p>
                            <div>
                                <p className="mb-2">Tips:</p>
                                <ul>
                                    <li>Only send the corresponding digital assets to the deposit address. Sending any other currency to this address may result in the loss of your deposit.</li>
                                    <li>Coins will be deposited immediately after 25 network confirmations.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ---------------------------------- DEPOSIT MODAL -------------------------------------- */}

            {/* ---------------------------------- WITHDRAW MODAL -------------------------------------- */}

            <Modal show={showWithdrawModal.flag} onHide={handleWithdrawCloseModal} centered className={`${styles.fial_details_modal} history_fial_modal d-block`} size="lg">
                <Modal.Header closeButton className={styles.modal_header}>
                    <Modal.Title className="text-start">Withdraw {withdrawChainData?.Coin}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className={`col-7 ${styles.modal_deposit}`}>
                            {showWithdrawModal.type === "funexID" ? (
                                <div className="mb-3">
                                    <Formik
                                        initialValues={{
                                            withdrawAds: "",
                                            memo: "",
                                            amount: "",
                                        }}
                                        validationSchema={Yup.object().shape({
                                            withdrawAds: Yup.string().required("Please enter recipient address."),
                                            amount: Yup.number().required("Please enter withdraw amount."),
                                        })}
                                        onSubmit={(values, { resetForm }) => {
                                            setSubmitWithdrawData(values, resetForm);
                                        }}
                                    >
                                        {(runform) => {
                                            const { touched, errors } = runform;
                                            return (
                                                <Form onSubmit={runform.handleSubmit}>
                                                    <div className={styles.withdraw_form}>
                                                        <div className="mb-3">
                                                            <label className={styles.label}>Account Type</label>
                                                            <select
                                                                className={`form-control ${errors.withdrawAds && touched.withdrawAds && "input-error"}`}
                                                                {...formAttr(runform, "withdrawAds")}
                                                                name="withdrawAds"
                                                            >
                                                                <option value="">Email / Phone Number</option>
                                                                <option value="1">Phone Number</option>
                                                            </select>
                                                            {errorContainer(runform, "withdrawAds")}
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className={styles.label}>
                                                                Available: <b>{withdrawChainData.FreeBalance ?? 0.0}</b>{" "}
                                                                {/* <button type="button" onClick={() => setMaxWithdrawAmount()}>
                                                                    Max
                                                                </button> */}
                                                            </label>
                                                            <div className={styles.amount_input}>
                                                                <input
                                                                    className={`form-control ${errors.amount && touched.amount && "input-error"}`}
                                                                    placeholder="Amount"
                                                                    value=""
                                                                    name="amount"
                                                                    {...formAttr(runform, "amount")}
                                                                />
                                                                <span>USDT</span>
                                                            </div>
                                                            {errorContainer(runform, "amount")}
                                                        </div>
                                                        <span className="mb-3">
                                                            You Will Get:{" "}
                                                            <b>
                                                                {" "}
                                                                {withdrawModel.totalAmount} {withdrawChainData.Coin}
                                                            </b>
                                                        </span>
                                                        <span className="mb-2">
                                                            Withdraw Fee:{" "}
                                                            <b>
                                                                {" "}
                                                                {withdrawChainData.WithdrawFee} {withdrawChainData.Coin}
                                                            </b>
                                                        </span>
                                                        <button type="submit" className="button primary_button w-100 mt-4">
                                                            Withdraw
                                                        </button>
                                                    </div>
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            ) : (
                                <>
                                    <div className="mb-3">
                                        <span className={styles.label}>Slect Chain</span>
                                        <div className={styles.select_chain}>
                                            {coinChain.map((item, i) => (
                                                <button
                                                    key={i}
                                                    className={selectChain === item.ShortName ? styles.active : ""}
                                                    onClick={() => handleChain(item)}
                                                    disabled={selectChain === item.ShortName}
                                                >
                                                    {item.ShortName}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                    {withdrawChainData.Exist && (
                                        <div className="mb-3">
                                            <Formik
                                                initialValues={{
                                                    withdrawAds: withdrawModel.toAddress,
                                                    memo: withdrawModel.memo,
                                                    amount: withdrawModel.amount,
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    withdrawAds: Yup.string()
                                                        .matches(
                                                            new RegExp(withdrawChainData.AddressRegex, "g"),
                                                            "Withdraw address format is wrong. Please check the withdraw address length and character content and try again."
                                                        )
                                                        .required("Please enter recipient address."),
                                                    memo: Yup.string().matches(
                                                        new RegExp(withdrawChainData.TagRegex, "g"),
                                                        "Withdraw address format is wrong. Please check the withdraw address length and character content and try again."
                                                    ),
                                                    amount: Yup.number()
                                                        .required("Please enter withdraw amount.")
                                                        .test({
                                                            name: "is-valid-amount",
                                                            message: "Invalid amount", // Default error message
                                                            test: function (value) {
                                                                // if (!isStringNullOrWhiteSpace(value)) {
                                                                const amount = parseFloat(value);
                                                                // Check if the amount is not a number or less than 0
                                                                if (!isNumber(amount) || amount < 0) {
                                                                    return this.createError({
                                                                        message: "Withdraw amount must be valid and positive.",
                                                                    });
                                                                }
                                                                // Check if the amount is exactly 0
                                                                if (amount === 0) {
                                                                    return this.createError({
                                                                        message: "Amount to withdraw must be higher than 0.",
                                                                    });
                                                                }
                                                                // Check withdraw fees valid number
                                                                const withdrawFees = parseFloat(withdrawChainData.WithdrawFee);
                                                                if (isNaN(withdrawFees)) {
                                                                    return this.createError({
                                                                        message: "Withdraw fees must be valid.",
                                                                    });
                                                                }
                                                                // Check withdraw amount scale
                                                                var withdrawScale = parseInt(withdrawChainData.WithdrawScale);
                                                                if (isNaN(withdrawScale) || withdrawScale < 0) {
                                                                    return this.createError({
                                                                        message: "Withdraw amount scale must be valid.",
                                                                    });
                                                                } else if (withdrawScale === 0) {
                                                                    // if (decimalScaleCount(withdrawFees) > 0) {
                                                                    //     return this.createError({
                                                                    //         message: "Withdraw fees must be numbers with no more than 0 decimal places.",
                                                                    //     });
                                                                    // }
                                                                    // // Check amount should be integer
                                                                    // if (decimalScaleCount(amount) > 0) {
                                                                    //     this.createError({
                                                                    //         message: "Withdraw amount must be numbers with no more than 0 decimal places.",
                                                                    //     });
                                                                    // }
                                                                } else {
                                                                    // if (decimalScaleCount(amount) > withdrawScale) {
                                                                    //     this.createError({
                                                                    //         message: "Please enter numbers with no more than " + withdrawScale + " decimal places.",
                                                                    //     });
                                                                    // }
                                                                }
                                                                const withdrawMin = parseFloat(withdrawChainData.WithdrawMin);
                                                                if (withdrawMin <= withdrawFees) {
                                                                    // Check amount less than or equal wdrl fees
                                                                    if (amount <= withdrawFees) {
                                                                        this.createError({
                                                                            message: "Amount to withdraw must be higher than " + withdrawFees.toString() + ".",
                                                                        });
                                                                    }
                                                                } else {
                                                                    // Check amount less than min wdrl
                                                                    if (amount < withdrawMin) {
                                                                        this.createError({
                                                                            message: "Amount to withdraw must be at least " + withdrawMin.toString() + ".",
                                                                        });
                                                                    }
                                                                }

                                                                const freeBalance = parseFloat(withdrawChainData.FreeBalance);
                                                                // Check amount more than wdrl balance
                                                                if (amount > freeBalance) {
                                                                    this.createError({
                                                                        message: "Please enter an withdraw amount no higher than your available balance(" + freeBalance.toString() + ").",
                                                                    });
                                                                }

                                                                const withdrawMax = parseFloat(withdrawChainData.WithdrawMax);
                                                                // Check amount more than max wdrl
                                                                if (amount > withdrawMax) {
                                                                    this.createError({
                                                                        message: "Amount to withdraw must be no higher than " + withdrawMax.toString() + ".",
                                                                    });
                                                                }

                                                                const remaining = parseFloat(userDailyWithdrawConvertedData.remaining);
                                                                if (remaining <= 0) {
                                                                    this.createError({
                                                                        message: "24h withdraw limit is exceeded.",
                                                                    });
                                                                } else if (userDailyWithdrawConvertedData.coin != withdrawChainData.Coin) {
                                                                    this.createError({
                                                                        message: "24h withdraw limit is invalid.",
                                                                    });
                                                                } else if (amount > remaining) {
                                                                    this.createError({
                                                                        message:
                                                                            "Withdraw amount must be less than or equal to withdraw limit(" +
                                                                            remaining +
                                                                            " " +
                                                                            userDailyWithdrawConvertedData.coin +
                                                                            ").",
                                                                    });
                                                                }

                                                                // }
                                                                return true; // Return true if no errors
                                                            },
                                                        }),
                                                })}
                                                onSubmit={(values, { resetForm }) => {
                                                    setSubmitWithdrawData(values, resetForm);
                                                }}
                                            >
                                                {(runform) => {
                                                    const { touched, errors } = runform;
                                                    return (
                                                        <Form onSubmit={runform.handleSubmit}>
                                                            <div className={styles.withdraw_form}>
                                                                <div className="mb-3">
                                                                    <label className={styles.label}>Withdraw Address</label>
                                                                    <input
                                                                        className={`form-control ${errors.withdrawAds && touched.withdrawAds && "input-error"}`}
                                                                        name="withdrawAds"
                                                                        value={withdrawModel.toAddress}
                                                                        onChange={(e) => {
                                                                            setWithdrawModel((prevState) => ({ ...prevState, toAddress: e.target.value }), "withdrawAds");
                                                                            runform.setFieldValue("withdrawAds", e.target.value);
                                                                        }}
                                                                    />
                                                                    {errorContainer(runform, "withdrawAds")}
                                                                </div>
                                                                {withdrawChainData.WithdrawIsTag && (
                                                                    <div className="mb-3">
                                                                        <label className={styles.label}>Memo (optional)</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={withdrawModel.memo}
                                                                            name="memo"
                                                                            onChange={(e) => {
                                                                                setWithdrawModel((prevState) => ({ ...prevState, memo: e.target.value }), "memo");
                                                                                runform.setFieldValue("memo", e.target.value);
                                                                            }}
                                                                        />
                                                                        <small>Confirm if the receiving address requires a Memo</small>
                                                                        {errorContainer(runform, "memo")}
                                                                    </div>
                                                                )}
                                                                <span className="mb-3">
                                                                    24h Remaining Limit:{" "}
                                                                    <b>
                                                                        {userDailyWithdrawConvertedDisplay.remaining}/{userDailyWithdrawConvertedDisplay.limit}
                                                                    </b>
                                                                </span>
                                                                <span className="mb-2">
                                                                    Available:{" "}
                                                                    <b>
                                                                        {withdrawChainData.FreeBalance ?? 0.0}{" "}
                                                                        <button type="button" onClick={() => setMaxWithdrawAmount()}>
                                                                            Max
                                                                        </button>
                                                                    </b>
                                                                </span>
                                                                <div className="mb-3">
                                                                    <label className={styles.label}>Amount</label>
                                                                    <div className={styles.amount_input}>
                                                                        <input
                                                                            className={`form-control ${errors.amount && touched.amount && "input-error"}`}
                                                                            value={withdrawModel.amount}
                                                                            name="amount"
                                                                            onChange={(e) => {
                                                                                setWithdrawModel((prevState) => ({ ...prevState, amount: e.target.value }), "amount");
                                                                                runform.setFieldValue("amount", e.target.value);
                                                                            }}
                                                                        />
                                                                        <span>{withdrawChainData.Coin}</span>
                                                                    </div>
                                                                    {errorContainer(runform, "amount")}
                                                                </div>
                                                                {withdrawChainData.ContractAddress != "" && withdrawChainData.ContractAddress != null && (
                                                                    <span>
                                                                        Contract Address: <b>{withdrawChainData.ContractAddress}</b>
                                                                    </span>
                                                                )}
                                                                <span>
                                                                    Network Fee:{" "}
                                                                    <b>
                                                                        {withdrawChainData.WithdrawFee} {withdrawChainData.Coin}
                                                                    </b>
                                                                </span>
                                                                <span>
                                                                    You Will Get:{" "}
                                                                    <b>
                                                                        {withdrawModel.totalAmount} {withdrawChainData.Coin}
                                                                    </b>
                                                                </span>
                                                            </div>
                                                            <button type="submit" className="button primary_button w-100 mt-4">
                                                                Withdraw
                                                            </button>
                                                        </Form>
                                                    );
                                                }}
                                            </Formik>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        <div className={`${styles.wallet_deposit_info} col-5`}>
                            <div>
                                <p className="mb-2">Tips:</p>
                                <ul>
                                    <li>
                                        Minimum withdraw amount:{" "}
                                        <b>
                                            {withdrawChainData.WithdrawMin} {withdrawChainData.Coin}
                                        </b>
                                    </li>
                                    <li>Do not withdraw directly to crowdfunding or ICO. We will not credit your account with tokens from that sale.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ---------------------------------- WITHDRAW MODAL -------------------------------------- */}
        </>
    );
}
