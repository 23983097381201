import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import styles from "../styles/tickets.module.css";
import { GetTokenApi, PostTokenApi } from "../api/api-service";
import { API_Path } from "../const";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import Loading from "../components/loader/Loading";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// NOTE: Use the editor from source (not a build)!
const editorConfiguration = {
    // plugins: ["Essentials", "Bold", "Italic", "Paragraph"],
    toolbar: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "fontfamily",
        "fontsize",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "subscript",
        "superscript",
        "code",
        "|",
        "link",
        "blockQuote",
        "codeBlock",
        "|",
        "alignment",
        "|",
        "bulletedList",
        "numberedList",
        "todoList",
        "outdent",
        "indent",
    ],
    language: "en",
};

// const editorOptions = {
//     toolbar: [
//         { name: "basicstyles", items: ["Bold", "Italic", "Strike", "Underline"] },
//         { name: "paragraph", items: ["BulletedList", "NumberedList", "Blockquote"] },
//         { name: "editing", items: ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"] },
//         { name: "links", items: ["Link", "Unlink", "Anchor"] },
//         { name: "tools", items: ["SpellChecker", "Maximize"] },
//         "/",
//         { name: "styles", items: ["Format", "FontSize", "TextColor", "PasteText", "PasteFromWord", "RemoveFormat"] },
//         { name: "insert", items: ["Table", "SpecialChar"] },
//         { name: "forms", items: ["Outdent", "Indent"] },
//         { name: "clipboard", items: ["Undo", "Redo"] },
//         { name: "document", items: ["PageBreak"] },
//     ],
//     removeButtons: "Subscript,Superscript",
// };

export default function TicketsDetails() {
    const navigate = useNavigate();
    const location = useLocation();
    const editorRef = useRef();
    const fileInputRef = useRef(null);
    const [UserTicketData, setUserTicketData] = useState();
    const [UserTicketNo, setUserTicketNo] = useState({
        CreatedDate: "",
        UpdatedDate: "",
        TicketNo: "",
        Subject: "",
        EmailId: "",
        Status: "",
        IsDelete: "",
        UserRegId: "",
        AllMsgs: "",
        UserMsgs: "",
        AdminMsgs: "",

        IssueId: "",
        IssueName: "",
        SupportEmailId: "",
        AvailEmailId: "",
        TransHash: "",
        DepoAmount: "",
        TickerDeposited: "",
        WrongAddress: "",
        DestinationAddress: "",
        TransID: "",
        RemovedCoinWithdraw: "",
        NameOfChatMember: "",
        ConfirmMissingType: "",
        AbleToLogin: "",
        IsConfirm1: "",
        IsConfirm2: "",
        IsConfirm3: "",
        IsNewTicket: "",
    });
    const [loading, setLoading] = useState(false);
    const [editorData, setEditorData] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        setLoading(true);
        GetUserAllSupports();
    }, []);

    const GetUserAllSupports = () => {
        const GetCurrentUserAllTickets = new Promise((resolve) => {
            resolve(GetTokenApi(API_Path.GetCurrentUserSupportsByTicketNo + location.state.id));
        });
        GetCurrentUserAllTickets.then((res) => {
            if (res.data) {
                var supports = res.data && res.data[0];
                var data = res.data && res.data[1];
                setUserTicketData(supports);
                setUserTicketNo({
                    CreatedDate: data[0].CreatedDate,
                    UpdatedDate: data[0].UpdatedDate,
                    TicketNo: data[0].TICKETNO,
                    Subject: data[0].SUBJECT,
                    EmailId: data[0].USEREMAILID,
                    Status: data[0].STATUS,
                    IsDelete: data[0].ISDELETE == "False" ? 0 : 1,
                    UserRegId: data[0].USERREGID,
                    AllMsgs: data[0].AllMsgs,
                    UserMsgs: data[0].UserMsgs,
                    AdminMsgs: data[0].AdminMsgs,
                    IssueId: data[0].ISS_ID,
                    IssueName: data[0].ISS_NAME,
                    SupportEmailId: data[0].EMAIL_ID,
                    AvailEmailId: data[0].AVAIL_EMAIL_ID,
                    TransHash: data[0].TRANS_HASH,
                    DepoAmount: data[0].DEPO_AMOUNT,
                    TickerDeposited: data[0].TICKER_COIN,
                    WrongAddress: data[0].MISTACK_ADDRESS,
                    DestinationAddress: data[0].DEST_ADDRESS,
                    TransID: data[0].TRANS_ID,
                    RemovedCoinWithdraw: data[0].CURR_AND_AMOUNT,
                    NameOfChatMember: data[0].CHAT_MEMBER_NAME,
                    ConfirmMissingType: data[0].CONFIRM_MISSING_TYPE,
                    AbleToLogin: data[0].IS_LOGIN,
                    IsConfirm1: data[0].IS_CONFIRM_1 == "True" ? true : false,
                    IsConfirm2: data[0].IS_CONFIRM_2 == "True" ? true : false,
                    IsConfirm3: data[0].IS_CONFIRM_3 == "True" ? true : false,
                    IsNewTicket: data[0].IS_NEW_TICKET == "True" ? true : false,
                });
                setLoading(false);
            } else {
                toast.error("Something went wrong!");
            }
        });
    };

    const handleBack = () => navigate(-1);

    const handleImageFile = (e) => {
        setSelectedFile(e.target.files[0]);
        console.log(e.target.file);
    };
    
    const handleClear = () => {
        setEditorData('');
        if (editorRef.current) {
            editorRef.current.setData('');
        }
        setSelectedFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }

    const handleSubmitData = () => {
        // if (UserTicketNo.IsDelete === 1) {
        //     toast.error("Support ticket is closed. Unable to send new message.");
        // }
        // ImageFile.length <= 0 && editorModel.data == null
        // Support message text is required.
        if (editorData === "") {
            toast.error("Support message text is required.");
        } else {
            const formData = new FormData();
            formData.append("supportImage", selectedFile);
            formData.append(
                "supportModel",
                JSON.stringify({
                    Subject: location.state.name,
                    TicketNo: `#${location.state.id}`,
                    Message: editorData,
                })
            );
            let SendSupportMessageByUserData = new Promise((resolve) => {
                resolve(PostTokenApi(API_Path.SendSupportMessageByUser, formData));
            });
            SendSupportMessageByUserData.then((res) => {
                if (res.data.Isvalid === true) {
                    toast.success(res.data.Successes[0]);
                    handleClear();
                    GetUserAllSupports();
                } else {
                    toast.error(res.data.Errors[0]);
                }
            });
        }
    };

    return (
        <Layout>
            {loading && <Loading />}
            <section className={`${styles.section} p-0`}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className={styles.ticket_info_boxs}>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className={`${styles.ticket_head} _card`}>
                                            <div className={styles.card_header}>
                                                <h2 className={styles.card_title}>Ticket Info</h2>
                                                <h6 className={`ms-2 mb-0 ${UserTicketNo.IsDelete == 1 ? "red_color" : "green_color"}`}>{UserTicketNo.IsDelete == 1 ? "Closed" : "Opened"}</h6>
                                            </div>
                                            <div className={styles.ticket_info_two_box}>
                                                <div className={styles.ticket_info}>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <h6>Created Date</h6>
                                                            <h3>{UserTicketNo.CreatedDate}</h3>
                                                        </div>
                                                        <div className="col-md-4 ">
                                                            <h6>Updated Date</h6>
                                                            <h3>{UserTicketNo.UpdatedDate}</h3>
                                                        </div>
                                                        <div className="col-md-4 text-break">
                                                            <h6>Ticket Creator</h6>
                                                            <h3>{UserTicketNo.EmailId}</h3>
                                                        </div>
                                                    </div>
                                                    <span>Total Replies</span>
                                                    <div className="row m-0">
                                                        <div className="col-md-2 p-0">
                                                            <h3>{UserTicketNo.UserMsgs}</h3>
                                                            <h6>User</h6>
                                                        </div>
                                                        <div className="col-md-2 p-0">
                                                            <h3>{UserTicketNo.AdminMsgs}</h3>
                                                            <h6>Admin</h6>
                                                        </div>
                                                        <div className="col-md-2 p-0">
                                                            <h3>{UserTicketNo.AllMsgs}</h3>
                                                            <h6>All</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div>
                                            <div className={styles.card_header}>
                                                <h2 className={styles.card_title}>Ticket {UserTicketNo.TicketNo}</h2>
                                                <button className={`${styles.btn_back} p-0`} onClick={handleBack}>
                                                    Back
                                                </button>
                                            </div>
                                            <div className={styles.ticket_info_two_box}>
                                                <div className={styles.ticket_info}>
                                                    <div className="row">
                                                        <div className="col-md-3 col-12">
                                                            <h6>Subject</h6>
                                                            <h3>support</h3>
                                                        </div>
                                                        <div className="col-md-5 col-12">
                                                            <h6>Issue Type</h6>
                                                            <h3>I want to delete my funex exchange account</h3>
                                                        </div>
                                                        {UserTicketNo.IssueId !== "" && UserTicketNo.IssueId !== "0" && (
                                                            <div className="col-md-4 col-12">
                                                                <h6>Support Email Id:</h6>
                                                                <h3>{UserTicketNo.AvailEmailId}</h3>
                                                            </div>
                                                        )}
                                                        {UserTicketNo.IssueId === "92" && (
                                                            <div className="col-md-4 col-12">
                                                                <h6>Support Email Id:</h6>
                                                                <h3>{UserTicketNo.AvailEmailId}</h3>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.ticket_replie}>
                                <h2 className={`${styles.card_title} mb-2`}>Ticket Replies</h2>
                                <div className={styles.ticket_info_box}>
                                    {UserTicketData &&
                                        UserTicketData.map((item, i) => (
                                            <div className={styles.ticket_info} key={i}>
                                                <h6>{`${item.AGO} | ${item.ENTRYDATE}`}</h6>
                                                <h3>{item.USERTYPENAME == "admin" ? item.USERTYPENAME : item.USEREMAILID}</h3>
                                                {item.FilesUrl && item.FilesUrl.split(",").map((file, index) => (
                                                    <div className={styles.msg_img} key={index}>
                                                        <img src={file ? file : "https://exchange.funexcoin.com/skins/customcommon/images/no_image_available.png"} alt="" key={i} />
                                                    </div>
                                                ))}
                                                <div dangerouslySetInnerHTML={{ __html: item.MESSAGE }}></div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className={styles.ticket_replie}>
                                <h2 className={`${styles.card_title} mb-2`}>Write A Reply</h2>
                                <div className={`${styles.ticket_info_box} h-auto`}>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={editorConfiguration}
                                        data="<p></p>"
                                        onReady={(editor) => {
                                            editorRef.current = editor;
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            setEditorData(data);
                                        }}
                                    />
                                    <input type="file" className="my-3" onChange={(e) => handleImageFile(e)} accept="image/png, image/jpg"  ref={fileInputRef} />
                                    <div className={styles.reply_btn}>
                                        <button
                                            type="button"
                                            className="button secondary_button me-3"
                                            onClick={handleClear}
                                        >
                                            Clear
                                        </button>
                                        <button type="submit" className="button primary_button" onClick={() => handleSubmitData()}>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
