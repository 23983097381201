import React, { useEffect, useState } from "react";
import styles from "../../styles/Profile.module.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { allCountrys, errorContainer, formAttr } from "../common/CommonFun";
import { GetTokenApi, PostTokenApi } from "../../api/api-service";
import { API_Path } from "../../const";
import Loading from "../loader/Loading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import moment from "moment";

var startdate = moment().subtract(13, "years").format("YYYY-MM-DD");
var dataOFPersonal = "";
var kycData = 0;

export default function PersonalInfo(props) {
    const navigate = useNavigate();
    const [useKycDetailsData, setUserKycDetailsData] = useState({ ...dataOFPersonal });
    const [loading, setLoading] = useState(false);
    const [kycStep, setKycStep] = useState(kycData);

    useEffect(() => {
        getUserKYCProfile();
    }, []);

    const getUserKYCProfile = () => {
        setLoading(true);
        const getCryptoCurrency = new Promise((resolve) => {
            resolve(GetTokenApi(API_Path.getUserKycProfile));
        });
        getCryptoCurrency.then((res) => {
            setLoading(false);
            if (res) {
                dataOFPersonal = res.data;
                if (dataOFPersonal.Id > 0) kycData = 2;
                else kycData = 1;
                setKycStep(kycData);
                setUserKycDetailsData(dataOFPersonal);
            } else {
                toast.error("Something went wrong!");
            }
        });
    };

    const setSubmitData = (formdata, resetform) => {
        setLoading(true);
        const CountryCode = allCountrys.find((data) => data.name === formdata.Country);
        const data = {
            ...formdata,
			DateOfBirth: moment(formdata.DateOfBirth, "YYYY-MM-DD").format("DD-MM-YYYY"),
            CountryCode: CountryCode.value,
        }
        let editProfile = new Promise((resolve) => {
            resolve(PostTokenApi(API_Path.userKYCProfileUpload, data));
        });
        editProfile.then((res) => {
            setLoading(false);
            if (res.data.Isvalid === true) {
                toast.success(res.data.Successes[0]);
                getUserKYCProfile();
                resetform();
            } else {
                toast.error(res.data.Errors[0]);
            }
        });
    };

    const handleEditProfile = () => setKycStep(1);

    const handleRedirect = () => navigate("/useraccount/profile/user-kyc");
    
    return (
        <React.Fragment>
            {loading && <Loading />}
            <div className={styles.personal_info_body}>
                <div >
                    {kycStep === 1 && (
                        <div className={`${styles.account_box}`}>
                            <Formik
                                initialValues={{
                                    FirstName: useKycDetailsData?.FirstName ?? "",
                                    MiddleName: useKycDetailsData?.MiddleName ?? "",
                                    LastName: useKycDetailsData?.LastName ?? "",
                                    DateOfBirth: useKycDetailsData?.DateOfBirth ? moment(useKycDetailsData?.DateOfBirth, "DD/MM/YYYY").format("YYYY-MM-DD") : "",
                                    MobileNo: useKycDetailsData?.MobileNo ?? "",
                                    Address: useKycDetailsData?.Address ?? "",
                                    City: useKycDetailsData?.City ?? "",
                                    State: useKycDetailsData?.State ?? "",
                                    Country: useKycDetailsData?.Country ?? "",
                                    PinCode: useKycDetailsData?.PinCode ?? "",
                                    CountryCode: useKycDetailsData?.CountryCode ?? "",
                                }}
                                validationSchema={Yup.object().shape({
                                    FirstName: Yup.string()
                                        .matches(/^[A-Za-z ]+$/, "First name can contain only alphabets and space.")
                                        .required("First name is required."),
                                    MiddleName: Yup.string().matches(/^[A-Za-z ]+$/, "Middle name can contain only alphabets and space."),
                                    LastName: Yup.string()
                                        .matches(/^[A-Za-z ]+$/, "Last name can contain only alphabets and space.")
                                        .required("Last name is required."),
                                    DateOfBirth: Yup.string().required("Date of Birth is required."),
                                    MobileNo: Yup.number()
                                        .test("len", "Use a vaild mobile number.", (val) => val.toString().length >= 6 && val.toString().length <= 20)
                                        .typeError("Use a vaild mobile number.")
                                        .required("Mobile No. is required."),
                                    Address: Yup.string().required("Address is required."),
                                    City: Yup.string()
                                        .matches(/^[A-Za-z ]+$/, "City name can contain only alphabets and space.")
                                        .required("City is required."),
                                    State: Yup.string()
                                        .matches(/^[A-Za-z ]+$/, "State name can contain only alphabets and space.")
                                        .required("State is required."),
                                    Country: Yup.string().required("Country is required."),
                                    PinCode: Yup.number().required("PIN/ZIP Code is required."),
                                })}
                                onSubmit={(values, { resetForm }) => {
                                    setSubmitData(values, resetForm);
                                }}
                            >
                                {(runform) => {
                                    const { touched, errors } = runform;
                                    return (
                                        <Form onSubmit={runform.handleSubmit}>
                                            <div className={styles.title}>
                                                <h6>Personal Info</h6>
                                            </div>
                                            <div className={styles.personalinfo_width}>
                                                <div className={styles.personalinfocontent}>
                                                    <label>
                                                        First name
                                                        <span className={styles.asterisk}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="FirstName"
                                                        {...formAttr(runform, "FirstName")}
                                                        className={`${styles.form_input} ${errors.FirstName && touched.FirstName ? "input-error" : ""} `}
                                                        placeholder="Enter your full name"
                                                    />
                                                    {errorContainer(runform, "FirstName")}
                                                </div>
                                                <div className={styles.personalinfocontent}>
                                                    <label>Middle name</label>
                                                    <input
                                                        type="text"
                                                        name="MiddleName"
                                                        {...formAttr(runform, "MiddleName")}
                                                        className={`${styles.form_input} ${errors.MiddleName && touched.MiddleName && "input-error"} `}
                                                        placeholder="Enter your middle name"
                                                    />
                                                    {errorContainer(runform, "MiddleName")}
                                                </div>
                                                <div className={styles.personalinfocontent}>
                                                    <label>
                                                        Last name
                                                        <span className={styles.asterisk}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="LastName"
                                                        {...formAttr(runform, "LastName")}
                                                        className={`${styles.form_input} ${errors.LastName && touched.LastName && "input-error"} `}
                                                        placeholder="Last name"
                                                    />
                                                    {errorContainer(runform, "LastName")}
                                                </div>
                                                <div className={styles.personalinfocontent}>
                                                    <label>
                                                        Date of birth
                                                        <span className={styles.asterisk}>*</span>
                                                    </label>
                                                    <input
                                                        type="date"
                                                        name="DateOfBirth"
                                                        id="dataID"
														max={startdate}
                                                        {...formAttr(runform, "DateOfBirth")}
                                                        className={`${styles.form_input} ${errors.DateOfBirth && touched.DateOfBirth && "input-error"} `}
                                                    />
                                                    {errorContainer(runform, "DateOfBirth")}
                                                </div>
                                                <div className={styles.personalinfocontent}>
                                                    <label>
                                                        Mobile No
                                                        <span className={styles.asterisk}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="MobileNo"
                                                        {...formAttr(runform, "MobileNo")}
                                                        className={`${styles.form_input} ${errors.MobileNo && touched.MobileNo && "input-error"} `}
                                                        placeholder="xxxxx xxxxx"
                                                    />
                                                    {errorContainer(runform, "MobileNo")}
                                                </div>
                                            </div>
                                            <div className={`${styles.title} mt-4`}>
                                                <h6 className="mt-4">Contact Info</h6>
                                            </div>
                                            <div className={styles.personalinfo_width}>
                                                <div className={styles.personalinfocontent}>
                                                    <label>
                                                        Address<span className={styles.asterisk}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="Address"
                                                        {...formAttr(runform, "Address")}
                                                        className={`${styles.form_input} ${errors.Address && touched.Address && "input-error"} `}
                                                        placeholder="Plot no., Flor no., Street Name"
                                                    />
                                                    {errorContainer(runform, "Address")}
                                                </div>
                                                <div className={styles.personalinfocontent}>
                                                    <label>
                                                        City
                                                        <span className={styles.asterisk}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="City"
                                                        {...formAttr(runform, "City")}
                                                        className={`${styles.form_input} ${errors.City && touched.City && "input-error"} `}
                                                        placeholder="City"
                                                    />
                                                    {errorContainer(runform, "City")}
                                                </div>
                                                <div className={styles.personalinfocontent}>
                                                    <label>
                                                        State
                                                        <span className={styles.asterisk}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="State"
                                                        {...formAttr(runform, "State")}
                                                        className={`${styles.form_input} ${errors.State && touched.State && "input-error"} `}
                                                        placeholder="State"
                                                    />
                                                    {errorContainer(runform, "State")}
                                                </div>
                                                <div className={styles.personalinfocontent}>
                                                    <label>
                                                        Country
                                                        <span className={styles.asterisk}>*</span>
                                                    </label>
                                                    <select name="Country" className={`${styles.form_input} ${errors.Country && touched.Country && "input-error"}`} {...formAttr(runform, "Country")}>
                                                        <option value="">Select Country</option>
                                                        {allCountrys.map((item, i) => (
                                                            <option key={i} value={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errorContainer(runform, "Country")}
                                                </div>
                                                <div className={styles.personalinfocontent}>
                                                    <label>
                                                        Pin/Zip Code
                                                        <span className={styles.asterisk}>*</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        name="PinCode"
                                                        {...formAttr(runform, "PinCode")}
                                                        className={`${styles.form_input} ${errors.PinCode && touched.PinCode && "input-error"} `}
                                                        placeholder="Pin/Zip Code"
                                                    />
                                                    {errorContainer(runform, "PinCode")}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className={`${styles.personal_info_btn} text-center d-flex`}>
                                                    <button type="button" className={`${styles.button} ${styles.button_border}`} onClick={() => runform.resetForm()}>
                                                        Cancel
                                                    </button>
                                                    <button type="submit" className={`${styles.button} primary_button`}>
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    )}
                    {kycStep === 2 && (
                        <div className="row">
                            <div className="col-12 personal-info-main">
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className={`${styles.detatils_white_box} h-100`}>
                                            <div className={styles.title}>
                                                <h6>Personal Info</h6>
                                            </div>
                                            <div className={styles.personal_details}>
                                                <ul>
                                                    <li>
                                                        <b>First Name</b>
                                                        <span>{useKycDetailsData?.FirstName}</span>
                                                    </li>
                                                    <li>
                                                        <b>Middle Name</b>
                                                        <span>{useKycDetailsData?.MiddleName ?? ""}</span>
                                                    </li>
                                                    <li>
                                                        <b>Last Name</b>
                                                        <span>{useKycDetailsData?.LastName}</span>
                                                    </li>
                                                    <li>
                                                        <b>Date of Birth</b>
                                                        <span>{useKycDetailsData?.DateOfBirth}</span>
                                                    </li>
                                                    <li>
                                                        <b>Mobile No.</b>
                                                        <span>{useKycDetailsData?.MobileNo}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <div className={`${styles.detatils_white_box} h-100`}>
                                            <div className={styles.title}>
                                                <h6>Contact Info</h6>
                                            </div>
                                            <div className={styles.personal_details}>
                                                <ul>
                                                    <li>
                                                        <b>Address</b>
                                                        <span>{useKycDetailsData?.Address}</span>
                                                    </li>
                                                    <li>
                                                        <b>City</b>
                                                        <span>{useKycDetailsData?.City ?? ""}</span>
                                                    </li>
                                                    <li>
                                                        <b>State</b>
                                                        <span>{useKycDetailsData?.State}</span>
                                                    </li>
                                                    <li>
                                                        <b>Country</b>
                                                        <span>{useKycDetailsData?.Country}</span>
                                                    </li>
                                                    <li>
                                                        <b>Pin/Zip code</b>
                                                        <span>{useKycDetailsData?.PinCode}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {useKycDetailsData.DocStatus !== 2 &&
                            <div className={`${styles.personal_info_btn} text-center d-flex mt-4`}>
                                <button type="button" className={`${styles.button} ${styles.button_border}`} onClick={() => handleEditProfile()}>
                                    Edit Profile
                                </button>
                                <button type="submit" className={`${styles.button} primary_button`} onClick={handleRedirect}>
                                    Complete KYC
                                </button>
                            </div>
                            }
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
