import React, { useState, useEffect } from "react";
import styles from "../styles/systemMonitor.module.css";
import Table from "react-bootstrap/esm/Table";
import { API_Path, companyName } from "../const";
import { GetApi } from "../api/api-service";
import Searchbar from "../components/searchbar/Searchbar";
import NoDataFoundImg from "../components/nodatafound/NoDataFoundImg";
import Loading from "../components/loader/Loading";
import Layout from "../components/Layout/Layout";

export default function SystemMonitor() {
    const [coinData, setCoinData] = useState([]);
    const [time, setTime] = useState(new Date());
    const [lastUpdatedTime, setLastUpdatedTime] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        setLoading(true);
        const GetHCoinData = () => {
            const getNotification = new Promise((resolve) => {
                resolve(GetApi(API_Path.getCoinStatusData));
            });
            getNotification.then((res) => {
                setLoading(false);
                setCoinData(res?.data);
            });
        };
        GetHCoinData();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    function formatDateTime(dateTime) {
        return (
            dateTime.getFullYear() +
            "-" +
            ("0" + (dateTime.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + dateTime.getDate()).slice(-2) +
            " " +
            ("0" + dateTime.getHours()).slice(-2) +
            ":" +
            ("0" + dateTime.getMinutes()).slice(-2) +
            ":" +
            ("0" + dateTime.getSeconds()).slice(-2)
        );
    }
    const formattedDateTime = formatDateTime(time);
    const updateFormattedDateTime = formatDateTime(lastUpdatedTime);

    const filteredCoinData = coinData.filter((item) => item.COIN.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <Layout>
            {loading && <Loading />}

            <div className={styles.system_monitor}>
                <div className="container">
                    <div className="title_heading d-flex">
                        <p>Home /</p>
                        <h4>System Status</h4>
                    </div>
                </div>

                <section className="common_bg_style">
                    <div className="container">
                        <div className={styles.head_pare}>
                            <p>
                                At {companyName.toLowerCase()}, we work hard to create transparency and maintain openness in our system. As one of the leading and most trusted cryptocurrency exchange, we aim to
                                transparently share the realtime details of all the transactions happening on our platform.
                            </p>
                            <p>
                                That is why we have created this System Monitor page where you can check the live statistics of all transactions happening on the {companyName.toLowerCase()} platform as well as
                                track key metrics related to the system performance.
                            </p>
                        </div>
                        <div className="row">
                            <div className={styles.system_monitor_head}>
                                <div className={styles.system_monitor_title}>
                                    <ul>
                                        <li className="pe-3">
                                            <span>
                                                <b>Current Time : </b>
                                                {formattedDateTime}
                                            </span>
                                        </li>
                                        <li className="ps-3">
                                            <span>
                                                <b>Last Updated : </b>
                                                {updateFormattedDateTime}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <div className={`${styles.symbol_search_box} tradingSearchBox position-relative`}>
                                    <div className="form-group">
                                        <div className="searchbar_style">
                                            <input type="search" placeholder="Search" className="form-control" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18" fill="none">
                                                <path d="M15.7469 15.7498L12.4844 12.4873" stroke="#404040" strokeLinecap="round" strokeLinejoin="round" />
                                                <path
                                                    d="M8.25 14.25C11.5637 14.25 14.25 11.5637 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25Z"
                                                    stroke="#404040"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.market_table_body}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Pair</th>
                                        <th>Trading</th>
                                        <th>Deposits</th>
                                        <th>Withdrawals</th>
                                        <th>Pending Confirmations</th>
                                        <th>Last Successful Withdrawal</th>
                                        <th>Avg. Processing Time</th>
                                    </tr>
                                </thead>
                                {filteredCoinData && filteredCoinData.length > 0 ? (
                                    <tbody>
                                        {coinData &&
                                            filteredCoinData.map((item, i) => (
                                                <tr key={i}>
                                                    <td>
                                                        <div>
                                                            <img src={item.CoinImageUrl} alt="" />
                                                            <span>{item.COIN}</span>
                                                        </div>
                                                    </td>
                                                    <td className={item.IS_TRADING_STATUS === "Online" ? "green_color" : "red_color"}>{item.IS_TRADING_STATUS}</td>
                                                    <td className={item.IS_DEPOSIT_STATUS === "Online" ? "green_color" : "red_color"}>{item.IS_DEPOSIT_STATUS}</td>
                                                    <td className={item.IS_WITHDRAWAL_STATUS === "Online" ? "green_color" : "red_color"}>{item.IS_WITHDRAWAL_STATUS}</td>
                                                    <td>{item.DEPO_PEND_CONFIM}</td>
                                                    <td>
                                                        <span>
                                                            {item.LAST_SUCC_WITH_DATE} <bdi>{item.LAST_SUCC_WITH_TIME}</bdi>
                                                        </span>
                                                    </td>
                                                    <td>{item.AVG_PROCESSING_TIME} sec.</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                ) : (
                                    <>
                                        <NoDataFoundImg data={"No data found"} />
                                    </>
                                )}
                            </Table>
                        </div>
                    </div>
                </section>

                <section className={styles.system_monitor_footer}>
                    <div className="container">
                        <p>
                            <b>Deposits : </b>
                            Check whether the deposits are Online/Offline for the specific coin or asset and time of processing of last deposit.
                        </p>
                        <p>
                            <b>Withdrawals : </b>
                            Check whether the withdrawals are Online/Offline for the specific coin or asset and time of processing of last withdrawal.
                        </p>
                        <p>
                            <b>Trading : </b>
                            Check whether placing of orders and trading is permitted or not permitted for the specific coin or asset.
                        </p>
                        <p>
                            <b>Maintenance Status : </b>
                            Track the status of our Exchange and any of its coin or asset whenever undergoing scheduled or un-scheduled maintenance.
                        </p>
                    </div>
                </section>
            </div>
        </Layout>
    );
}
